import {Component, OnInit} from '@angular/core';
import Reference from '@models/references/reference/reference.model';
import {Observable, ReplaySubject, take} from 'rxjs';
import {IDynamicComponent} from '@shared/shared.interfaces';
import {IDCReferenceData} from '@features/references/references.interfaces';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import {map} from 'rxjs/operators';

@Component({selector: 'dc-reference-slide-over', templateUrl: 'reference.slide-over.component.html'})
export class DCReferenceSlideOverComponent implements IDynamicComponent, OnInit {
    private _dictionaryItemService: DictionaryItemService;
    private _referenceSource = new ReplaySubject<Reference>(1);
    private _reference$ = this._referenceSource.asObservable();
    private _superficieLabel!: string;

    constructor(dictionaryItemService: DictionaryItemService) {
        this._dictionaryItemService = dictionaryItemService;
    }

    set data(dynamicComponentData: IDCReferenceData) {
        this._referenceSource.next(dynamicComponentData.reference!);
    }

    get reference$(): Observable<Reference> {
        return this._reference$;
    }

    get superficieLabel(): string {
        return this._superficieLabel;
    }

    ngOnInit(): void {
        this._reference$.pipe(
            map(reference => this._dictionaryItemService.getOneSuperficieLabel(reference.nature)),
            take(1),
        ).subscribe(superficieLabel => this._superficieLabel = superficieLabel);
    }
}
