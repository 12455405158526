import {Component, OnInit} from '@angular/core';
import User from '@models/users/user/user.model';
import {combineLatest, Observable, of, ReplaySubject, Subject, switchMap} from 'rxjs';
import {UserFactory} from '@models/users/user/user.factory';
import {map, take, tap} from 'rxjs/operators';
import {IDynamicComponent} from '@shared/shared.interfaces';
import {EtudeFactory} from '@models/etudes/etude/etude.factory';
import Etude from '@models/etudes/etude/etude.model';
import Site from '@models/sites/site/site.model';
import {CSitesService} from '@models/sites/collection/sites.collection.service';
import {EmailEditService} from '@models/emails/email/email.edit.service';
import {IUserSlideOverData} from '@features/dynamic-components/dc.interfaces';
import {CVentesFactory} from '@models/ventes/collection/ventes.collection.factory';
import CVentes from '@models/ventes/collection/ventes.collection.model';

@Component({templateUrl: 'dc-user.slide-over.component.html'})
export class DCUserSlideOverComponent implements IDynamicComponent, OnInit {
    private _cSitesService: CSitesService;
    private _cVentesFactory: CVentesFactory;
    private _emailEditService: EmailEditService;
    private _etudeFactory: EtudeFactory;
    private _userFactory: UserFactory;
    private _cVentesSource = new Subject<CVentes>();
    private _cVentes$ = this._cVentesSource.asObservable();
    private _etudeSource = new Subject<Etude>();
    private _etude$ = this._etudeSource.asObservable();
    private _sitePrincipalSource = new Subject<Site>();
    private _sitePrincipal$ = this._sitePrincipalSource.asObservable();
    private _userSource = new ReplaySubject<User>(1);
    private _user$ = this._userSource.asObservable();

    constructor(cSitesService: CSitesService,
                cVentesFactory: CVentesFactory,
                emailEditService: EmailEditService,
                etudeFactory: EtudeFactory,
                userFactory: UserFactory) {
        this._cSitesService = cSitesService;
        this._cVentesFactory = cVentesFactory;
        this._emailEditService = emailEditService;
        this._etudeFactory = etudeFactory;
        this._userFactory = userFactory;
    }

    get cVentes$(): Observable<CVentes> {
        return this._cVentes$;
    }

    set data(dynamicComponentData: IUserSlideOverData) {
        let user$ = of(dynamicComponentData.user!);

        if (dynamicComponentData.link) {
            user$ = this._userFactory.getByLink$(dynamicComponentData.link);
        }

        user$.pipe(take(1)).subscribe(user => this._userSource.next(user));
    }

    get etude$(): Observable<Etude> {
        return this._etude$;
    }

    get sitePrincipal$(): Observable<Site> {
        return this._sitePrincipal$;
    }

    get user$(): Observable<User> {
        return this._user$;
    }

    ngOnInit(): void {
        this._user$.pipe(
            switchMap(user => this._etudeFactory.getByLink$(user.linkEtude).pipe(map(etude => ({etude, user})))),
            tap(({etude}) => this._etudeSource.next(etude)),
            switchMap(({etude, user}) => combineLatest([
                this._cSitesService.getSitePrincipal$(etude.linkSites).pipe(tap(sitePrincipal => this._sitePrincipalSource.next(sitePrincipal))),
                this._cVentesFactory.getByLink$(user.linkVentes).pipe(tap(cDossierBiens => this._cVentesSource.next(cDossierBiens))),
            ])),
            take(1),
        ).subscribe();
    }

    sendMail(user: User): void {
        this._emailEditService.fromUser$(user).pipe(take(1)).subscribe();
    }
}
