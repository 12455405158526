import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import ADossier from '@models/dossiers/dossier/dossier.model.abstract';
import {EtudeService} from '@models/etudes/etude/etude.service';
import Etude from '@models/etudes/etude/etude.model';
import {Router} from '@angular/router';
import {AppNoteoContactComponent} from '@shared/noteo/contact/noteo-contact.component';
import {CDossierExportsFactory} from '@models/dossiers/dossier/exports/collection/dossier-exports.collection.factory';
import CDossierExports from '@models/dossiers/dossier/exports/collection/dossier-exports.collection.model';
import {startWith, switchMap, takeUntil, tap} from 'rxjs/operators';

@Component({selector: 'app-dossier-inot-exports-box', templateUrl: 'dossier-inot-exports.box.component.html'})
export class AppDossierInotExportsBoxComponent implements OnDestroy, OnInit {
    private _cDossierExportsFactory: CDossierExportsFactory;
    private _etudeService: EtudeService;
    private _router: Router;
    private _cDossierExportsSource = new Subject<CDossierExports>();
    private _cDossierExports$ = this._cDossierExportsSource.asObservable();
    private _dossier!: ADossier;
    private readonly _onDestroy$ = new Subject<void>();
    private _updateCDossierExportsSource = new Subject<void>();

    constructor(cDossierExportsFactory: CDossierExportsFactory, etudeService: EtudeService, router: Router) {
        this._cDossierExportsFactory = cDossierExportsFactory;
        this._etudeService = etudeService;
        this._router = router;
    }

    get cDossierExports$(): Observable<CDossierExports> {
        return this._cDossierExports$;
    }

    @Input()
    set dossier(value: ADossier) {
        this._dossier = value;
    }

    get etude$(): Observable<Etude> {
        return this._etudeService.last$;
    }

    get INOT_LABEL(): string {
        return Etude.enablableModules.INOT.label;
    }

    get moduleInot(): string {
        return AppNoteoContactComponent.modules.INOT;
    }

    ngOnInit(): void {
        this._updateCDossierExportsSource.pipe(
            startWith(undefined!),
            tap(_ => this._cDossierExportsSource.next(undefined!)),
            switchMap(_ => this._cDossierExportsFactory.get$(this._dossier)),
            takeUntil(this._onDestroy$)
        ).subscribe(cDossierExports => this._cDossierExportsSource.next(cDossierExports));
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    create(): void {
        this._router.navigateByUrl('/app/' + this._dossier.dossierType + 's/' + this._dossier.id.toString() + '/inot/export');
    }

    updateCDossierExports(): void {
        this._updateCDossierExportsSource.next();
    }
}
