import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgSoqFileApi} from '@legacy/app/soqrate/soqrate';
import {NgAuthenticationService} from '@legacy/app/auth/auth';
import {NgClientConfig} from '@legacy/app/client/client';

export default function getClientFileAPI(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('ClientFileAPI', Factory);

        /**
         * All methods to use Window
         *
         * @param ClientConfig
         * @param SoqFileAPI
         * @param AuthenticationService
         * @returns {ClientFileAPI}
         */
        Factory.$inject = ['ClientConfig', 'SoqFileAPI', 'AuthenticationService'];
        function Factory(
            clientConfig: NgClientConfig,
            soqFileAPI: NgSoqFileApi,
            authenticationService: NgAuthenticationService
        ) {
            return {uploadFile: uploadFile};

            /**
             * Upload file
             *
             * @param path
             * @param file
             * @param params
             * @returns {Promise}
             */
            function uploadFile(path: string, file: File, params: unknown) {
                return soqFileAPI.upload({
                    url: clientConfig.getFullUrl(path),
                    headers: {'Authorization': 'Bearer ' + authenticationService.getAccessToken()},
                    uploadCredentials: false,
                    files: {file: file},
                    data: params
                }).then(data => clientConfig.deleteEmbedded(data));
            }
        }
    })(angularJS);
}
