import {Component, EventEmitter, inject, Input, Output} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {Observable} from 'rxjs';
import Etude from '@models/etudes/etude/etude.model';
import Location from '@models/locations/location/location.model';
import Vente from '@models/ventes/vente/vente.model';
import {IDossierBienCardOptions} from '@features/dossiers/dossiers.interfaces';

@Component({selector: 'app-dossier-bien-card', templateUrl: 'dossier-bien.card.component.html'})
export class AppDossierBienCardComponent {
    @Output() action = new EventEmitter<ADossierBien>();

    static readonly initDossierBienCardOptions: IDossierBienCardOptions = {
        actionOnAll: false,
        enabledAction: undefined!,
        withBorder: false,
    };
    private _etudeService = inject(EtudeService);
    private _dossierBien!: ADossierBien;
    private _options: IDossierBienCardOptions = {...AppDossierBienCardComponent.initDossierBienCardOptions};

    get currentEtude$(): Observable<Etude> {
        return this._etudeService.last$;
    }

    get dossierBien(): ADossierBien {
        return this._dossierBien;
    }

    @Input()
    set dossierBien(value: ADossierBien) {
        this._dossierBien = value;
    }

    get location(): Location {
        return this._dossierBien as Location;
    }

    get options(): IDossierBienCardOptions {
        return this._options;
    }

    @Input()
    set options(value: IDossierBienCardOptions) {
        this._options = {...AppDossierBienCardComponent.initDossierBienCardOptions, ...value};
    }

    get vente(): Vente {
        return this._dossierBien as Vente;
    }
}
