import * as angular from 'angular';
import {Injectable, Injector} from '@angular/core';
import {ActivatedRouteSnapshot, NavigationBehaviorOptions, Router} from '@angular/router';

@Injectable({providedIn: 'root'})
export class RouterStateForNgService {
    private _$state = {} as { current: Record<string, string>; params: Record<string, unknown> };
    private _injector: Injector;
    private _extraParams: Record<string, unknown> = {};

    constructor(injector: Injector) {
        this._injector = injector;
    }

    private get $state(): { current: Record<string, string>; params: Record<string, unknown> } {
        const isRouteWithExtraParams = (route: string): boolean => ['/app/documents/advertisement', '/app/negociation/revenus'].includes(route) ||
            (route.includes('/bons-visite/') && route.includes('/edit')) ||
            (route.includes('/offres-achat/') && route.includes('/edit')) ||
            (route.includes('/app/estimations/') && route.includes('/evaluation')) ||
            route.includes('/app/estimations/references') ||
            route.includes('/app/locations/search') ||
            route.includes('/app/procedures/') ||
            route.includes('/app/ventes/');
        const router = this._injector.get<Router>(Router);

        this._$state.current = {name: router.url, url: router.url};
        this._$state.params = {...this._getParams(router.routerState.snapshot.root) as object, ...router.routerState.snapshot.root.queryParams};
        if (isRouteWithExtraParams(router.url)) {
            this._$state.params = {...this._$state.params as object, ...(this._extraParams as object)[router.url]} as Record<string, unknown>;
        }

        return this._$state;
    }

    private readonly _getParams = (activatedRouteSnapshot: ActivatedRouteSnapshot): unknown => ({
        ...activatedRouteSnapshot.params,
        ...activatedRouteSnapshot.children.reduce((acc, child) => ({...(this._getParams(child) as object), ...acc}), {}),
    });

    get current(): Record<string, string> {
        return this.$state.current;
    }

    get params(): Record<string, unknown> {
        return this.$state.params;
    }

    get navigateByUrl(): (url: string, extraParams?: Record<string, unknown>) => angular.IPromise<unknown> {
        return (url: string, extraParams?: Record<string, unknown>) => {
            const extras: NavigationBehaviorOptions = {state: {extraParams}};
            let to = '/app/dashboard';

            this._extraParams[url] = extraParams;
            if (url) {
                if (url.includes('.')) {
                    console.log(to);
                    throw new Error('CODE INUTILISABLE');
                }

                to = url;
            }

            return this._injector.get<Router>(Router).navigateByUrl(to, extras);
        };
    }
}
