@if (cVentes$ | async; as cVentes) {
  <div class="tw-overflow-hidden">
    <table [appCollectionSelection]="{collection: cVentes, listName: options.nameSelection!, options: {filterEnabled: options.filterEnabled, initSelectAll: options.initSelectAll, titleDisabled: options.titleDisabled}}"
           id="venteReferences" class="tw-table-head-separate-body">
      <thead>
        <tr>
          <th class="tw-hidden md:tw-table-cell tw-w-[5.5rem]"><div>Photo</div></th>

          <th [appCollectionColumnSort]="options.tris![CVENTES_TRIS.ARCHIVE_DATE_VENTE]" class="tw-w-32 2xl:tw-w-36"
              (changed)="changedTri.emit([CVENTES_TRIS.ARCHIVE_DATE_VENTE, $event])">
            <div>
              <span class="2xl:tw-hidden">Date vente</span> <span class="tw-hidden 2xl:tw-inline">Date de vente</span>
            </div>
          </th>

          @if (options.enabledColumnAdresse) {
            <th><div>Adresse</div></th>
          }

          @if (options.enabledColumnCommune) {
            <th class="tw-hidden lg:tw-table-cell" (changed)="changedTri.emit([CVENTES_TRIS.COMMUNE, $event])"
                [appCollectionColumnSort]="options.tris![CVENTES_TRIS.COMMUNE]"><div>Commune</div></th>
          }

          @if (options.enabledColumnLocalisation) {
            <th><div>Adresse</div></th>
          }

          <th class="tw-w-32 2xl:tw-w-36 tw-text-right">
            <div>
              <span class="2xl:tw-hidden">{{superficieLabel.replace('Surface', 'Surf.')}}</span>
              <span class="tw-hidden 2xl:tw-inline">{{superficieLabel}}</span>
            </div>
          </th>

          <th class="tw-hidden 2xl:tw-table-cell tw-w-40 tw-text-right">
            <div>
              <span class="2xl:tw-hidden">Surf. cadastrale</span>
              <span class="tw-hidden 2xl:tw-inline">Surface cadastrale</span>
            </div>
          </th>

          <th class="tw-hidden sm:tw-table-cell tw-w-32 2xl:tw-w-36 tw-text-right">
            <div>
              <span class="2xl:tw-hidden">Prix cession</span>
              <span class="tw-hidden 2xl:tw-inline">Prix de cession</span>
            </div>
          </th>

          <th class="tw-hidden lg:tw-table-cell tw-w-28 2xl:tw-w-32 tw-text-right">
            <div>
              <span class="2xl:tw-hidden">Prix m²</span>
              <span class="tw-hidden 2xl:tw-inline">Prix au m²</span>
            </div>
          </th>

          <th class="tw-w-16 lg:tw-w-20 print:tw-hidden"><div><div class="tw-h-5"></div></div></th>
        </tr>
      </thead>

      <tbody>
        @for (vente of cVentes.results; track vente) {
          <tr [ngClass]="isEnabled(vente) ? '' : 'tw-opacity-75'">
            <td class="tw-table-head-separate-body-img tw-hidden md:tw-table-cell">
              <app-bien-photo [dossierBien]="vente" class="tw-block tw-h-16 tw-w-16"
                              [options]="{linkPhotoAttribute: 'linkPremierePhotoThumbnail'}"/>
            </td>

            <td>{{vente.archiveDateVente | date}}</td>

            @if (options.enabledColumnAdresse) {
              <td>{{vente.bien.inlineAdresse}}</td>
            }

            @if (options.enabledColumnCommune) {
              <td class="tw-hidden lg:tw-table-cell">{{vente.bien.commune ? vente.bien.commune.fullname : null}}</td>
            }

            @if (options.enabledColumnLocalisation) {
              <td>
                {{vente.bien.inlineAdresse}}
                <div class="tw-text-gray-400">{{vente.bien.commune ? vente.bien.commune.fullname : null}}</div>
              </td>
            }

            <td class="tw-text-right">{{vente.bien.superficie | textCurrency:'m²'}}</td>

            <td class="tw-hidden 2xl:tw-table-cell tw-text-right">
              {{vente.bien.surfaceCadastrale | textCurrency:'m²'}}
            </td>

            <td class="tw-hidden sm:tw-table-cell tw-text-right">{{vente.archivePrixCession | textCurrency}}</td>

            <td class="tw-hidden lg:tw-table-cell tw-text-right">
              {{vente.bien.superficie > 0 && vente.archivePrixCession > 0 ? (vente.archivePrixCession / vente.bien.superficie | textCurrency) : null}}
            </td>

            <td class="tw-table-head-separate-body-actions print:tw-hidden">
              <button class="tw-btn-secondary-info tw-bg-transparent tw-shadow-none tw-ring-0 tw-w-10" type="button"
                      #buttonElement (click)="clickOnVente(buttonElement, vente)">
                <fa-icon [icon]="'ellipsis-v'"/>
              </button>
            </td>
          </tr>
        }
      </tbody>
    </table>

    <app-load-more [collection$]="cVentes$" (loadMore)="getMore(cVentes)" [loading]="pendingGetMore"/>
  </div>
} @else {
  <app-loader [classHeight]="'tw-h-40'" class="tw-block"/>
}
