import {Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import CActualites from '@models/actualites/collection/actualites.collection.model';
import {CActualitesService} from '@models/actualites/collection/actualites.collection.service';
import Actualite from '@models/actualites/actualite/actualite.model';
import {take, takeUntil} from 'rxjs/operators';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {IDropdownClicked} from '@shared/dropdown/dropdown.interfaces';
import {
    ActualiteDropdownComponent
} from '@features/actualites/actualite/dropdown/actualite.dropdown.component';

@Component({selector: 'app-actualites-list', templateUrl: 'actualites-list.component.html'})
export class AppActualitesListComponent implements OnDestroy, OnInit {
    @Output() clicked = new EventEmitter<IDropdownClicked>();

    private _cActualitesService = inject(CActualitesService);
    private _dropdownService = inject(DropdownService);
    private _cActualites$!: Observable<CActualites>;
    private readonly _onDestroy$ = new Subject<void>();
    private _pendingGetMore = false;

    get cActualites$(): Observable<CActualites> {
        return this._cActualites$;
    }

    @Input({required: true})
    set cActualites$(value$: Observable<CActualites>) {
        this._cActualites$ = value$;
    }

    get pendingGetMore(): boolean {
        return this._pendingGetMore;
    }

    ngOnInit(): void {
        this._dropdownService.clicked$.pipe(takeUntil(this._onDestroy$)).subscribe(dropdownClicked => this.clicked.emit(dropdownClicked));
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    clickOnActualite(htmlButtonElement: HTMLButtonElement, actualite: Actualite): void {
        this._dropdownService.open(htmlButtonElement, {
            component: ActualiteDropdownComponent,
            data: {options: {enabledActions: {see: true}}, actualite},
        });
    }

    getMore(cActualites: CActualites): void {
        this._pendingGetMore = true;
        this._cActualitesService.addNext$(cActualites).pipe(take(1)).subscribe({
            complete: () => this._pendingGetMore = false,
        });
    }
}
