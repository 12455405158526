import {Component, EventEmitter, inject, Input, Output, ViewChild} from '@angular/core';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import {DictionaryFactory} from '@models/dictionaries/dictionary/dictionary.factory';
import {NgItemDictionary} from '@legacy/app/managers/ressources';
import {DictionaryItemFactory} from '@models/dictionaries/dictionary/items/item/dictionary-item.factory';
import {NgModel} from '@angular/forms';
import {AppFormSelectInputComponent} from '@shared/form/select/input/form.select-input.component';
import {DictionarySelected, IDictionarySelectOptions} from '@features/dictionaries/dictionaries.interfaces';

// @todo Renommer en AppDictionaryItemSelectComponent
@Component({
    exportAs: 'dictionarySelect',
    selector: 'app-dictionary-select',
    templateUrl: 'dictionary-select.component.html',
})
export class AppDictionarySelectComponent {
    @Output() selected = new EventEmitter<DictionarySelected>();

    static readonly initDictionarySelectOptions: IDictionarySelectOptions = {
        acceptNoResult: false,
        name: 'dictionarySelect',
        notFoundText: 'Aucun élément',
    };
    private _dictionaryFactory = inject(DictionaryFactory);
    private _dictionaryItemFactory = inject(DictionaryItemFactory);
    private _formInput!: NgModel;
    private _initialList: DictionaryItem[] = [];
    private _list: DictionaryItem[] = [];
    private _model!: DictionarySelected;
    private _options: IDictionarySelectOptions = {...AppDictionarySelectComponent.initDictionarySelectOptions};

    @ViewChild('dictionarySelectInput')
    set dictionarySelectInput(value: AppFormSelectInputComponent) {
        setTimeout(_ => this._formInput = value.formInput, 1);
    }

    get formInput(): NgModel {
        return this._formInput;
    }

    get list(): DictionaryItem[] {
        return this._list;
    }

    get model(): DictionarySelected {
        return this._model;
    }

    @Input()
    set model(value: DictionarySelected) {
        // @todo Faire dans le OnInit afin de pouvoir accepter `string` en type de value
        // @todo Supprimer les deprecated
        this._model = value;
    }

    @Input()
    set name(value: string) {
        this._initialList = this._dictionaryFactory.getByName(value).items;
        this._list = [...this._initialList];
    }

    /**
     * @deprecated, utiliser set model()
     */
    @Input()
    set ngItem(value: NgItemDictionary) {
        if (value) {
            this.model = this._dictionaryItemFactory.ngCreate(value);
        } else {
            this.model = undefined!;
        }
    }

    get options(): IDictionarySelectOptions {
        return this._options;
    }

    @Input()
    set options(value: IDictionarySelectOptions) {
        this._options = {...AppDictionarySelectComponent.initDictionarySelectOptions, ...value};
    }

    onSelect(dictionarySelection: unknown): void {
        this.selected.emit(dictionarySelection as DictionarySelected);
    }

    search(search: string): void {
        const sanitizeSearch = search.toLowerCase();

        this._list = this._initialList.filter(item => item.label.toLowerCase().includes(sanitizeSearch));
        if (this._list.length <= 0 && this._options.acceptNoResult) {
            this._list.push(this._dictionaryItemFactory.createForOtherLabel(search));
        }
    }
}
