import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Observable, switchMap} from 'rxjs';
import {take} from 'rxjs/operators';
import Etude from '@models/etudes/etude/etude.model';
import Site from '@models/sites/site/site.model';
import CSites from '@models/sites/collection/sites.collection.model';
import {CSitesService} from '@models/sites/collection/sites.collection.service';
import {EmailEditService} from '@models/emails/email/email.edit.service';

@Component({selector: 'app-etudes-sites-list', templateUrl: 'etudes-sites.list.component.html'})
export class AppEtudesSitesListComponent {
    @Output() actionWhenNoResult = new EventEmitter<void>();
    @Output() hovered = new EventEmitter<Site>();

    private _cSitesService: CSitesService;
    private _emailEditService: EmailEditService;
    private _cSites$!: Observable<CSites>;
    private _pendingGetMore = false;

    constructor(cSitesService: CSitesService, emailEditService: EmailEditService) {
        this._cSitesService = cSitesService;
        this._emailEditService = emailEditService;
    }

    get cSites$(): Observable<CSites> {
        return this._cSites$;
    }

    @Input()
    set cSites$(value$: Observable<CSites>) {
        this._cSites$ = value$;
    }

    get pendingGetMore(): boolean {
        return this._pendingGetMore;
    }

    getMore(): void {
        this._pendingGetMore = true;
        this.cSites$
            .pipe(switchMap(cSites => this._cSitesService.addNext$(cSites)), take(1))
            .subscribe({complete: () => this._pendingGetMore = false});
    }

    sendMail(etude : Etude, site: Site): void {
        this._emailEditService.fromEtudeSite$(etude, site).pipe(take(1)).subscribe();
    }
}
