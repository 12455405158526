import {Component, Input} from '@angular/core';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {Observable} from 'rxjs';
import {IDynamicComponent, IDynamicComponentData} from '@shared/shared.interfaces';

@Component({templateUrl: 'estimation.actions-main.component.html'})
export class EstimationActionsMainComponent implements IDynamicComponent {
    static readonly actions = {REFERENCES_CRITERIA: 'estimation_references_criteria'};
    private _callToActionService: CallToActionService;
    private _value!: IDynamicComponentData;

    constructor(callToActionService: CallToActionService) {
        this._callToActionService = callToActionService;
    }

    get pending$(): Observable<boolean> {
        return this._callToActionService.pending$;
    }

    // Inutile pour le moment mais obligatoire pour IDynamicComponent
    @Input()
    set data(value: IDynamicComponentData) {
        this._value = value;
    }

    referencesCriteria(): void {
        this._callToActionService.clicked(EstimationActionsMainComponent.actions.REFERENCES_CRITERIA);
    }
}
