import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {Observable, of, ReplaySubject, Subject, throwError} from 'rxjs';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {catchError, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {EstimationActionsMainComponent} from '@features/estimations/item/actions/estimation.actions-main.component';
import {Router} from '@angular/router';
import Estimation from '@models/estimations/estimation/estimation.model';
import {EstimationService} from '@models/estimations/estimation/estimation.service';
import {CollectionSelectionService} from '@shared/collection/selection/collection-selection.service';
import {SearchCriteriaService} from '@models/search/criteria/search-criteria.service';
import {SortConst, SortDefinition} from '@shared/constants';
import {ToasterService} from '@shared/toaster/toaster.service';
import {HttpErrorResponse} from '@angular/common/http';
import EtudeSettingsCityscan from '@models/etudes/etude/settings/cityscan/etude-settings-cityscan.model';
import {
    ICityscanOnsalePropertiesListOptions, IDCCityscanOnsalePropertyData
} from '@features/cityscan/cityscan.interfaces';
import {
    CCityscanOnsalePropertiesService
} from '@models/cityscan-onsale-properties/collection/cityscan-onsale-properties.collection.service';
import CCityscanOnsaleProperties
    from '@models/cityscan-onsale-properties/collection/cityscan-onsale-properties.collection.model';
import {
    EstimationActionsSelectionComponent
} from '@features/estimations/item/actions/estimation.actions-selection.component';
import {IModel, IModelValidationError} from '@models/model.interfaces';
import {ModalService} from '@shared/modal/modal.service';
import CityscanOnsaleProperty from '@models/cityscan-onsale-properties/onsale-property/cityscan-onsale-property.model';
import {
    EstimationOnsaleReferenceService
} from '@models/estimations/estimation/onsale-references/onsale-reference/estimation-onsale-reference.service';
import {
    EstimationOnsaleReferencesService
} from '@models/estimations/estimation/onsale-references/estimation-onsale-references.service';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {
    CityscanOnsalePropertyDropdownComponent
} from '@features/cityscan/onsaleProperties/onsaleProperty/dropdown/cityscan-onsale-property.dropdown.component';
import {SlideOverService} from '@shared/slide-over/slide-over.service';
import {
    DCCityscanOnsalePropertySlideOverComponent
} from '@features/cityscan/onsaleProperties/onsaleProperty/slide-over/cityscan-onsale-property.slide-over.component';
import SearchCriteria from '@models/search/criteria/search-criteria.model';

@Component({
    selector: 'layout-estimation-evaluation-onsale-references-list',
    templateUrl: 'layout-estimation-evaluation-onsale-references-list.component.html',
})
export class AppLayoutEstimationEvaluationOnsaleReferencesListComponent implements OnDestroy, OnInit {
    // @todo Faire un tour pour factoriser les messages
    static readonly messages = {httpResponseError: {TITLE: 'Recherche de références en vente'}};
    static readonly ONSALE_PROPERTIES_LIST_OPTIONS: ICityscanOnsalePropertiesListOptions = {
        nameSelection: 'layout-estimation-evaluation-onsale-properties-list-selection',
    };
    static readonly INITIAL_TRIS = {
        DATE: {date: SortConst.DESCENDING},
    };

    private _callToActionService = inject(CallToActionService);
    private _collectionSelectionService = inject(CollectionSelectionService);
    private _cCityscanOnsalePropertiesService = inject(CCityscanOnsalePropertiesService);
    private _dropdownService = inject(DropdownService);
    private _estimationOnsaleReferenceService = inject(EstimationOnsaleReferenceService);
    private _estimationOnsaleReferencesService = inject(EstimationOnsaleReferencesService);
    private _estimationService = inject(EstimationService);
    private _modalService = inject(ModalService);
    private _router = inject(Router);
    private _searchCriteriaService = inject(SearchCriteriaService);
    private _slideOverService = inject(SlideOverService);
    private _toasterService = inject(ToasterService);
    private _cCityscanOnsalePropertiesSource = new ReplaySubject<CCityscanOnsaleProperties>(1);
    private _cCityscanOnsaleProperties$ = this._cCityscanOnsalePropertiesSource.asObservable();
    private _cityscanOnsalePropertiesListOptions: ICityscanOnsalePropertiesListOptions = {...AppLayoutEstimationEvaluationOnsaleReferencesListComponent.ONSALE_PROPERTIES_LIST_OPTIONS};
    private _errorCityscanLocalizable = false;
    private _estimation$!: Observable<Estimation>;
    private readonly _onDestroy$ = new Subject<void>();
    private _redirectionRoute!: string;
    private _searchCriteria!: SearchCriteria;

    get CALL_TO_ACTION_MAIN(): string {
        return CallToActionService.MAIN;
    }

    get cityscanOnsalePropertiesListOptions(): ICityscanOnsalePropertiesListOptions {
        return this._cityscanOnsalePropertiesListOptions;
    }

    get cCityscanOnsaleProperties$(): Observable<CCityscanOnsaleProperties> {
        return this._cCityscanOnsaleProperties$;
    }

    get errorCityscanLocalizable(): boolean {
        return this._errorCityscanLocalizable;
    }

    get estimation$(): Observable<Estimation> {
        return this._estimation$;
    }

    get hasSelectedItems(): boolean {
        return this._collectionSelectionService.hasValues(this.cityscanOnsalePropertiesListOptions.nameSelection!);
    }

    get redirectionRoute(): string {
        return this._redirectionRoute;
    }

    get searchCriteria(): SearchCriteria {
        return this._searchCriteria;
    }

    ngOnInit(): void {
        this._estimation$ = of(this._estimationService.getCurrentFromNg());
        this._estimation$.pipe(
            tap(estimation => this._redirectionRoute = '/app/estimations/' + estimation.id.toString() + '/evaluation'),
            map(estimation => this._searchCriteriaService.getOneOrFromEstimation(this._redirectionRoute + '/onsale-references', estimation)),
            tap(searchCriteria => this._searchCriteria = searchCriteria),
            take(1),
        ).subscribe(_ => this.getCCityscanOnsaleProperties());
        this._callToActionService.clicked$.pipe(
            switchMap(callToActionClicked => {
                if (callToActionClicked.action === EstimationActionsMainComponent.actions.REFERENCES_CRITERIA) {
                    const searchCriteriaRoute = this._redirectionRoute + '/onsale-references/criteres';

                    this._searchCriteriaService.save(searchCriteriaRoute, this.searchCriteria);

                    return of(searchCriteriaRoute);

                } else if (callToActionClicked.action === EstimationActionsSelectionComponent.actions.LINK) {
                    const listName = AppLayoutEstimationEvaluationOnsaleReferencesListComponent.ONSALE_PROPERTIES_LIST_OPTIONS.nameSelection!;

                    const action$ = (items: Set<IModel>) => this.linkItems$.call(this, items);
                    const actionExec$ = this._collectionSelectionService.operateListSelected$(listName, action$);

                    return this._callToActionService.actionExec$(actionExec$).pipe(
                        switchMap(_ => this._estimation$),
                        map(estimation => '/app/estimations/' + estimation.id.toString() + '/evaluation'),
                        catchError(_ => of(undefined)),
                    );
                }

                return of(undefined!);
            }),
            takeUntil(this._onDestroy$),
        ).subscribe(url => {
            if (url) {
                this._router.navigateByUrl(url);
            }
        });

        this._dropdownService.clicked$.pipe(takeUntil(this._onDestroy$)).subscribe(dropdownClicked => {
            const cityscanOnsaleProperty = (dropdownClicked.value as IDCCityscanOnsalePropertyData).cityscanOnsaleProperty!;

            if (dropdownClicked.action === CityscanOnsalePropertyDropdownComponent.actions.LINK) {
                this.linkCityscanOnsaleProperty(cityscanOnsaleProperty);
            } else if (dropdownClicked.action === CityscanOnsalePropertyDropdownComponent.actions.SEE) {
                this._slideOverService.open$(DCCityscanOnsalePropertySlideOverComponent, {cityscanOnsaleProperty}).pipe(take(1)).subscribe();
            }
        });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    changedTri([name, sort]: [string, SortDefinition]): void {
        this.searchCriteria.setTri(name, sort, false);
        this.getCCityscanOnsaleProperties();
    }

    getCCityscanOnsaleProperties(): void {
        this._cCityscanOnsalePropertiesSource.next(undefined!);
        this.searchCriteria.tris = this.searchCriteria.tris ?? AppLayoutEstimationEvaluationOnsaleReferencesListComponent.INITIAL_TRIS.DATE;
        this.estimation$.pipe(
            switchMap(estimation => this._cCityscanOnsalePropertiesService.getByEstimationAndSearchCriteria$(estimation, this.searchCriteria)),
            tap(cCityscanOnsaleProperties => this._cCityscanOnsalePropertiesSource.next(cCityscanOnsaleProperties)),
            tap(_ => {
                this._cityscanOnsalePropertiesListOptions.tris = this.searchCriteria.tris;
                if (this.searchCriteria.natures.length === 1) {
                    this._cityscanOnsalePropertiesListOptions.mainNature = this.searchCriteria.natures[0];
                }
            }),
            catchError((httpErrorResponse: HttpErrorResponse): Observable<void> => {
                const error = httpErrorResponse?.error as { type?: string };

                if (httpErrorResponse.status !== 400 && error?.type) {
                    return throwError(() => httpErrorResponse);
                }

                const errorType = error.type!;

                if (!errorType.includes(EtudeSettingsCityscan.REQUEST_ERRORS.cityscan_not_localizable)) {
                    return throwError(() => httpErrorResponse);
                }

                this._errorCityscanLocalizable = true;

                return of(undefined);
            }),
            take(1),
        ).subscribe({
            error: (httpErrorResponse: HttpErrorResponse) =>
                this._toasterService.error(AppLayoutEstimationEvaluationOnsaleReferencesListComponent.messages.httpResponseError.TITLE, httpErrorResponse.message),
        });
    }

    linkItems$(items: Set<IModel>): Observable<void> {
        return this._estimation$.pipe(
            switchMap(estimation => {
                const onsaleProperties = Array.from(items as Set<CityscanOnsaleProperty>);

                return this._estimationOnsaleReferencesService.saveFromCityscanOnsaleProperties$(estimation, onsaleProperties).pipe(
                    tap(_ => this._toasterService.success(
                        EstimationOnsaleReferenceService.TITLE_ADDED_ESTIMATION_ONSALE_REFERENCES,
                        EstimationOnsaleReferenceService.getMessageLinkOnsaleReferencesAdded(onsaleProperties)
                    )),
                    catchError((error: IModelValidationError) => this._modalService.openInformation$({
                        comments: EstimationOnsaleReferenceService.getMessageLinkOnsaleReferenceAddedError(error),
                        title: EstimationOnsaleReferenceService.TITLE_ADDED_ESTIMATION_ONSALE_REFERENCE,
                        status: ModalService.status.DANGER,
                    }).pipe(switchMap(_ => throwError(() => error)))),
                );
            }),
            map(_ => undefined),
        );
    }

    linkCityscanOnsaleProperty(cityscanOnsaleProperty: CityscanOnsaleProperty): void {
        this._estimation$.pipe(
            switchMap(estimation => this._estimationOnsaleReferenceService.saveFromCityscanOnsaleProperty$(estimation, cityscanOnsaleProperty)),
            take(1),
        ).subscribe({
            next: _ => this._toasterService.success(EstimationOnsaleReferenceService.TITLE_ADDED_ESTIMATION_ONSALE_REFERENCE, EstimationOnsaleReferenceService.getMessageLinkOnsaleReferenceAdded()),
            error: (error: IModelValidationError) => this._modalService.openInformation$({
                comments: EstimationOnsaleReferenceService.getMessageLinkOnsaleReferenceAddedError(error),
                title: EstimationOnsaleReferenceService.TITLE_ADDED_ESTIMATION_ONSALE_REFERENCE,
                status: ModalService.status.DANGER,
            }).pipe(take(1)).subscribe(),
        });
    }
}
