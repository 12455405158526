import {Inject, inject, Injectable} from '@angular/core';
import {ApiService} from '@core/api/api.service';
import {Observable, of} from 'rxjs';
import {HttpEventProgress, HttpOptions} from '@core/api/api.interfaces';
import Media from '@models/medias/media/media.model';
import {HttpResponse} from '@angular/common/http';
import DateFormat from '@shared/date/date.format';
import {UrlService} from '@shared/texts/url/url.service';
import {IQueryParameters} from '@shared/texts/texts.interfaces';
import {IModelWindowOptions} from '@models/model.interfaces';

@Injectable({providedIn: 'root'})
export class ModelApiService {
    private _apiService = inject(ApiService);
    private _urlService = inject(UrlService);
    private _window: Window;

    constructor(@Inject('Window') window: Window) {
        this._window = window;
    }

    delete$(path: string): Observable<void> {
        return this._apiService.delete$(path);
    }

    get$<T>(path: string, httpOptions?: HttpOptions): Observable<T> {
        return this._apiService.get$<T>(path, httpOptions);
    }

    getCollection$<T>(path: string, httpOptions?: HttpOptions): Observable<T> {
        return this._apiService.getResponse$<T>(path, httpOptions);
    }

    openWindow$(path: string, modelWindowOptions: IModelWindowOptions = {}): Observable<Window> {
        const titreWindow = modelWindowOptions.title ?? Media.NO_TITLE;
        let completeFullUrl: string;
        let completeFullUrlAuthenticated: string;

        if (modelWindowOptions.needFullPath) {
            completeFullUrl = this._apiService.getFullUrl(path);
        } else {
            completeFullUrl = path;
        }

        if (modelWindowOptions.needAuthentication) {
            completeFullUrlAuthenticated = this._apiService.getAuthenticatedUrlPath(completeFullUrl);
        } else {
            completeFullUrlAuthenticated = completeFullUrl;
        }

        return of(this._window.open(completeFullUrlAuthenticated, titreWindow + ' ' + DateFormat.timeFromDate(new Date()))!);
    }

    patch$<B, T = void>(path: string, body?: B, httpOptions?: HttpOptions): Observable<T> {
        return this._apiService.patch$<T>(path, body, httpOptions);
    }

    pathWithQueryParameters(path: string, queryParameters?: IQueryParameters): string {
        return this._urlService.pathWithQueryParameters(path, queryParameters);
    }

    post$<T>(path: string, body?: T, httpOptions?: HttpOptions): Observable<T> {
        return this._apiService.post$<T>(path, body!, httpOptions);
    }

    put$<T>(path: string, body: T, httpOptions?: HttpOptions): Observable<T> {
        return this._apiService.put$<T>(path, body, httpOptions);
    }

    updateUrlPath(urlPath: string, key: string, value: unknown): string {
        return this._apiService.updateUrlPath(urlPath, key, encodeURIComponent(value as boolean | number | string));
    }

    upload$<T>(path: string, formData: FormData, httpOptions?: HttpOptions): Observable<HttpEventProgress | HttpResponse<T>> {
        return this._apiService.upload$<T>(path, formData, httpOptions);
    }
}
