import {Component, ElementRef, Input} from '@angular/core';
import Site from '@models/sites/site/site.model';
import Etude from '@models/etudes/etude/etude.model';
import {switchMap, take} from 'rxjs/operators';
import {EtudeFactory} from '@models/etudes/etude/etude.factory';
import {Observable, ReplaySubject} from 'rxjs';
import {EmailEditService} from '@models/emails/email/email.edit.service';
import IntersectionService from '@shared/intersection/intersection.service';

@Component({selector: 'app-etude-site-card', templateUrl: 'etude-site.card.component.html'})
export class AppEtudeSiteCardComponent {
    private _elementRef: ElementRef<HTMLElement>;
    private _emailEditService: EmailEditService;
    private _etudeFactory: EtudeFactory;
    private _intersectionService: IntersectionService;
    private _etudeSource = new ReplaySubject<Etude>(1);
    private _etude$ = this._etudeSource.asObservable();
    private _site!: Site;

    constructor(elementRef: ElementRef<HTMLElement>,
                emailEditService: EmailEditService,
                etudeFactory: EtudeFactory,
                intersectionService: IntersectionService) {
        this._elementRef = elementRef;
        this._emailEditService = emailEditService;
        this._etudeFactory = etudeFactory;
        this._intersectionService = intersectionService;
    }

    get etude$(): Observable<Etude> {
        return this._etude$;
    }

    get site(): Site {
        return this._site;
    }

    @Input()
    set site(value: Site) {
        this._site = value;
        this._intersectionService.observe(this._elementRef.nativeElement, () => this._etudeFactory.getByLink$(this._site.linkEtude)
            .pipe(take(1))
            .subscribe(etude => this._etudeSource.next(etude)));
    }

    sendMail(): void {
        this._etude$.pipe(
            switchMap(etude => this._emailEditService.fromEtudeSite$(etude, this.site)),
            take(1),
        ).subscribe();
    }
}
