import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({selector: '[appClickAway]'})
export class ClickAwayDirective {
    @Output() appClickAway = new EventEmitter<void>();
    private _elementRef: ElementRef<HTMLElement>;

    constructor(elementRef: ElementRef<HTMLElement>) {
        this._elementRef = elementRef;
    }

    @HostListener('document:click', ['$event.target'])
    onClick(target: HTMLElement): void {
        if (!this._elementRef.nativeElement.contains(target)) {
            this.appClickAway.emit();
        }
    }
}
