import angularJS from '@shared/angularJS/global.ng';
import {filter as lFilter, find, findIndex} from 'lodash';
import {IModule, IPromise, IQService} from 'angular';
import {
    NgBienCadastreManager, NgEmailManager, NgLocationPasserelleManager, NgManager, NgMediaManager,
    NgVentePasserelleManager
} from '@legacy/app/managers/managers';
import {NgSoqModalesManager} from '@legacy/app/soqrate/soqrate';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {take, tap} from 'rxjs/operators';
import {NgDoubleMandat} from '@legacy/app/managers/ressources';
import EtudePasserelle from '@models/etudes/etude/passerelles/passerelle/etude-passerelle.model';
import {firstValueFrom} from 'rxjs';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {DictionaryFactory} from '@models/dictionaries/dictionary/dictionary.factory';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import {TemplateFactory} from '@models/templates/template/template.factory';
import {DossierBienFactory} from '@models/dossiers/biens/bien/dossier-bien.factory';
import {DocumentService} from '@models/documents/document/document.service';
import Location from '@models/locations/location/location.model';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import Vente from '@models/ventes/vente/vente.model';

export default function getBienDiffusion(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-bien-diffusion></esk-bien-diffusion>
         */
        module.component('eskBienDiffusion', {
            bindings: {dossier: '='},
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/biens/components/item/diffusion.html'
        });

        /**
         * Print or send bien
         *
         * @param SoqModalesManager
         * @param VentePasserelleManager
         * @param LocationPasserelleManager
         * @param $q
         * @param BienCadastreManager
         * @param EmailManager
         * @param $translate
         * @param Ng2RouterStateForNgService
         * @param Ng2EtudeService
         * @param MediaManager
         * @param Ng2DictionaryFactory
         * @param Ng2DocumentService
         * @param Ng2TemplateFactory
         * @param Ng2DossierBienFactory
         */
        Controller.$inject = ['SoqModalesManager', 'VentePasserelleManager', 'LocationPasserelleManager', '$q', 'BienCadastreManager', 'EmailManager', '$translate', 'Ng2RouterStateForNgService', 'Ng2EtudeService', 'MediaManager', 'Ng2DictionaryFactory', 'Ng2DocumentService', 'Ng2TemplateFactory', 'Ng2DossierBienFactory'];
        function Controller(this: any,
                            soqModalesManager: NgSoqModalesManager,
                            ventePasserelleManager: NgVentePasserelleManager,
                            locationPasserelleManager: NgLocationPasserelleManager,
                            $q: IQService,
                            bienCadastreManager: NgBienCadastreManager,
                            emailManager: NgEmailManager,
                            $translate: angular.translate.ITranslateService,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            ng2EtudeService: EtudeService,
                            mediaManager: NgMediaManager,
                            ng2DictionaryFactory: DictionaryFactory,
                            ng2DocumentService: DocumentService,
                            ng2TemplateFactory: TemplateFactory,
                            ng2DossierBienFactory: DossierBienFactory) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.ng2RouterStateForNgService = ng2RouterStateForNgService;
            $ctrl.print = print;
            $ctrl.send = send;
            $ctrl.visibilityDiffuseAll = visibilityDiffuseAll;
            $ctrl.diffuseAll = diffuseAll;
            $ctrl.DossierBienTypesConst = DossierBienTypesConst;
            $ctrl.INTERACTIVE = Vente.types.INTERACTIVE;
            $ctrl.options = {
                affichette: {
                    loading: true,
                    collapsed: true,
                    groups: ng2DictionaryFactory.getByName(Dictionary.names.AFFICHETTE_GROUPS),
                },
                fiche: {loading: true, collapsed: true},
            };

            /**
             * Initialization method
             */
            function $onInit() {
                const startPromises = [] as IPromise<unknown>[];
                let dossierPasserelleManager: NgManager;

                $ctrl.bienDossier = ng2DossierBienFactory.ngCreate($ctrl.dossier);
                switch ($ctrl.dossier._esk.typeDossier) {
                    case DossierBienTypesConst.VENTE :
                        $ctrl.options.affichette.codesTemplate = [Vente.templates.AFFICHETTE];
                        $ctrl.options.fiche.codesTemplate = [Vente.templates.FICHE, Vente.templates.FICHE_CONFIDENTIELLE];
                        $ctrl.options.documentVariables = {venteId: $ctrl.dossier.id};
                        $ctrl.acceptedStatus = [Vente.statuts.COMPROMIS, Vente.statuts.DIFFUSE, Vente.statuts.MANDAT];
                        $ctrl.statusToDiffuse = [Vente.statuts.DIFFUSE, Vente.statuts.MANDAT];
                        dossierPasserelleManager = ventePasserelleManager;
                        break;
                    case DossierBienTypesConst.LOCATION :
                        $ctrl.options.affichette.codesTemplate = ['affichette-location'];
                        $ctrl.options.fiche.codesTemplate = ['fiche-location', 'fiche-location-confidentielle'];
                        $ctrl.options.documentVariables = {locationId: $ctrl.dossier.id};
                        $ctrl.acceptedStatus = [Location.statuts.DIFFUSE, Location.statuts.DISPONIBLE, Location.statuts.SUSPENDU];
                        $ctrl.statusToDiffuse = [Location.statuts.DIFFUSE, Location.statuts.DISPONIBLE];
                        dossierPasserelleManager = locationPasserelleManager;
                        break;
                }

                if (!$ctrl.acceptedStatus.includes($ctrl.dossier.statut)) {
                    return;
                }

                $ctrl.loading = true;
                $ctrl.diffusionTypes = [
                    {title: 'Réseau', type: 'soqrate'},
                    {title: 'Réseaux sociaux', type: 'social_network'},
                    {title: 'Sites Internet', type: 'website'},
                    {title: 'Journaux papier', type: 'newspaper'}
                ];
                $ctrl.diffusions = [];

                ng2EtudeService.last$.pipe(
                    // Network
                    tap(currentEtude => {
                        let promiseNetwork: IPromise<NgDoubleMandat>;

                        if (!currentEtude.hasNetwork()) {
                            return;
                        }

                        switch ($ctrl.dossier._esk.typeDossier) {
                            case DossierBienTypesConst.VENTE :
                                promiseNetwork = bienCadastreManager.checkDoubleMandat($ctrl.dossier.bien.cadastres, $ctrl.dossier.bien.id);
                                break;
                            case DossierBienTypesConst.LOCATION :
                                promiseNetwork = $q.resolve(undefined!);
                                break;
                            default :
                                promiseNetwork = $q.reject();
                        }

                        startPromises.push(promiseNetwork.then(doubleMandat => {
                            $ctrl.diffusions.push({
                                type: 'soqrate',
                                diffuseur: currentEtude.mainNetwork,
                                bien: {
                                    dateDiffusion: $ctrl.dossier.dateDiffusion,
                                    dateSuspension: $ctrl.dossier.dateSuspension,
                                    doubleMandat,
                                },
                                diffuse: function () {
                                    const self = this;

                                    self.message = 'Diffusion en cours';

                                    return $ctrl.dossier.diffuse().then(function () {
                                        self.bien.dateDiffusion = $ctrl.dossier.dateDiffusion;
                                    }).finally(function () {
                                        self.diffuseur.nbBiensActifsEnvoyes++;
                                        self.message = undefined;
                                    });
                                },
                                isDiffused: function () {
                                    return $ctrl.dossier.statut === ADossierBien.abstractBienStatuts.DIFFUSE;
                                },
                                isSuspended: function () {
                                    return $ctrl.dossier.statut === ADossierBien.abstractBienStatuts.SUSPENDU;
                                },
                                isAvailable: function () {
                                    return $ctrl.dossier.statut;
                                },
                                visibleBtn: function () {
                                    if (doubleMandat?.type !== 'doubleMandat' && !this.isDiffused()) {
                                        return 'diffuse';
                                    }

                                    return 'none';
                                },
                                edit: function () {
                                    const self = this;

                                    soqModalesManager.open('BiensModalItemDiffusion', {
                                        resolve: {
                                            dossier: $ctrl.dossier,
                                            diffusion: this.diffuseur,
                                            config: {type: 'networks', currentTab: 'general'}
                                        }
                                    }).then(function () {
                                        self.message = 'Enregistrement en cours';

                                        return $ctrl.dossier.save().finally(() => self.message = undefined);
                                    });
                                }
                            });
                        }));
                    }),
                    // Sites Internet
                    tap(currentEtude => {
                        startPromises.push($ctrl.dossier.getDiffusions().then(function () {
                            angular.forEach(currentEtude.passerelles, function (etudePasserelle) {
                                if ($ctrl.dossier._esk.typeDossier === DossierBienTypesConst.VENTE && $ctrl.dossier.type.code === Vente.types.INTERACTIVE) {
                                    if (etudePasserelle.passerelle.venteInteractive === 'none') return;
                                }

                                const idx = findIndex($ctrl.dossier.passerelles, {passerelle: {id: etudePasserelle.passerelle.id}});
                                const diffusion = {
                                    message: undefined as unknown as string,
                                    type: etudePasserelle.passerelle.type,
                                    diffuseur: etudePasserelle,
                                    bien: {
                                        dateDiffusion: $ctrl.dossier.passerelles[idx].dateDiffusion,
                                        dateSuspension: $ctrl.dossier.passerelles[idx].dateRetrait
                                    },
                                    venteInteractive: etudePasserelle.passerelle.venteInteractive,
                                    children: lFilter(currentEtude.passerelles, {viaId: etudePasserelle.passerelle.id}),
                                    diffuse: function (forceDiffuse = true) {
                                        const self = this;

                                        self.message = 'Diffusion en cours';

                                        return $ctrl.dossier.passerelles[idx].diffuse().then(function () {
                                            if (forceDiffuse && etudePasserelle.passerelle.viaId) {
                                                $ctrl.$onInit();
                                            }

                                            self.bien.dateDiffusion = $ctrl.dossier.passerelles[idx].dateDiffusion;
                                            self.bien.dateSuspension = $ctrl.dossier.passerelles[idx].dateRetrait;

                                            return !!etudePasserelle.passerelle.viaId;
                                        }).finally(function () {
                                            switch ($ctrl.dossier._esk.typeDossier) {
                                                case DossierBienTypesConst.VENTE :
                                                    self.diffuseur.nbVentesActivesEnvoyees++;
                                                    break;
                                                case DossierBienTypesConst.LOCATION :
                                                    self.diffuseur.nbLocationsActivesEnvoyees++;
                                                    break;
                                            }
                                            self.message = undefined!;
                                        });
                                    },
                                    suspend: function () {
                                        const self = this;

                                        self.message = 'Suspension en cours';

                                        return $ctrl.dossier.passerelles[idx].suspend().then(function () {
                                            if (!etudePasserelle.passerelle.viaId) {
                                                let reload = false;

                                                angular.forEach(currentEtude.passerelles, function (etudePasserelleTemp) {
                                                    if (etudePasserelleTemp.passerelle.viaId === etudePasserelle.passerelle.id) reload = true;
                                                });

                                                if (reload) {
                                                    $ctrl.$onInit();
                                                }
                                            }

                                            self.bien.dateDiffusion = $ctrl.dossier.passerelles[idx].dateDiffusion;
                                            self.bien.dateSuspension = $ctrl.dossier.passerelles[idx].dateRetrait;
                                        }).finally(function () {
                                            switch ($ctrl.dossier._esk.typeDossier) {
                                                case DossierBienTypesConst.VENTE :
                                                    self.diffuseur.nbVentesActivesEnvoyees--;
                                                    break;
                                                case DossierBienTypesConst.LOCATION :
                                                    self.diffuseur.nbLocationsActivesEnvoyees--;
                                                    break;
                                            }
                                            self.message = undefined!;
                                        });
                                    },
                                    isDiffused: () => $ctrl.dossier.passerelles[idx].isDiffused(),
                                    isSuspended: () => $ctrl.dossier.passerelles[idx].isSuspended(),
                                    isAvailable: () => $ctrl.dossier.statut,
                                    visibleBtn: function () {
                                        if (angular.isString(this.diffuseur.configurator) && this.diffuseur.configurator !== '' && this.diffuseur.statut !== 'configured') {
                                            return 'to_configure';
                                        }

                                        if (this.isDiffused()) {
                                            return (this.type === "newspaper" || (this.type === "social_network" && this.diffuseur.passerelle.instantDiffusion)) ? 'diffuse' : 'suspend';
                                        }

                                        return 'diffuse';
                                    },
                                    edit: function () {
                                        const self = this;
                                        var promise;

                                        if ($ctrl.dossier.passerelles[idx].id) {
                                            self.message = 'Ouverture de la personnalisation';
                                            promise = dossierPasserelleManager.getOneById($ctrl.dossier.id, $ctrl.dossier.passerelles[idx]._esk.defaultRouteParams.id).then(function (dossierPasserelle) {
                                                $ctrl.dossier.passerelles[idx] = dossierPasserelle;
                                            }).finally(() => self.message = undefined!);
                                        } else {
                                            promise = $q.resolve();
                                        }

                                        promise.then(function () {
                                            self.message = undefined!;

                                            soqModalesManager.open('BiensModalItemDiffusion', {
                                                resolve: {
                                                    dossier: $ctrl.dossier,
                                                    diffusion: self.diffuseur,
                                                    config: {type: 'passerelle', currentTab: 'general'}
                                                }
                                            }).then(function () {
                                                self.message = 'Enregistrement en cours';

                                                return $ctrl.dossier.passerelles[idx].save().finally(() => self.message = undefined!);
                                            });
                                        });
                                    }
                                };

                                if (etudePasserelle.passerelle.viaId) {
                                    diffusion.edit = undefined!;
                                }

                                $ctrl.diffusions.push(diffusion);
                            });
                        }));
                    }),
                // Chargement complet
                ).subscribe(_ => $q.all(startPromises).finally(() => $ctrl.loading = false));
            }

            /**
             * Print templates
             */
            function print(type: string) {
                const ngTemplate = $ctrl.options[type].template;

                if (!ngTemplate) {
                    return;
                }

                $ctrl.options[type].loading = true;
                ng2DocumentService.readFromDossierBien$($ctrl.bienDossier, ng2TemplateFactory.ngCreate(ngTemplate))
                    .pipe(take(1))
                    .subscribe({complete: () => $ctrl.options[type].loading = false});
            }

            /**
             * Send templates
             */
            function send(type: string) {
                $ctrl.options[type].loading = true;
                firstValueFrom(ng2DocumentService.getMediaDossierBien$($ctrl.bienDossier, ng2TemplateFactory.ngCreate($ctrl.options[type].template)))
                    .then(media => mediaManager.createFromNg2(media))
                    .then(media => soqModalesManager.open('EskModalEmail', {
                        resolve: {
                            email: emailManager.create({
                                attachments: [{media: media}],
                                dossiers: [{
                                    typeId: $ctrl.dossier.id,
                                    type: $ctrl.dossier._esk.typeDossier,
                                    dossier: $ctrl.dossier,
                                }],
                                subject: $translate.instant('bien.diffusion.send.SUBJECT', {
                                    type: type,
                                    typeDossier: $ctrl.dossier._esk.typeDossier,
                                    reference: $ctrl.dossier.bien.reference,
                                }),
                                message: $translate.instant('bien.diffusion.send.MESSAGE', {
                                    type: type,
                                    typeDossier: $ctrl.dossier._esk.typeDossier,
                                    reference: $ctrl.dossier.bien.reference,
                                }) + "<br><br>" + emailManager.getCurrentUserSignature(),
                            }),
                            currentDossier: $ctrl.dossier,
                        },
                    })).finally(() => $ctrl.options[type].loading = false);
            }

            /**
             * Visibility of diffuseAll button for each type
             *
             * @param type
             * @returns {boolean}
             */
            function visibilityDiffuseAll(type: string) {
                const filterredDiffusions = $ctrl.diffusions.filter(diffusion => diffusion.type === type);

                if (filterredDiffusions.length <= 1) {
                    return false;
                }

                return filterredDiffusions.some(diffusion => diffusion.visibleBtn() === "diffuse");
            }

            /**
             * Diffuse on all networks with defined type
             *
             * @param type
             */
            function diffuseAll(type: string) {
                const promises: IPromise<unknown>[] = [];

                angular.forEach($ctrl.diffusions, diffusion => {
                    if (diffusion.type === type && !diffusion.isDiffused()) {
                        promises.push(diffusion.diffuse(false));
                    }
                });

                $q.all(promises).then(viaIds => viaIds.includes(true) ? $ctrl.$onInit() : undefined);
            }
        }
    })(angularJS);
}
