import angularJS from '@shared/angularJS/global.ng';
import {IHttpRequestConfigHeaders, IModule, IPromise, IQService} from 'angular';
import {NgLocalStorageServiceClient} from '@legacy/app/soqrate/soqrate';
import {NgClientConfig, NgClientFileApi, NgClientHttp, NgClientWindow} from '@legacy/app/client/client';
import {NgEsk} from '@legacy/app/managers/ressources';
import {LocalStorageService} from '@core/storage/local-storage.service';

export default function getClientService(module: IModule): void {
    (function (angular) {
        'use strict';

        module.service('ClientService', Service);

        /**
         * Communication with API server
         *
         * @param ClientHttp
         * @param ClientWindow
         * @param ClientFileAPI
         * @param ClientConfig
         * @param $q
         * @param Ng2LocalStorageService
         */
        Service.$inject = ['ClientHttp', 'ClientWindow', 'ClientFileAPI', 'ClientConfig', '$q', 'Ng2LocalStorageService'];
        function Service(this: any,
                         clientHttp: NgClientHttp,
                         clientWindow: NgClientWindow,
                         clientFileAPI: NgClientFileApi,
                         clientConfig: NgClientConfig,
                         $q: IQService,
                         ng2LocalStorageService: LocalStorageService) {
            const self = this;
            const routes = {} as Record<string, { method: string, path: string, useCache: boolean }>;
            const clientStorage = ng2LocalStorageService.get<NgLocalStorageServiceClient>('client') || {};

            if (!angular.isObject(clientStorage.cache)) clientStorage.cache = {};

            self.razCache = razCache;
            self.addRoute = addRoute;
            self.getPathCleanQueryParams = getPathCleanQueryParams;
            self.execRoute = execRoute;
            self.execLink = execLink;
            self.execCompleteLink = execCompleteLink;
            self.openWindow = openWindow;
            self.uploadFile = uploadFile;

            /**
             * RAZ cache
             */
            function razCache() {
                ng2LocalStorageService.remove('client');
            }

            /**
             * Add route to be executed
             *
             * @param name
             * @param options
             * @param useCache
             */
            function addRoute(name: string, options: {
                method: string,
                path: string,
                useCache: boolean
            }, useCache?: boolean): void {
                routes[name] = options;
                routes[name].useCache = useCache!;
            }

            /**
             * Get path and clean queryParams
             *
             * @param name
             * @param queryParams
             * @returns {String}
             */
            function getPathCleanQueryParams(name: string, queryParams: Record<string, unknown>) {
                const route = routes[name];
                const regexRouteParam = /{([a-zA-Z_]+)}/g;
                const slugs = {} as Record<string, unknown>;
                let slug: string[];

                if (!angular.isObject(route)) {
                    throw new Error("La route \"" + name + "\" n'existe pas.");
                }

                while ((slug = regexRouteParam.exec(route.path)!) !== null) {
                    if (!angular.isDefined(queryParams[slug[1]])) {
                        throw new Error("Le slug \"" + slug[1] + "\" n'existe pas.");
                    }

                    slugs[slug[1]] = queryParams[slug[1]];
                    delete queryParams[slug[1]];
                }

                let path = route.path;
                for (const [key, value] of Object.entries(slugs as Record<string, unknown>)) {
                    if (typeof value === 'number' || typeof value === 'string') {
                        path = path.replace('{' + key + '}', value.toString());
                    }
                }

                return path;
            }

            /**
             * Make request HTTP
             *
             * @param name
             * @param params
             * @param data
             * @param headers
             * @returns {promise}
             */
            function execRoute<T>(name: string, params?: unknown, data?: unknown, headers?: IHttpRequestConfigHeaders): IPromise<T> | undefined {
                const queryParams = angular.copy(params);
                const path = self.getPathCleanQueryParams(name, queryParams);
                let fullUrl: string;

                switch (routes[name].method) {
                    case 'GET':
                        fullUrl = clientConfig.getFullUrl(path, queryParams);
                        if (routes[name].useCache && angular.isDefined(clientStorage.cache![fullUrl])) {
                            return $q.resolve(clientStorage.cache![fullUrl] as T);
                        }

                        return clientHttp.get(path, queryParams, headers).then(data => {
                            if (routes[name].useCache && (data as { pages: number }).pages === 1) {
                                clientStorage.cache![fullUrl] = data;
                                ng2LocalStorageService.set('client', clientStorage);
                            }

                            return data as T;
                        });
                    case 'POST':
                        return clientHttp.post(path, data, headers) as IPromise<T>;
                    case 'PUT':
                        return clientHttp.put(path, data, headers) as IPromise<T>;
                    case 'PATCH':
                        return clientHttp.patch(path, queryParams, data, headers) as IPromise<T>;
                    case 'DELETE':
                        return clientHttp.remove(path, headers) as IPromise<T>;
                }

                return $q.reject('UNKNOWN METHOD : ' + routes[name].method);
            }

            /**
             * Make request GET on defined route
             *
             * @param route
             * @returns {promise}
             */
            function execLink(route: string) {
                return clientHttp.get(route);
            }

            /**
             * Make request GET on defined link
             *
             * @param link
             * @param params
             * @returns {promise}
             */
            function execCompleteLink(link: string, params: unknown) {
                return clientHttp.execCompleteLink(link, params);
            }

            /**
             * Open window with defined route
             *
             * @param routeName
             * @param params
             * @param windowName
             * @returns {*}
             */
            function openWindow(routeName: string, params: unknown, windowName: string): IPromise<Window> {
                const queryParams = angular.copy(params);

                return clientWindow.openFromAPI(self.getPathCleanQueryParams(routeName, queryParams), queryParams, windowName);
            }

            /**
             * Upload file
             *
             * @param routeName
             * @param file
             * @param queryParams
             * @returns {Promise}
             */
            function uploadFile(routeName: string, file: File & { _esk: NgEsk }, queryParams: unknown) {
                file._esk = {status: 'WAITING'};

                return clientFileAPI.uploadFile(self.getPathCleanQueryParams(routeName, queryParams), file, queryParams).then(data => {
                    file._esk.status = 'UPLOADED';

                    return data;
                }, error => {
                    if (error === 'abort') {
                        error = "Annulation manuelle du téléchargement.";
                    }

                    file._esk.status = 'ERROR';
                    file._esk.error = error;

                    return $q.reject(error);
                }, evt => {
                    file._esk.status = 'IN_PROGRESS';
                    file._esk.uploadXhr = evt.xhr;
                    file._esk.progress = Math.round(evt.loaded / evt.total * 100);

                    return evt;
                }).finally(() => file._esk.uploadXhr = undefined!);
            }
        }
    })(angularJS);
}
