import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';

export default class Estimation extends ADossierBien {
    static readonly motifs = {
        SUCCESSION: 'succession',
        VENTE: 'vente',
        DIVORCE: 'divorce',
        DONATION: 'donation',
        ETUDE_PATRIMOINE: 'etude_patrimoine',
        AUTRE: 'autre',
    };
    static readonly statuts = {
        ARCHIVE: ADossierBien.abstractBienStatuts.ARCHIVE,
        BROUILLON: ADossierBien.abstractBienStatuts.BROUILLON,
        REMIS: ADossierBien.abstractBienStatuts.REMIS,
    };
    static readonly types = {
        AVIS_VALEUR: 'avis_valeur',
        EVALUATION: 'evaluation',
    };
    static readonly valeurVenaleTypes = {
        FOURCHETTE: 'fourchette',
        SIMPLE: 'simple',
    };
    readonly dossierType = DossierBienTypesConst.ESTIMATION;
    private _courrierIntroductifRapportId!: number;
    private _dateRemise!: string;
    private _motif!: string;
    private _rapportId!: number;
    private _type!: DictionaryItem;
    private _typeValeurVenale!: DictionaryItem;
    private _valeurVenale!: number;
    private _valeurVenaleMax!: number;
    private _valeurVenaleMin!: number;

    constructor(uuid: string, id?: number, reference?: string) {
        super(uuid, id, reference);
        this._interne = true;
    }

    get courrierIntroductifRapportId(): number {
        return this._courrierIntroductifRapportId;
    }

    set courrierIntroductifRapportId(value: number) {
        this._courrierIntroductifRapportId = value;
    }

    get dateRemise(): string {
        return this._dateRemise;
    }

    set dateRemise(value: string) {
        this._dateRemise = value;
    }

    get motif(): string {
        return this._motif;
    }

    set motif(value: string) {
        this._motif = value;
    }

    get rapportId(): number {
        return this._rapportId;
    }

    set rapportId(value: number) {
        this._rapportId = value;
    }

    get type(): DictionaryItem {
        return this._type;
    }

    set type(value: DictionaryItem) {
        this._type = value;
    }

    get typeValeurVenale(): DictionaryItem {
        return this._typeValeurVenale;
    }

    set typeValeurVenale(value: DictionaryItem) {
        this._typeValeurVenale = value;
    }

    get valeurVenale(): number {
        return this._valeurVenale;
    }

    set valeurVenale(value: number) {
        this._valeurVenale = value;
    }

    get valeurVenaleMax(): number {
        return this._valeurVenaleMax;
    }

    set valeurVenaleMax(value: number) {
        this._valeurVenaleMax = value;
    }

    get valeurVenaleMin(): number {
        return this._valeurVenaleMin;
    }

    set valeurVenaleMin(value: number) {
        this._valeurVenaleMin = value;
    }

    canEditRapport(): boolean {
        if (this.isArchived()) {
            return false;
        }

        if (this.dateRemise) {
            return true;
        }

        if (!this.bien.hasSavedCadastres() || !this.bien.descriptif) {
            return false;
        }

        if (!this.hasValeurVenale()) {
            return false;
        }

        if (this.isEvaluation()) {
            return this.bien.hasLocalisation();
        }

        return true;
    }

    hasValeurVenale(): boolean {
        if (this.isValeurVenaleSimple()) {
            return this.valeurVenale > 0;
        } else if (this.isValeurVenaleFourchette()) {
            return this.valeurVenaleMin > 0 && this.valeurVenaleMax > 0;
        }

        return false;
    }

    isEvaluation(): boolean {
        return this.type.code === Estimation.types.EVALUATION;
    }

    isValeurVenaleFourchette(): boolean {
        return this.typeValeurVenale.code === Estimation.valeurVenaleTypes.FOURCHETTE;
    }

    isValeurVenaleSimple(): boolean {
        return this.typeValeurVenale.code === Estimation.valeurVenaleTypes.SIMPLE;
    }
}
