import {Component, OnDestroy, OnInit} from '@angular/core';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {Observable, Subject, switchMap} from 'rxjs';
import {IDynamicComponent, IDynamicComponentData} from '@shared/shared.interfaces';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {VenteDropdownComponent} from '@features/ventes/vente/dropdown/vente.dropdown.component';
import {filter, take, takeUntil} from 'rxjs/operators';
import {VenteService} from '@models/ventes/vente/vente.service';
import {VenteOverviewService} from '@models/ventes/vente/vente-overview.service';

@Component({selector: 'vente-overview-actions-main', templateUrl: 'vente-overview.actions-main.component.html'})
export class VenteOverviewActionsMainComponent implements IDynamicComponent, OnDestroy, OnInit {
    static readonly actions = VenteDropdownComponent.actions;
    private _callToActionService: CallToActionService;
    private _dropdownService: DropdownService;
    private _venteOverviewService: VenteOverviewService;
    private _venteService: VenteService;
    private readonly _onDestroy$ = new Subject<void>();
    private _value!: IDynamicComponentData;

    constructor(callToActionService: CallToActionService,
                dropdownService: DropdownService,
                venteOverviewService: VenteOverviewService,
                venteService: VenteService) {
        this._callToActionService = callToActionService;
        this._dropdownService = dropdownService;
        this._venteOverviewService = venteOverviewService;
        this._venteService = venteService;
    }

    get pending$(): Observable<boolean> {
        return this._callToActionService.pending$;
    }

    // Inutile pour le moment mais obligatoire pour IDynamicComponent
    set data(value: IDynamicComponentData) {
        this._value = value;
    }

    ngOnInit(): void {
        this._dropdownService.clicked$.pipe(takeUntil(this._onDestroy$)).subscribe(dropdownClicked =>
            this._callToActionService.clicked(dropdownClicked.action, dropdownClicked.value)
        );
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    clickDropdown(htmlButtonElement: HTMLButtonElement): void {
        this._venteService.current$.pipe(
            filter(vente => !!vente),
            switchMap(vente => this._venteOverviewService.getActions$(vente)),
            take(1),
        ).subscribe(enabledActions => this._dropdownService.open(htmlButtonElement, {
            component: VenteDropdownComponent,
            data: {options: {enabledActions}},
        }));
    }

    print(): void {
        this._callToActionService.clicked(VenteOverviewActionsMainComponent.actions.PRINT);
    }
}
