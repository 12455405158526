import {Component, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';

@Component({selector: 'app-call-to-action', templateUrl: 'call-to-action.component.html'})
export class CallToActionComponent implements OnDestroy, OnInit {
    @ViewChild('callToAction', {read: ViewContainerRef, static: true}) callToActionContent!: ViewContainerRef;
    private _callToActionService: CallToActionService;
    private readonly _onDestroy$ = new Subject<void>();
    private _type!: string;

    constructor(callToActionService: CallToActionService) {
        this._callToActionService = callToActionService;
    }

    get pending$(): Observable<boolean> {
        return this._callToActionService.pending$;
    }

    @Input()
    set type(value: string) {
        this._type = value;
    }

    ngOnInit(): void {
        this._callToActionService.dynamicComponentLoading$(this._type).pipe(
            tap(_ => this.callToActionContent.clear()),
            takeUntil(this._onDestroy$),
        ).subscribe(dynamicComponentLoading => {
            if (dynamicComponentLoading) {
                const componentRef = this.callToActionContent.createComponent(dynamicComponentLoading.component);

                componentRef.instance.data = dynamicComponentLoading.data;
            }
        });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }
}
