import {Component, Input} from '@angular/core';
import {IDynamicComponent} from '@shared/shared.interfaces';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import LocationPasserelle from '@models/locations/location/passerelles/passerelle/location-passerelle.model';
import {IDCLocationPasserelleData} from '@features/locations/locations.interfaces';

@Component({
    selector: 'location-passerelle-dropdown',
    templateUrl: 'location-passerelle.dropdown.component.html',
})
export class LocationPasserelleDropdownComponent implements IDynamicComponent {
    static readonly actions = {REMOVE: 'location-passerelle_remove', SEE_LOCATION: 'location-passerelle_see_location'};
    private _dropdownService: DropdownService;
    private _locationPasserelle!: LocationPasserelle;

    constructor(dropdownService: DropdownService) {
        this._dropdownService = dropdownService;
    }

    @Input()
    set data(value: IDCLocationPasserelleData) {
        this._locationPasserelle = value.locationPasserelle!;
    }

    get locationPasserelle(): LocationPasserelle {
        return this._locationPasserelle;
    }

    remove(): void {
        this._dropdownService.clicked(LocationPasserelleDropdownComponent.actions.REMOVE, {locationPasserelle: this._locationPasserelle});
    }

    see(): void {
        this._dropdownService.clicked(LocationPasserelleDropdownComponent.actions.SEE_LOCATION, {locationPasserelle: this._locationPasserelle});
    }
}
