import {Injectable} from '@angular/core';
import {IQueryParameterCondition, IQueryParameters} from '@shared/texts/texts.interfaces';

@Injectable({providedIn: 'root'})
export class UrlService {
    static addQueryparamToUrl(urlPath: string, key: string, value: string): string {
        try {
            const url = new URL(urlPath);

            url.searchParams.append(key, value);

            return url.toString();
        } catch (e) {
            return undefined!;
        }
    }

    getHostname(stringUrl = ''): string {
        try {
            const url = new URL(stringUrl);

            return url.hostname;
        } catch (e) {
            return undefined!;
        }
    }

    getQueryparamValue(stringUrl = '', queryparamKey = ''): string {
        try {
            const url = new URL(stringUrl);

            return url.searchParams.get(queryparamKey)!;
        } catch (e) {
            return undefined!;
        }
    }

    // Pas utile de passer par URL car path n'est pas une véritable url
    pathWithQueryParameters(path: string, queryParameters?: IQueryParameters): string {
        let completePath = path;
        const stringifiedInQueryparams = this.stringifyInQueryparams(queryParameters as Record<string, unknown>);

        if (stringifiedInQueryparams) {
            completePath += '?' + stringifiedInQueryparams;
        }

        return completePath;
    }

    // Pas utile de passer par URL ou URLSearchParams car il faut malgré tout passer par une boucle récursive
    stringifyInQueryparams(params?: Record<string, unknown>, prefix?: string): string {
        if (!params) {
            return undefined!;
        }

        const queryParameterCondition = params as unknown as IQueryParameterCondition;

        if (queryParameterCondition['condition'] && (!queryParameterCondition['values'] || queryParameterCondition['values'].length <= 0)) {
            return undefined!;
        }

        const serializedParams: string[] = [];
        const typedParams = params;
        const paramsIsArray = Array.isArray(typedParams);

        for (const [key, rawValue] of Object.entries(typedParams)) {
            const value = rawValue as Record<string, unknown>;

            if (value || (typeof value === 'boolean') || (typeof value === 'number')) {
                const thisPrefix = prefix ? prefix + '[' + (paramsIsArray ? '' : key) + ']' : key;

                serializedParams.push(typeof value === 'object' ? this.stringifyInQueryparams(value, thisPrefix)
                    : thisPrefix + '=' + encodeURIComponent(value as boolean | number | string));
            }
        }

        return serializedParams.filter(serializedParam => !!serializedParam).join('&');
    }
}
