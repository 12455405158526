import {Component, EventEmitter, Input, Output} from '@angular/core';
import Collection from '@models/collection/collection.model';
import AModel from '@models/model.abstract';
import {combineLatest, Observable, ReplaySubject} from 'rxjs';
import {map, tap} from 'rxjs/operators';

@Component({selector: 'app-collection-navigation', templateUrl: 'collection-navigation.component.html'})
export class AppCollectionNavigationComponent {
    @Output() goBack = new EventEmitter<void>();
    @Output() wantedIdx = new EventEmitter<number>();

    private _collectionSource = new ReplaySubject<Collection<AModel>>(1);
    private _uuidRefSource = new ReplaySubject<string>(1);
    private _collection$ = combineLatest([this._collectionSource.asObservable(), this._uuidRefSource.asObservable()]).pipe(
        tap(([collection, uuidRef]) => {
            let idxRef = (collection?.results ?? []).findIndex(result => result.uuid === uuidRef);

            if (idxRef > -1) {
                idxRef = idxRef + (collection.page - 1) * collection.perPage;
            }

            this._idxRef = idxRef;
        }),
        map(([collection,]) => collection),
    );
    private _idxRef!: number;

    get collection$(): Observable<Collection<AModel>> {
        return this._collection$;
    }

    @Input({required: true})
    set collection(value: Collection<AModel>) {
        this._collectionSource.next(value);
    }

    get idxRef(): number {
        return this._idxRef;
    }

    @Input({required: true})
    set uuidRef(value: string) {
        this._uuidRefSource.next(value);
    }

    next(collection: Collection<AModel>): void {
        let nextIdx = this.idxRef + 1;

        if (collection.total <= nextIdx) {
            nextIdx = 0;
        }

        this.wantedIdx.emit(nextIdx);
    }

    previous(collection: Collection<AModel>): void {
        let nextIdx = this.idxRef - 1;

        if (nextIdx < 0) {
            nextIdx = collection.total - 1;
        }

        this.wantedIdx.emit(nextIdx);
    }
}
