import {Component, Input} from '@angular/core';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import CUsers from '@models/users/collection/users.collection.model';
import {CUsersService} from '@models/users/collection/users.collection.service';

@Component({selector: 'app-users-list', templateUrl: 'users.list.component.html'})
export class AppUsersListComponent {
    private _cUsersService: CUsersService;
    private _pendingGetMore = false;
    private _cUsers$!: Observable<CUsers>;

    constructor(cUsersService: CUsersService) {
        this._cUsersService = cUsersService;
    }

    get cUsers$(): Observable<CUsers> {
        return this._cUsers$;
    }

    @Input()
    set cUsers$(value$: Observable<CUsers>) {
        this._cUsers$ = value$;
    }

    get pendingGetMore(): boolean {
        return this._pendingGetMore;
    }

    getMore(): void {
        this._pendingGetMore = true;
        this._cUsersService.addNextToCurrent$().pipe(take(1)).subscribe({
            complete: () => this._pendingGetMore = false,
        });
    }
}
