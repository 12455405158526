import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {BehaviorSubject, combineLatest, delay, Observable, of, ReplaySubject, Subject, switchMap} from 'rxjs';
import Offreachat from '@models/offreachats/offreachat/offreachat.model';
import Demandeur from '@models/demandeurs/demandeur/demandeur.model';
import {DemandeurFactory} from '@models/demandeurs/demandeur/demandeur.factory';
import {map, take, takeUntil, tap} from 'rxjs/operators';
import Person from '@models/contacts/person/person.model';
import {NgForm} from '@angular/forms';
import {DemandeurService} from '@models/demandeurs/demandeur/demandeur.service';
import {IOffreachatEditForms, IOffreachatEditOptions} from '@features/offreachats/offreachats.interfaces';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {UserService} from '@models/users/user/user.service';
import User from '@models/users/user/user.model';
import Vente from '@models/ventes/vente/vente.model';
import {VenteFactory} from '@models/ventes/vente/vente.factory';
import CADossierBiens from '@models/dossiers/biens/collection/dossier-biens.collection.model.abstract';
import {DossierBienSource} from '@models/dossiers/biens/dossier-biens.constants';
import CVentes from '@models/ventes/collection/ventes.collection.model';
import {CVentesService} from '@models/ventes/collection/ventes.collection.service';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {IFormButtonInput} from '@shared/form/form.interfaces';
import Filter from '@models/filters/filter/filter.model';
import {CDossierBiensService} from '@models/dossiers/biens/collection/dossier-biens.collection.service';
import {OffreachatService} from '@models/offreachats/offreachat/offreachat.service';
import {ConditionConst} from '@shared/constants';
import {DossierBiensSourcesConst, DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';

@Component({selector: 'app-offreachat-edit', templateUrl: 'offreachat.edit.component.html'})
export class AppOffreachatEditComponent implements OnDestroy, OnInit {
    @Output() demandeur = new ReplaySubject<Demandeur>(1);
    @Output() forms = new EventEmitter<IOffreachatEditForms>();
    @Output() vente = new ReplaySubject<Vente>(1);

    static readonly initOffreachatEditOptions: IOffreachatEditOptions = {
        fixedDemandeur: false,
        fixedVente: false,
    };
    static readonly initVentesSources: IFormButtonInput[] = [
        {
            code: DossierBiensSourcesConst.BIENS_CORRESPONDANTS,
            disabled: false,
            label: 'Biens correspondants',
        },
        {
            code: DossierBiensSourcesConst.WALLET,
            disabled: false,
            label: 'Mon portefeuille',
        },
        {
            code: DossierBiensSourcesConst.NETWORK,
            disabled: false,
            label: 'Tout le réseau',
        },
    ];
    private _cDossierBiensService: CDossierBiensService;
    private _cVentesService: CVentesService;
    private _demandeurFactory: DemandeurFactory;
    private _demandeurService: DemandeurService;
    private _etudeService: EtudeService;
    private _offreachatService: OffreachatService;
    private _userService: UserService;
    private _venteFactory: VenteFactory;
    private _offreachat!: Offreachat;
    private _demandeurCreation = false;
    private _demandeur$ = this.demandeur.asObservable();
    private readonly _endGetVente$ = new Subject<void>();
    private _filteredVentesSource = new BehaviorSubject<CVentes>(undefined!);
    private _filteredVentes$ = this._filteredVentesSource.asObservable();
    private _forms = {} as IOffreachatEditForms;
    private readonly _onDestroy$ = new Subject<void>();
    private _options: IOffreachatEditOptions = {...AppOffreachatEditComponent.initOffreachatEditOptions};
    private _vente$ = this.vente.asObservable();
    private _venteKeywords!: string;
    private _venteSearch = false;
    private _ventesSources = [...AppOffreachatEditComponent.initVentesSources];
    private _ventesSourcesCode = DossierBiensSourcesConst.WALLET;

    constructor(cDossierBiensService: CDossierBiensService,
                cVentesService: CVentesService,
                demandeurFactory: DemandeurFactory,
                demandeurService: DemandeurService,
                etudeService: EtudeService,
                offreachatService: OffreachatService,
                userService: UserService,
                venteFactory: VenteFactory) {
        this._cDossierBiensService = cDossierBiensService;
        this._cVentesService = cVentesService;
        this._demandeurFactory = demandeurFactory;
        this._demandeurService = demandeurService;
        this._etudeService = etudeService;
        this._offreachatService = offreachatService;
        this._userService = userService;
        this._venteFactory = venteFactory;
    }

    get currentUser$(): Observable<User> {
        return this._userService.last$;
    }

    get demandeur$(): Observable<Demandeur> {
        return this._demandeur$;
    }

    get demandeurCreation(): boolean {
        return this._demandeurCreation;
    }

    set demandeurCreation(value: boolean) {
        this._demandeurCreation = value;
    }

    @ViewChild('demandeurForm')
    set demandeurForm(value: NgForm) {
        this._forms.demandeur = value;
        this.forms.emit(this._forms);
    }

    get DICTIONARY_CODE_HONO_NEGO_INCLUS(): string {
        return Vente.chargesType.HONO_NEGO_INCLUS;
    }

    get filteredVentes$(): Observable<CADossierBiens> {
        return this._filteredVentes$;
    }

    get offreachat(): Offreachat {
        return this._offreachat;
    }

    @Input()
    set offreachat(value: Offreachat) {
        this._offreachat = value;
        if (this._offreachat.linkDemandeur) {
            this._demandeurFactory.getByLink$(this._offreachat.linkDemandeur).pipe(
                take(1),
            ).subscribe(demandeur => this.demandeur.next(demandeur));
        } else {
            this.demandeur.next(undefined!);
        }

        if (this._offreachat.linkVente) {
            this._venteFactory.getByLink$(this._offreachat.linkVente).pipe(
                tap(vente => {
                    if (!this._offreachat.honorairesNego) {
                        this._offreachat.honorairesNego = vente.honorairesNego;
                    }

                    if (!this._offreachat.montant) {
                        this._offreachat.montant = vente.prixAffiche;
                    }

                    if (!this._offreachat.typeCharges) {
                        this._offreachat.typeCharges = vente.typeCharges;
                    }
                }),
                take(1),
            ).subscribe(vente => this.vente.next(vente));
        } else {
            this.vente.next(undefined!);
        }
    }

    get options(): IOffreachatEditOptions {
        return this._options;
    }

    @Input()
    set options(value: IOffreachatEditOptions) {
        this._options = {...AppOffreachatEditComponent.initOffreachatEditOptions, ...value};
    }

    @ViewChild('offreForm')
    set offreForm(value: NgForm) {
        this._forms.offre = value;
        this.forms.emit(this._forms);
    }

    get vente$(): Observable<Vente> {
        return this._vente$;
    }

    get venteKeywords(): string {
        return this._venteKeywords;
    }

    get venteSearch(): boolean {
        return this._venteSearch;
    }

    set venteSearch(value: boolean) {
        this._venteSearch = value;
    }

    get ventesSources(): IFormButtonInput[] {
        return this._ventesSources;
    }

    get ventesSourcesCode(): DossierBienSource {
        return this._ventesSourcesCode;
    }

    ngOnInit(): void {
        this.demandeur$.pipe(
            switchMap(demandeur => combineLatest([of(demandeur), this._demandeurService.getOneRecherche$(demandeur)])),
            map(([demandeur, demandeurRecherche]) => !demandeur || demandeur.isBrouillon() || !demandeurRecherche || demandeurRecherche.type !== DossierBienTypesConst.VENTE),
            map(disabled => this._ventesSources
                .filter(ventesSource => ventesSource.code === DossierBiensSourcesConst.BIENS_CORRESPONDANTS)
                .map(ventesSource => {
                    ventesSource.disabled = disabled;

                    return ventesSource;
                }),
            ),
            map(ventesSource => ventesSource[0].disabled),
            takeUntil(this._onDestroy$),
        ).subscribe(ventesSourceBiensCorrespondantsDisabled => {
            if (ventesSourceBiensCorrespondantsDisabled && this._ventesSourcesCode === DossierBiensSourcesConst.BIENS_CORRESPONDANTS) {
                this._ventesSourcesCode = DossierBiensSourcesConst.WALLET;
                this.getVentes();
            }
        });
        this._etudeService.last$.pipe(map(currentEtude => currentEtude.hasNetwork()), take(1)).subscribe(hasNetwork => {
            if (!hasNetwork) {
                this._ventesSources = this._ventesSources.filter(ventesSource => ventesSource.code !== DossierBiensSourcesConst.NETWORK);
            }
        });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    createDemandeur(person: Person): void {
        this._demandeurService.createFromContact$(person)
            .pipe(delay(1), take(1))
            .subscribe(demandeur => this.demandeur.next(demandeur));
    }

    displayVenteSearch(): void {
        this.venteSearch = true;
        this.getVentes();
    }

    getVentes(): void {
        let cVentes$: Observable<CVentes>;

        this._filteredVentesSource.next(undefined!);
        if (!this.venteSearch) {
            return;
        }

        this._endGetVente$.next();
        if (this._ventesSourcesCode === DossierBiensSourcesConst.BIENS_CORRESPONDANTS) {
            cVentes$ = this.demandeur$.pipe(
                switchMap(demandeur => this._demandeurService.getCResultsByFilter$(demandeur, Filter.codes.WALLET_CURRENT, this._venteKeywords)),
                switchMap(cDemandeurRechercheResultats => this._cDossierBiensService.getFromDemandeurRechercheResultats$(cDemandeurRechercheResultats)),
                map(cDossierBiens => cDossierBiens as CVentes),
            );
        } else {
            cVentes$ = this._cVentesService.getBySource$(this._ventesSourcesCode, {
                keywords: this._venteKeywords,
                limit: 20,
                type: {condition: ConditionConst.IN, values: [Vente.types.SIMPLE, Vente.types.VIAGER]},
                statut: {
                    condition: ConditionConst.IN,
                    values: [
                        ADossierBien.abstractBienStatuts.DIFFUSE,
                        ADossierBien.abstractBienStatuts.DISPONIBLE,
                        ADossierBien.abstractBienStatuts.MANDAT,
                    ],
                },
            });
        }

        cVentes$.pipe(
            switchMap(cVentes => this.vente$.pipe(
                map(vente => cVentes.results.filter(venteResult => vente?.uuid !== venteResult.uuid)),
                tap(filteredVentes => cVentes.results = filteredVentes),
                map(_ => cVentes),
            )),
            tap(cVentesFiltered => cVentesFiltered.total = cVentesFiltered.results.length),
            take(1),
            takeUntil(this._endGetVente$),
        ).subscribe(cVentesFiltered => this._filteredVentesSource.next(cVentesFiltered));
    }

    onChangeMontant(): void {
        this.vente.pipe(
            switchMap(vente => this._offreachatService.updateFromVentePrice$(vente, this._offreachat)),
            take(1),
        ).subscribe();
    }

    searchVentes(search: string): void {
        this._venteKeywords = search;
        this.getVentes();
    }

    selectDemandeur(demandeur: Demandeur): void {
        this.demandeur.next(demandeur);
    }

    selectVente(dossierBien: ADossierBien): void {
        // @todo Pour éviter ce code superflu, il faudrait passer un "link" à AppDossierBienCardComponent
        this.vente.next(undefined!);
        of(undefined).pipe(delay(1), take(1)).subscribe(_ => {
            const vente = dossierBien as Vente;

            this._offreachat.honorairesNego = vente.honorairesNego;
            this._offreachat.montant = vente.prixAffiche;
            this._offreachat.typeCharges = vente.typeCharges;
            this.vente.next(vente);
            this.getVentes();
        });
    }

    selectVentesSource(source: string): void {
        this._ventesSourcesCode = source as DossierBienSource;
        this.getVentes();
    }

    stopDemandeurCreation(): void {
        this.demandeurCreation = false;
        this.demandeur.next(undefined!);
    }
}
