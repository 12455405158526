import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({selector: 'app-form-switch', templateUrl: 'form.switch.component.html'})
export class AppFormSwitchComponent {
    @Output() clicked = new EventEmitter<boolean>();
    private _loading = false;
    private _model!: boolean;

    get loading(): boolean {
        return this._loading;
    }

    @Input()
    set loading(value: boolean) {
        this._loading = value;
    }

    get model(): boolean {
        return this._model;
    }

    @Input()
    set model(value: boolean) {
        this._model = value;
    }

    click(): void {
        this._model = !this._model;
        this.clicked.next(this._model);
    }
}
