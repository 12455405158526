import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {IDynamicComponent, IDynamicComponentData} from '@shared/shared.interfaces';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {takeUntil} from 'rxjs/operators';
import {
    EstimationRapportDropdownComponent
} from '@features/estimations/item/rapport/dropdown/estimation-rapport.dropdown.component';
import Estimation from '@models/estimations/estimation/estimation.model';
import {EstimationService} from '@models/estimations/estimation/estimation.service';

@Component({templateUrl: 'estimation-rapport-write.actions-main.component.html'})
export class EstimationRapportWriteActionsMainComponent implements IDynamicComponent, OnDestroy, OnInit {
    static readonly actions = EstimationRapportDropdownComponent.actions;
    private _callToActionService: CallToActionService;
    private _dropdownService: DropdownService;
    private _estimationService: EstimationService;
    private _estimation!: Estimation;
    private readonly _onDestroy$ = new Subject<void>();
    private _value!: IDynamicComponentData;

    constructor(callToActionService: CallToActionService,
                dropdownService: DropdownService,
                estimationService: EstimationService) {
        this._callToActionService = callToActionService;
        this._dropdownService = dropdownService;
        this._estimationService = estimationService;
    }

    set data(value: IDynamicComponentData) {
        this._value = value;
    }

    get estimation(): Estimation {
        return this._estimation;
    }

    get pending$(): Observable<boolean> {
        return this._callToActionService.pending$;
    }

    ngOnInit(): void {
        this._estimation = this._estimationService.getCurrentFromNg();
        this._dropdownService.clicked$.pipe(takeUntil(this._onDestroy$)).subscribe(dropdownClicked =>
            this._callToActionService.clicked(dropdownClicked.action, dropdownClicked.value)
        );
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    clickDropdown(htmlButtonElement: HTMLButtonElement): void {
        this._dropdownService.open(htmlButtonElement, {
            component: EstimationRapportDropdownComponent,
            data: {
                options: {
                    enabledActions: {
                        generate: !this._estimation.isArchived(),
                        print: !this._estimation.isArchived(),
                        send: true,
                    },
                },
            },
        });
    }

    print(): void {
        this._callToActionService.clicked(EstimationRapportDropdownComponent.actions.PRINT);
    }

    save(): void {
        this._callToActionService.clicked(EstimationRapportDropdownComponent.actions.SAVE);
    }
}
