import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {Observable, ReplaySubject, switchMap} from 'rxjs';
import Etude from '@models/etudes/etude/etude.model';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {IBienOverviewPhotosClicked, IBienOverviewPhotosOptions} from '@features/biens/biens.interfaces';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {CBienPhotosFactory} from '@models/bien/photos/collection/bien-photos.collection.factory';
import CBienPhotos from '@models/bien/photos/collection/bien-photos.collection.model';
import {ImageSizeConst} from '@shared/constants';

@Component({selector: 'app-bien-overview-photos', templateUrl: 'bien-overview.photos.component.html'})
export class AppBienOverviewPhotosComponent implements OnInit {
    @Output() clicked = new EventEmitter<IBienOverviewPhotosClicked>();

    static readonly initBienOverviewPhotosOptions: IBienOverviewPhotosOptions = {linkPhotosAttribute: 'linkPhotos'};
    private _cBienPhotosFactory = inject(CBienPhotosFactory);
    private _etudeService = inject(EtudeService);
    private _cBienPhotos$!: Observable<CBienPhotos>;
    private _dossierBien!: ADossierBien;
    private _options: IBienOverviewPhotosOptions = {...AppBienOverviewPhotosComponent.initBienOverviewPhotosOptions};
    private _updateCBienPhotos = new ReplaySubject<void>(1);

    get cBienPhotos$(): Observable<CBienPhotos> {
        return this._cBienPhotos$;
    }

    get currentEtude$(): Observable<Etude> {
        return this._etudeService.last$;
    }

    get dossierBien(): ADossierBien {
        return this._dossierBien;
    }

    @Input({required: true})
    set dossierBien(value: ADossierBien) {
        this._dossierBien = value;
        this._updateCBienPhotos.next();
    }

    get ImageSize(): typeof ImageSizeConst {
        return ImageSizeConst;
    }

    get options(): IBienOverviewPhotosOptions {
        return this._options;
    }

    @Input()
    set options(value: IBienOverviewPhotosOptions) {
        this._options = {...AppBienOverviewPhotosComponent.initBienOverviewPhotosOptions, ...value};
        this._updateCBienPhotos.next();
    }

    ngOnInit(): void {
        this._cBienPhotos$ = this._updateCBienPhotos.pipe(
            switchMap(_ => this._cBienPhotosFactory.getByLink$(this.dossierBien.bien[this.options.linkPhotosAttribute!] as string)),
        );
    }
}
