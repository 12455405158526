import {Injectable} from '@angular/core';
import {Editor, Ui, PluginManager} from 'tinymce';
import CTemplateBalises from '@models/templates/balises/collection/template-balises.collection.model';
import {TemplateBalisesEditorService} from '@models/templates/balises/template-balises.editor.service';
import {filter, map, switchMap, take} from 'rxjs/operators';
import {TemplateBaliseService} from '@models/templates/balises/balise/template-balise.service';
import {TinymceService} from '@shared/tinymce/tinymce.service';
import {ITinymceEvent} from '@shared/tinymce/tinymce.interfaces';

@Injectable({providedIn: 'root'})
export class TinymceBalisesService {
    static readonly attributes = {DATA_MCE_CONTENT: 'data-mce-content', DATA_NOTY: 'data-noty'};
    static readonly FIND_ALL = /\{\{[^{]+\}\}/g;
    static readonly format = {END_API: '() }}', END_EDITOR: '() }}', START_API: '{{ ', START_EDITOR: '{{ '};
    static readonly MENU_ITEMS = 'balises';
    static readonly PLUGIN_NAME = 'balises';
    static readonly pluginOptions = {KEY: 'balises_models', value: CTemplateBalises.models};
    private _templateBalisesEditorService: TemplateBalisesEditorService;
    private _templateBaliseService: TemplateBaliseService;
    private _options!: string[];

    constructor(templateBalisesEditorService: TemplateBalisesEditorService, templateBaliseService: TemplateBaliseService) {
        this._templateBalisesEditorService = templateBalisesEditorService;
        this._templateBaliseService = templateBaliseService;
    }

    set options(value: string[]) {
        this._options = value;
    }

    addPlugin(pluginManager: PluginManager): void {
        // @todo Supprimer eslint-disable et corriger
        /* eslint-disable */
        const that = this;

        pluginManager.add(TinymceBalisesService.PLUGIN_NAME, function (editor) {
            editor.on(TinymceService.events.EXEC_COMMAND, event => that.setExampleValues(editor, event));
            editor.on(TinymceService.events.INIT, _ => that.setExampleValues(editor));
            editor.on(TinymceService.events.BEFORE_SET_CONTENT, event => {
                that.setBalisesMenus(editor);
                that.beforeSetContent(event)
            });
            editor.on(TinymceService.events.POST_PROCESS, event => that.postProcess(event));
        });
        /* eslint-enable */
    }

    beforeSetContent(event: ITinymceEvent): void {
        if (!event.content) {
            return;
        }

        this._templateBalisesEditorService.templateBalises$.pipe(take(1)).subscribe(templateBalises => {
            templateBalises.forEach(templateBalise => {
                event.content = event.content!.replaceAll(TinymceBalisesService.format.START_API + templateBalise.code + TinymceBalisesService.format.END_API,
                    TinymceBalisesService.format.START_EDITOR + templateBalise.code + TinymceBalisesService.format.END_EDITOR);
            });
        });
    }

    getLabelsMenuItemSpecs(editor: Editor, modelsLabelsCTemplateBalises: [string, string, CTemplateBalises][]): [string, Ui.Menu.MenuItemSpec[]][] {
        return modelsLabelsCTemplateBalises.map(([, label, cTemplateBalises]) => [label, this.getMenuItemSpecs(editor, cTemplateBalises)]);
    }

    getMenuItemSpecs(editor: Editor, cTemplateBalises: CTemplateBalises): Ui.Menu.MenuItemSpec[] {
        return cTemplateBalises.results.map(templateBalise => ({
            type: 'menuitem',
            text: templateBalise.description,
            onAction: () => editor.insertContent(TinymceBalisesService.format.START_EDITOR + templateBalise.code + TinymceBalisesService.format.END_EDITOR + ' '),
        }));
    }

    getNestedMenusItemsContents(editor: Editor, modelsLabelsCTemplateBalises: [string, string, CTemplateBalises][]): Ui.Menu.NestedMenuItemContents[] {
        return this.getLabelsMenuItemSpecs(editor, modelsLabelsCTemplateBalises).map(([label, menuItemContents]) => ({
            type: 'nestedmenuitem',
            text: label,
            getSubmenuItems: () => menuItemContents,
        }));
    }

    postProcess(event: ITinymceEvent): void {
        if (!event.content) {
            return;
        }

        this._templateBalisesEditorService.templateBalises$.pipe(take(1)).subscribe(templateBalises => {
            templateBalises.forEach(templateBalise => {
                event.content = event.content!.replaceAll(TinymceBalisesService.format.START_EDITOR + templateBalise.code + TinymceBalisesService.format.END_EDITOR,
                    TinymceBalisesService.format.START_API + templateBalise.code + TinymceBalisesService.format.END_API);
            });
        });
    }

    setBalisesMenus(editor: Editor): void {
        this._templateBalisesEditorService.initModelsLabelsCTemplateBalises(this._options);
        this._templateBalisesEditorService.modelsLabelsCTemplateBalises$.pipe(
            filter(modelsLabelsCTemplateBalises => !!modelsLabelsCTemplateBalises),
            map(modelsLabelsCTemplateBalises => this.getNestedMenusItemsContents(editor, modelsLabelsCTemplateBalises)),
            take(1),
        ).subscribe(nestedMenusItemsContents => editor.ui.registry.addNestedMenuItem(TinymceBalisesService.MENU_ITEMS, {
            icon: 'code-sample',
            text: 'Balises',
            getSubmenuItems: () => nestedMenusItemsContents,
        }));
    }

    setExampleValues(editor: Editor, event: ITinymceEvent = {command: 'mceInsertContent'}): void {
        if (event.command !== 'mceInsertContent') {
            return;
        }

        editor.getBody()
            .querySelectorAll('[' + TinymceBalisesService.attributes.DATA_MCE_CONTENT + '^="{{"]:not([' + TinymceBalisesService.attributes.DATA_NOTY + '])')
            .forEach(element => {
                const dataMceContent = element.getAttribute(TinymceBalisesService.attributes.DATA_MCE_CONTENT)!;

                element.setAttribute(TinymceBalisesService.attributes.DATA_NOTY, 'Chargement des données ...');
                this._templateBalisesEditorService.getModelTemplateBalise$(
                    dataMceContent.slice(TinymceBalisesService.format.START_EDITOR.length, -TinymceBalisesService.format.END_EDITOR.length)
                ).pipe(
                    switchMap(([model, templateBalise]) => this._templateBaliseService.getExampleValue$(model, templateBalise)),
                    take(1),
                ).subscribe(dataNoty => element.setAttribute(TinymceBalisesService.attributes.DATA_NOTY, 'Ex. : ' + dataNoty));
            });
    }
}
