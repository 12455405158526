import {inject, Injectable} from '@angular/core';
import {NgBienDossier, NgEstimation, NgLocation, NgVente} from '@legacy/app/managers/ressources';
import {EstimationFactory} from '@models/estimations/estimation/estimation.factory';
import {LocationFactory} from '@models/locations/location/location.factory';
import {VenteFactory} from '@models/ventes/vente/vente.factory';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {Observable, of} from 'rxjs';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import {DossierBienType} from '@models/dossiers/biens/dossier-biens.constants';
import {IDossierBienDiffusionEtatApi} from '@models/dossiers/biens/bien/dossier-bien.interfaces';
import {IVenteDiffusionEtatApi} from '@models/ventes/vente/vente.interfaces';

@Injectable({providedIn: 'root'})
export class DossierBienFactory {
    private _estimationFactory = inject(EstimationFactory);
    private _locationFactory = inject(LocationFactory);
    private _venteFactory = inject(VenteFactory);

    createFromDiffusionEtat(dossierBienType: DossierBienType, dossierBienDiffusionEtatApi: IDossierBienDiffusionEtatApi): ADossierBien {
        if (dossierBienType === DossierBienTypesConst.LOCATION) {
            return this._locationFactory.createFromDossierBienDiffusionEtat(dossierBienDiffusionEtatApi);
        } else if (dossierBienType === DossierBienTypesConst.VENTE) {
            return this._venteFactory.createFromVenteDiffusionEtat(dossierBienDiffusionEtatApi as IVenteDiffusionEtatApi);
        }

        return undefined!;
    }

    getByLink$(link: string): Observable<ADossierBien> {
        if (link.search(DossierBienTypesConst.ESTIMATION) > 0) {
            return this._estimationFactory.getByLink$(link);
        } else if (link.search(DossierBienTypesConst.LOCATION) > 0) {
            return this._locationFactory.getByLink$(link);
        } else if (link.search(DossierBienTypesConst.VENTE) > 0) {
            return this._venteFactory.getByLink$(link);
        }

        return of(undefined as unknown as ADossierBien);
    }

    ngCreate(ngBienDossier: NgBienDossier): ADossierBien {
        if (ngBienDossier._esk.typeDossier === DossierBienTypesConst.ESTIMATION) {
            return this._estimationFactory.ngCreate(ngBienDossier as NgEstimation);
        } else if (ngBienDossier._esk.typeDossier === DossierBienTypesConst.LOCATION) {
            return this._locationFactory.ngCreate(ngBienDossier as NgLocation);
        } else if (ngBienDossier._esk.typeDossier === DossierBienTypesConst.VENTE) {
            return this._venteFactory.ngCreate(ngBienDossier as NgVente);
        }

        return undefined!;
    }
}
