import angularJS from '@shared/angularJS/global.ng';
import {IModule, IQService} from 'angular';
import {NgClientService} from '@legacy/app/client/client';
import {NgLocalStorageServiceMeteo} from '@legacy/app/soqrate/soqrate';
import {UserService} from '@models/users/user/user.service';
import {LocalStorageService} from '@core/storage/local-storage.service';
import DateFormat from '@shared/date/date.format';
import {take} from 'rxjs/operators';

export default function getEskMeteoWidget(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-meteo-widget></esk-meteo-widget>
         */
        module.component('eskMeteoWidget', {
            templateUrl: 'src/app/legacy/templates/eskimmo/components/meteo-widget.html',
            controller: Controller
        });

        /**
         * Controller for eskMeteoWidget
         *
         * @param Ng2UserService
         * @param ClientService
         * @param $q
         * @param Ng2LocalStorageService
         * @constructor
         */
        Controller.$inject = ['Ng2UserService', 'ClientService', '$q', 'Ng2LocalStorageService'];
        function Controller(this: any,
                            ng2UserService: UserService,
                            clientService: NgClientService,
                            $q: IQService,
                            ng2LocalStorageService: LocalStorageService) {
            const $ctrl = this;
            var apiKey = "bbb0ae78bded37fd7092ba48c664fa52";

            $ctrl.$onInit = $onInit;

            /**
             * Initialization method
             */
            function $onInit() {
                $ctrl.loading = true;
                ng2UserService.getCurrentCommune$().pipe(take(1)).subscribe(commune => {
                    let meteoStorage = ng2LocalStorageService.get<NgLocalStorageServiceMeteo>('meteo');
                    let promise = $q.resolve(meteoStorage);
                    let reload = true;

                    $ctrl.commune = commune;
                    if (angular.isObject(meteoStorage)) {
                        reload = Math.abs(DateFormat.diff(meteoStorage.date, undefined!, {returnUnit: DateFormat.HOURS})) > 1;
                    }

                    if (reload) {
                        promise = clientService.execCompleteLink("https://api.openweathermap.org/data/2.5/weather", {
                            lat: $ctrl.commune.coordonneesGps[0],
                            lon: $ctrl.commune.coordonneesGps[1],
                            mode: 'json',
                            units: 'metric',
                            lang: 'fr',
                            APPID: apiKey,
                        }).then(response => response as { data: unknown }).then(response => {
                            meteoStorage = {date: DateFormat.dateFromNow(), datas: response.data};
                            ng2LocalStorageService.set('meteo', meteoStorage);

                            return meteoStorage;
                        });
                    }

                    promise.then(meteo => $ctrl.meteo = meteo.datas).finally(() => $ctrl.loading = false);
                });
            }
        }
    })(angularJS);
}
