import {Component, Input} from '@angular/core';
import Media from '@core/models/medias/media/media.model';
import {BehaviorSubject, Observable} from 'rxjs';
import {MediaService} from '@models/medias/media/media.service';
import {take} from 'rxjs/operators';

@Component({selector: 'app-media-convivial-name', templateUrl: 'media.convivial-name.component.html'})
export class AppMediaConvivialNameComponent {
    private _mediaService: MediaService;
    private _mediaSource = new BehaviorSubject<Media>(undefined!);
    private _media$ = this._mediaSource.asObservable();

    constructor(mediaService: MediaService) {
        this._mediaService = mediaService;
    }

    get media$(): Observable<Media> {
        return this._media$;
    }

    @Input()
    set media(value: Media) {
        this._mediaSource.next(value);
    }

    @Input()
    set mediaLink(value: string) {
        this._mediaService.getOneByLink$(value).pipe(take(1)).subscribe(media => this._mediaSource.next(media));
    }
}
