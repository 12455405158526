import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {Observable, Subject} from 'rxjs';
import {IDynamicComponent, IDynamicComponentData} from '@shared/shared.interfaces';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {takeUntil} from 'rxjs/operators';
import {
    DossierFilesActionsSelectionDropdownComponent
} from '@features/dossiers/dossier/files/actions/dropdown/dossier-files.actions-selection-dropdown.component';

@Component({templateUrl: 'dossier-files.actions-selection.component.html'})
export class DossierFilesActionsSelectionComponent implements IDynamicComponent, OnInit, OnDestroy {
    static readonly actions = DossierFilesActionsSelectionDropdownComponent.actions;
    private _callToActionService: CallToActionService;
    private _dropdownService: DropdownService;
    private readonly _onDestroy$ = new Subject<void>();
    private _value!: IDynamicComponentData;

    constructor(callToActionService: CallToActionService, dropdownService: DropdownService) {
        this._callToActionService = callToActionService;
        this._dropdownService = dropdownService;
    }

    get pending$(): Observable<boolean> {
        return this._callToActionService.pending$;
    }

    // Inutile pour le moment mais obligatoire pour IDynamicComponent
    @Input()
    set data(value: IDynamicComponentData) {
        this._value = value;
    }

    ngOnInit(): void {
        this._dropdownService.clicked$.pipe(takeUntil(this._onDestroy$)).subscribe(dropdownClicked =>
            this._callToActionService.clicked(dropdownClicked.action, dropdownClicked.value)
        );
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    clickDropdown(htmlButtonElement: HTMLButtonElement): void {
        this._dropdownService.open(htmlButtonElement, {component: DossierFilesActionsSelectionDropdownComponent});
    }

    share(): void {
        this._callToActionService.clicked(DossierFilesActionsSelectionComponent.actions.SHARE);
    }
}
