import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {IDynamicComponent, IDynamicComponentData} from '@shared/shared.interfaces';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {
    EstimationRapportDropdownComponent
} from '@features/estimations/item/rapport/dropdown/estimation-rapport.dropdown.component';
import {EstimationService} from '@models/estimations/estimation/estimation.service';

@Component({templateUrl: 'estimation-rapport-edit.actions-main.component.html'})
export class EstimationRapportEditActionsMainComponent implements IDynamicComponent, OnInit {
    static readonly actions = {GENERATE: EstimationRapportDropdownComponent.actions.GENERATE};
    private _callToActionService: CallToActionService;
    private _estimationService: EstimationService;
    private _enabledActions = false;
    private _value!: IDynamicComponentData;

    constructor(callToActionService: CallToActionService, estimationService: EstimationService) {
        this._callToActionService = callToActionService;
        this._estimationService = estimationService;
    }

    // Inutile pour le moment mais obligatoire pour IDynamicComponent
    set data(value: IDynamicComponentData) {
        this._value = value;
    }

    get enabledActions(): boolean {
        return this._enabledActions;
    }

    get pending$(): Observable<boolean> {
        return this._callToActionService.pending$;
    }

    ngOnInit(): void {
        this._enabledActions = this._estimationService.getCurrentFromNg().canEditRapport();
    }

    generate(): void {
        this._callToActionService.clicked(EstimationRapportEditActionsMainComponent.actions.GENERATE);
    }
}
