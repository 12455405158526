import {Component, Input} from '@angular/core';
import {IDynamicComponent} from '@shared/shared.interfaces';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {
    IOffreachatActions, IOffreachatDropdownData, IOffreachatDropdownDataOptions
} from '@features/offreachats/offreachats.interfaces';
import Offreachat from '@models/offreachats/offreachat/offreachat.model';

@Component({selector: 'offreachat-dropdown', templateUrl: 'offreachat.dropdown.component.html'})
export class OffreachatDropdownComponent implements IDynamicComponent {
    static readonly actions = {
        ARCHIVE: 'archive',
        CANCEL_SIGN: 'cancelSign',
        EDIT: 'edit',
        PRINT: 'print',
        RESUME: 'resume',
        SAVE: 'save',
        SEND: 'send',
        SIGN: 'sign',
        WRITE: 'write',
    };
    static readonly initOffreachatActions: IOffreachatActions = {
        archive: false,
        cancelSign: false,
        edit: false,
        print: false,
        resume: false,
        save: false,
        send: false,
        sign: false,
        write: false,
    };
    private _dropdownService: DropdownService;
    private _offreachat!: Offreachat;
    private _options: IOffreachatDropdownDataOptions = {...{enabledActions: OffreachatDropdownComponent.initOffreachatActions}};

    constructor(dropdownService: DropdownService) {
        this._dropdownService = dropdownService;
    }

    @Input()
    set data(value: IOffreachatDropdownData) {
        this._offreachat = value.offreachat ;
        this._options = {...{enabledActions: OffreachatDropdownComponent.initOffreachatActions}, ...value.options};
    }

    get options(): IOffreachatDropdownDataOptions {
        return this._options;
    }

    archive(): void {
        this._dropdownService.clicked(OffreachatDropdownComponent.actions.ARCHIVE, this._offreachat);
    }

    cancelSign(): void {
        this._dropdownService.clicked(OffreachatDropdownComponent.actions.CANCEL_SIGN, this._offreachat);
    }

    edit(): void {
        this._dropdownService.clicked(OffreachatDropdownComponent.actions.EDIT, this._offreachat);
    }

    print(): void {
        this._dropdownService.clicked(OffreachatDropdownComponent.actions.PRINT, this._offreachat);
    }

    resume(): void {
        this._dropdownService.clicked(OffreachatDropdownComponent.actions.RESUME, this._offreachat);
    }

    save(): void {
        this._dropdownService.clicked(OffreachatDropdownComponent.actions.SAVE, this._offreachat);
    }

    send(): void {
        this._dropdownService.clicked(OffreachatDropdownComponent.actions.SEND, this._offreachat);
    }

    sign(): void {
        this._dropdownService.clicked(OffreachatDropdownComponent.actions.SIGN, this._offreachat);
    }

    write(): void {
        this._dropdownService.clicked(OffreachatDropdownComponent.actions.WRITE, this._offreachat);
    }
}
