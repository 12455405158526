import {Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import ContactsGroup from '@models/contacts-group/contacts-group.model';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import {DictionarySelected} from '@features/dictionaries/dictionaries.interfaces';
import {
    debounceTime, distinctUntilChanged, filter, map, skipWhile, switchMap, take, takeUntil, tap
} from 'rxjs/operators';
import {Observable, of, ReplaySubject, Subject} from 'rxjs';
import {ContactsGroupService} from '@models/contacts-group/contacts-group.service';
import {ModalService} from '@shared/modal/modal.service';
import {CollectionSortableService} from '@shared/collection/sortable/collection.sortable.service';
import ContactsGroupMember from '@models/contacts-group/members/member/contacts-group-member.model';
import {ContactsGroupMemberFactory} from '@models/contacts-group/members/member/contacts-group-member.factory';
import Contact from '@models/contacts/contact/contact.model';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {
    DossierContactsGroupEditMemberDropdownComponent
} from '@features/dossiers/dossier/contacts-group/edit/member-dropdown/dossier-contacts-group-edit.member-dropdown.component';
import {ContactsGroupMemberService} from '@models/contacts-group/members/member/contacts-group-member.service';
import {ToasterService} from '@shared/toaster/toaster.service';
import {IDCContactSelectResponse} from '@features/contacts/contacts.interfaces';
import {PersonFactory} from '@models/contacts/person/person.factory';
import {
    DCDossierMembresSelectComponent
} from '@features/dossiers/dossier/membres/select/dynamic-components/dossier-membres-select.component';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';

@Component({selector: 'app-dossier-contacts-group-edit', templateUrl: 'dossier-contacts-group-edit.component.html'})
export class AppDossierContactsGroupEditComponent implements OnDestroy, OnInit {
    @Output() changed = new EventEmitter<ContactsGroup>();

    static readonly actions = {...DCDossierMembresSelectComponent.actions, ...{IMPORT: 'import'}};
    static readonly messages = {
        delete: {
            CONFIRMATION: 'Voulez-vous vraiment retirer ce contact du groupe ?',
            TITLE: 'Retirer un contact du groupe',
        },
    };
    static readonly notifications = {
        [AppDossierContactsGroupEditComponent.actions.CREATE]: {TITLE: 'Création d\'un contact'},
        [AppDossierContactsGroupEditComponent.actions.IMPORT]: {TITLE: 'Importation d\'un contact'},
    };
    private _collectionSortableService = inject(CollectionSortableService);
    private _contactsGroupMemberFactory = inject(ContactsGroupMemberFactory);
    private _contactsGroupMemberService = inject(ContactsGroupMemberService);
    private _contactsGroupService = inject(ContactsGroupService);
    private _dropdownService = inject(DropdownService);
    private _modalService = inject(ModalService);
    private _personFactory = inject(PersonFactory);
    private _toasterService = inject(ToasterService);
    private _contactsGroupSource = new ReplaySubject<ContactsGroup>(1);
    private _contactsGroup$ = this._contactsGroupSource.asObservable();
    private _contactsGroupLink$!: Observable<string>;
    private _contactsGroupNomChangeSource = new Subject<ContactsGroup>();
    private _contactsGroupNomChange$ = this._contactsGroupNomChangeSource.asObservable();
    private readonly _onDestroy$ = new Subject<void>();
    private _searchModalProcessing = false;

    get contactsGroup$(): Observable<ContactsGroup> {
        return this._contactsGroup$;
    }

    get DICTIONARY_NAMES_CONTACTS_GROUP_TYPES(): string {
        return Dictionary.names.CONTACTS_GROUP_TYPES;
    }

    @Input()
    set link$(value$: Observable<string>) {
        this._contactsGroupLink$ = value$;
    }

    get searchModalProcessing(): boolean {
        return this._searchModalProcessing;
    }

    ngOnInit(): void {
        this._contactsGroupNomChange$.pipe(
            filter(contactsGroup => !!contactsGroup),
            map(contactsGroup => ({contactsGroup, nom: contactsGroup.nom})),
            debounceTime(500),
            distinctUntilChanged((previousContactsGroup, currentContactsGroup) => previousContactsGroup.nom === currentContactsGroup.nom),
            filter(({contactsGroup}) => !!contactsGroup.nom),
            takeUntil(this._onDestroy$),
        ).subscribe(({contactsGroup}) => this.changed.emit(contactsGroup));
        this._contactsGroupLink$
            .pipe(switchMap(link => this._contactsGroupService.getOneByLink$(link)), takeUntil(this._onDestroy$))
            .subscribe(contactsGroup => this._contactsGroupSource.next(contactsGroup));
        this._collectionSortableService.movedItem$.pipe(
            switchMap(([contactGroupMember, newIdx]) =>
                this.contactsGroup$.pipe(map(contactsGroup => ([contactsGroup, contactGroupMember, newIdx + 1] as [ContactsGroup, ContactsGroupMember, number])))
            ),
            filter(([, contactGroupMember]) => contactGroupMember instanceof ContactsGroupMember),
            switchMap(([contactsGroup, contactGroupMember, rank]) => this._contactsGroupMemberFactory.updateRank$(contactsGroup, contactGroupMember, rank)),
            takeUntil(this._onDestroy$),
        ).subscribe();
        this._dropdownService.clicked$.pipe(takeUntil(this._onDestroy$)).subscribe(dropdownClicked => {
            const {
                contactsGroup,
                contactsGroupMember,
            } = dropdownClicked.value as { contactsGroup: ContactsGroup; contactsGroupMember: ContactsGroupMember };

            if (dropdownClicked.action === DossierContactsGroupEditMemberDropdownComponent.actions.EDIT) {
                this.editContact(contactsGroup, contactsGroupMember.contact);
            } else if (dropdownClicked.action === DossierContactsGroupEditMemberDropdownComponent.actions.REMOVE) {
                this.removeMember(contactsGroup, contactsGroupMember);
            }
        });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    actionsMember(htmlButtonElement: HTMLButtonElement, contactsGroup: ContactsGroup, contactsGroupMember: ContactsGroupMember): void {
        this._dropdownService.open(htmlButtonElement, {
            component: DossierContactsGroupEditMemberDropdownComponent,
            data: {contactsGroup, contactsGroupMember},
        });
    }

    addFromContact$(contactsGroup: ContactsGroup, contact: Contact, notificationType: string): Observable<ContactsGroupMember> {
        return this._contactsGroupMemberService.addFromContact$(contactsGroup, contact).pipe(tap(contactsGroupMember =>
            this._toasterService.success(AppDossierContactsGroupEditComponent.notifications[notificationType].TITLE, contactsGroupMember.contact.convivialName + ' fait partie du dossier.')
        ));
    }

    createContactFromSearch$(contactsGroup: ContactsGroup, search: string): Observable<ContactsGroupMember> {
        const person = this._personFactory.createVirgin();

        person.nom = search;

        return this.openContactModal$(person).pipe(
            switchMap(contact => {
                if (!contact) {
                    return of(undefined as unknown as ContactsGroupMember);
                }

                return this.addFromContact$(contactsGroup, contact, AppDossierContactsGroupEditComponent.actions.CREATE);
            }),
        );
    }

    editContact(contactsGroup: ContactsGroup, contactToEdit: Contact): void {
        this.openContactModal$(contactToEdit).pipe(take(1)).subscribe(contact => {
            if (!contact) {
                return;
            }

            const idx = contactsGroup.members.findIndex(contactsGroupMember => contactsGroupMember.id === contact.id);

            contactsGroup.members[idx].contact = contact;
        });
    }

    getSavedContactsGroup$(): Observable<ContactsGroup> {
        return this.contactsGroup$.pipe(
            take(1),
            switchMap(contactsGroup => {
                if (!contactsGroup.id) {
                    this.changed.emit(contactsGroup);

                    return this.contactsGroup$.pipe(skipWhile(contactsGroupUpdated => !contactsGroupUpdated.id));
                }

                return of(contactsGroup);
            }),
        );
    }

    importFailedFromInot$(contactsGroup: ContactsGroup, contact: Contact): Observable<ContactsGroupMember> {
        return this.openContactModal$(contact, true).pipe(
            switchMap(contactSaved => {
                if (!contactSaved) {
                    return of(undefined as unknown as ContactsGroupMember);
                }

                return this.addFromContact$(contactsGroup, contactSaved, AppDossierContactsGroupEditComponent.actions.IMPORT);
            }),
        );
    }

    onChangeNom(contactsGroup: ContactsGroup): void {
        if (!contactsGroup.nom) {
            return;
        }

        this._contactsGroupNomChangeSource.next(contactsGroup);
    }

    onChangeNomValidation(contactsGroup: ContactsGroup): void {
        if (contactsGroup.nom) {
            return;
        }

        contactsGroup.nom = ContactsGroup.NO_NAME;
        this._contactsGroupNomChangeSource.next(contactsGroup);
    }

    onSelectTypeGroup(typeGroupSelected: DictionarySelected, contactsGroup: ContactsGroup): void {
        contactsGroup.typeGroup = typeGroupSelected as DictionaryItem;
        this.changed.emit(contactsGroup);
    }

    openContactModal$(contact: Contact, forceSubmit = false): Observable<Contact> {
        return this._modalService.ngOpen$<Contact>('ContactModalEdit', {resolve: {contact, forceSubmit}});
    }

    openSearchModal(): void {
        if (this._searchModalProcessing) {
            return;
        }

        this._searchModalProcessing = true;
        this.getSavedContactsGroup$().pipe(
            switchMap(contactsGroup => this._modalService.open$(DCDossierMembresSelectComponent,
                {contactsGroup},
                {align: 'top', button: 'ok', withPadding: false}).pipe(
                map(contactSelectResponse => contactSelectResponse as IDCContactSelectResponse),
                switchMap(contactSelectResponse => {
                    if (!contactSelectResponse) {
                        return of(undefined!);
                    }

                    if (contactSelectResponse.action === AppDossierContactsGroupEditComponent.actions.CREATE) {
                        return this.createContactFromSearch$(contactsGroup, contactSelectResponse.search!);
                    } else if (contactSelectResponse.action === AppDossierContactsGroupEditComponent.actions.IMPORT_FAILED) {
                        return this.importFailedFromInot$(contactsGroup, contactSelectResponse.contact!);
                    }

                    return of(undefined);
                }),
                map(_ => contactsGroup),
            )),
            take(1),
        ).subscribe(contactsGroup => {
            if (!contactsGroup.hasNom() && contactsGroup.members.length > 0) {
                contactsGroup.nom = contactsGroup.members[0].contact.convivialName;
                // @todo Utiliser this.onChangeNom()
                this._contactsGroupNomChangeSource.next(contactsGroup);
            }

            this._searchModalProcessing = false;
        });
    }

    removeMember(contactsGroup: ContactsGroup, contactsGroupMember: ContactsGroupMember): void {
        this._modalService.openConfirmation$({
            buttonConfirmationLabel: 'Retirer',
            question: AppDossierContactsGroupEditComponent.messages.delete.CONFIRMATION,
            title: AppDossierContactsGroupEditComponent.messages.delete.TITLE,
            status: ModalService.status.WARNING,
        }).pipe(
            switchMap(confirmation => {
                if (!confirmation) {
                    return of(undefined);
                }

                return this._contactsGroupMemberService.delete$(contactsGroup, contactsGroupMember);
            }),
            take(1),
        ).subscribe();
    }
}
