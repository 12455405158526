import {Component} from '@angular/core';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {Observable} from 'rxjs';

@Component({templateUrl: 'procedure-signataire.sign-actions-main.component.html'})
export class ProcedureSignataireSignActionsMainComponent {
    static readonly CANCEL = 'ProcedureSignataireSignActionsMainComponent.CANCEL';
    private _callToActionService: CallToActionService;

    constructor(callToActionService: CallToActionService) {
        this._callToActionService = callToActionService;
    }

    get pending$(): Observable<boolean> {
        return this._callToActionService.pending$;
    }

    cancel(): void {
        this._callToActionService.clicked(ProcedureSignataireSignActionsMainComponent.CANCEL);
    }
}
