import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {ICollectionItem} from '@shared/collection/collection.interfaces';

@Injectable({providedIn: 'root'})
export class CollectionSortableService {
    static readonly DRAGENTER_VIRGIN = 'dragenter_virgin';

    private _dragenterUuidsSource = new Subject<[string, string]>();
    private _dragenterUuids$ = this._dragenterUuidsSource.asObservable();
    private _movedItemSource = new Subject<[ICollectionItem, number]>();
    private _movedItem$ = this._movedItemSource.asObservable();
    private _startUuid!: string;
    private _startIdElementList!: string;

    get dragenterUuids$(): Observable<[string, string]> {
        return this._dragenterUuids$;
    }

    get movedItem$(): Observable<[ICollectionItem, number]> {
        return this._movedItem$;
    }

    dragend(): void {
        this._dragenterUuidsSource.next([this._startIdElementList, CollectionSortableService.DRAGENTER_VIRGIN]);
    }

    dragenter(uuid: string): void {
        this._dragenterUuidsSource.next([this._startIdElementList, uuid]);
    }

    end(uuid: string, collectionItems: ICollectionItem[], idElementList: string): void {
        // On ne fait rien car les 2 listes ne sont pas les mêmes
        if (this._startIdElementList !== idElementList) {
            return;
        }

        const idxToMove = collectionItems.findIndex(item => item.uuid === this._startUuid);
        const idxToTake = collectionItems.findIndex(item => item.uuid === uuid);
        const itemToMove = collectionItems[idxToMove];

        collectionItems.splice(idxToMove, 1);
        collectionItems.splice(idxToTake, 0, itemToMove);
        this._movedItemSource.next([itemToMove, idxToTake]);
        this.dragend();
    }

    isDragover(uuid: string, idElementList: string): boolean {
        return uuid !== this._startUuid && idElementList === this._startIdElementList;
    }

    start(uuid: string, idElementList: string): void {
        this._startUuid = uuid;
        this._startIdElementList = idElementList;
    }
}
