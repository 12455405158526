import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {IFormFieldInput, IFormRadioOptions} from '@shared/form/form.interfaces';
import {NgModel} from '@angular/forms';
import {AppFormRadioComponent} from '@shared/form/radio/form.radio.component';
import Bien from '@models/bien/bien.model';

@Component({
    exportAs: 'formViabilisationAssainissement',
    selector: 'app-bien-edit-viabilisation-assainissement',
    templateUrl: 'bien.edit-viabilisation-assainissement.component.html',
})
export class AppBienEditViabilisationAssainissementComponent {
    @Output() selected = new EventEmitter<string>();

    static readonly itemNull: IFormFieldInput = {code: null!, label: 'Non renseigné'};
    static readonly LIST: IFormFieldInput[] = [
        {code: Bien.viabilisationAssainissements.COLLECTIF, label: 'Collectif'},
        {code: Bien.viabilisationAssainissements.AUTONOME, label: 'Autonome'},
        AppBienEditViabilisationAssainissementComponent.itemNull,
    ];
    private _formFieldInput!: IFormFieldInput;
    private _formInput!: NgModel;
    private _formRadioOptions: IFormRadioOptions = {name: 'viabilisationAssainissement'};

    get formInput(): NgModel {
        return this._formInput;
    }

    get formFieldInput(): IFormFieldInput {
        return this._formFieldInput;
    }

    get formRadioOptions(): IFormRadioOptions {
        return this._formRadioOptions;
    }

    @ViewChild('formViabilisationAssainissement')
    set formRadioYnn(value: AppFormRadioComponent) {
        setTimeout(_ => this._formInput = value?.formInput, 1);
    }

    get list(): IFormFieldInput[] {
        return AppBienEditViabilisationAssainissementComponent.LIST;
    }

    @Input()
    set model(value: string) {
        setTimeout(() => this.onSelect(this.list.find(item => item.code === value) ?? AppBienEditViabilisationAssainissementComponent.itemNull));
    }

    onSelect(formFieldInput: IFormFieldInput): void {
        this._formFieldInput = formFieldInput;
        this.selected.emit(this._formFieldInput.code);
    }
}
