import {Component, Input} from '@angular/core';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {Observable} from 'rxjs';
import {IDynamicComponent, IDynamicComponentData} from '@shared/shared.interfaces';

@Component({
    selector: 'location-passerelles-actions-selection',
    templateUrl: 'location-passerelles.actions-selection.component.html',
})
export class LocationPasserellesActionsSelectionComponent implements IDynamicComponent{
    static readonly actions = {REMOVE: 'location_passerelle_remove'};
    private _callToActionService: CallToActionService;
    private _value!: IDynamicComponentData;

    constructor(callToActionService: CallToActionService) {
        this._callToActionService = callToActionService;
    }

    get pending$(): Observable<boolean> {
        return this._callToActionService.pending$;
    }

    @Input()
    set data(value: IDynamicComponentData) {
        this._value = value;
    }

    remove(): void {
        this._callToActionService.clicked(LocationPasserellesActionsSelectionComponent.actions.REMOVE);
    }
}
