import {Component, inject} from '@angular/core';
import {ModalService} from '@shared/modal/modal.service';
import {delay, Observable, of} from 'rxjs';
import Actualite from '@models/actualites/actualite/actualite.model';
import {IDynamicComponent, IDynamicComponentData} from '@shared/shared.interfaces';
import {ActualiteFactory} from '@models/actualites/actualite/actualite.factory';
import environment from '@env/environment';
import CActualites from '@models/actualites/collection/actualites.collection.model';
import {CActualitesFactory} from '@models/actualites/collection/actualites.collection.factory';
import {ToasterService} from '@shared/toaster/toaster.service';
import {take, tap} from 'rxjs/operators';
import {ActualiteService} from '@models/actualites/actualite/actualite.service';

@Component({templateUrl: 'actualite-view.component.html'})
export class DCActualiteViewComponent implements IDynamicComponent {
    private _actualiteFactory = inject(ActualiteFactory);
    private _actualiteService = inject(ActualiteService);
    private _cActualitesFactory = inject(CActualitesFactory);
    private _modalService = inject(ModalService);
    private _toasterService = inject(ToasterService);
    private _actualite$!: Observable<Actualite>;
    private _cActualites!: CActualites;
    private _waiting$ = of(true).pipe(delay(500));

    get actualite$(): Observable<Actualite> {
        return this._actualite$;
    }

    get API_BASE_PATH_URL(): string {
        return environment.api.baseUrl + environment.api.pathUrl;
    }

    set data(value: IDynamicComponentData) {
        let link = value.link as string;

        if (link) {
            this._cActualites = this._cActualitesFactory.createVirgin();
        } else {
            this._cActualites = value.collection as CActualites;
            link = this._cActualites.results[0].linkSelf;
        }

        this._actualite$ = this._actualiteFactory.getByLink$(link);
    }

    get waiting$(): Observable<boolean> {
        return this._waiting$;
    }

    close(): void {
        this._modalService.setResponse();
    }

    next(currentActualite: Actualite): void {
        this._cActualites.removeResult(actualite => actualite.uuid === currentActualite.uuid);
        if (this._cActualites.total > 0) {
            this.data = {collection: this._cActualites};
        } else {
            this.close();
        }
    }

    read(actualite: Actualite): void {
        this._actualiteService.read$(actualite).pipe(
            tap(_ => this._toasterService.success('Actualité marquée comme lue', 'Vous pouvez la retrouver dans le menu "Boîte à outils / Actualités"')),
            take(1),
        ).subscribe(_ => this.next(actualite));
    }
}
