import {Component, Input} from '@angular/core';
import {Observable, of} from 'rxjs';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {DossierBienFactory} from '@models/dossiers/biens/bien/dossier-bien.factory';

@Component({selector: 'app-dossier-reference', templateUrl: 'dossier.reference.component.html'})
export class AppDossierReferenceComponent {
    private _dossierBienFactory: DossierBienFactory;
    private _dossierBien$!: Observable<ADossierBien>;

    constructor(dossierBienFactory: DossierBienFactory) {
        this._dossierBienFactory = dossierBienFactory;
    }

    @Input()
    set dossierBien(value: ADossierBien) {
        this._dossierBien$ = of(value);
    }

    get dossierBien$(): Observable<ADossierBien> {
        return this._dossierBien$;
    }

    @Input()
    set link(value: string) {
        this._dossierBien$ = this._dossierBienFactory.getByLink$(value);
    }
}
