import {Component, Input} from '@angular/core';
import {IDynamicComponent} from '@shared/shared.interfaces';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import Vente from '@models/ventes/vente/vente.model';
import {IVenteActions, IVenteDropdownData, IVenteDropdownDataOptions} from '@features/ventes/ventes.interfaces';

@Component({selector: 'vente-dropdown', templateUrl: 'vente.dropdown.component.html'})
export class VenteDropdownComponent implements IDynamicComponent {
    static readonly actions = {
        ADVANCED_PRINT: 'vente_advanced_print',
        CREATE_BON_VISITE: 'vente_create_bonvisite',
        CREATE_OFFRE_ACHAT: 'vente_create_offreachat',
        LINK: 'vente_link',
        MANAGE_DIFFUSIONS: 'vente_manage_diffusions',
        PRINT: 'vente_print',
        PRINT_AFFICHETTES: 'vente_print_affichettes',
        RESTORE: 'vente_restore',
        SEE: 'vente_see',
        SEE_MATCHED_DEMANDEURS: 'vente_see_matched_demandeurs',
        SEND_FICHE: 'vente_send_fiche',
        SEND_TO_CONFRERE: 'vente_send_to_confrere',
        UNLOCK: 'vente_unlock',
        UPDATE: 'vente_update',
    };
    static readonly initVenteActions: IVenteActions = {
        advancedPrint: false,
        createBonvisite: false,
        createOffreachat: false,
        link: false,
        manageDiffusions: false,
        print: false,
        printAffichettes: false,
        restore: false,
        see: false,
        seeMatchedDemandeurs: false,
        sendFiche: false,
        sendToConfrere: false,
        unlock: false,
        update: false,
    };
    private _dropdownService: DropdownService;
    private _options: IVenteDropdownDataOptions = {...{enabledActions: VenteDropdownComponent.initVenteActions}};
    private _vente!: Vente;

    constructor(dropdownService: DropdownService) {
        this._dropdownService = dropdownService;
    }

    @Input()
    set data(value: IVenteDropdownData) {
        if (!value) {
            return;
        }

        this._vente = value.vente!;
        this._options = {...{enabledActions: VenteDropdownComponent.initVenteActions}, ...value.options};
    }

    get options(): IVenteDropdownDataOptions {
        return this._options;
    }

    advancedPrint(): void {
        this._dropdownService.clicked(VenteDropdownComponent.actions.ADVANCED_PRINT, {vente: this._vente});
    }

    createBonvisite(): void {
        this._dropdownService.clicked(VenteDropdownComponent.actions.CREATE_BON_VISITE, {vente: this._vente});
    }

    createOffreachat(): void {
        this._dropdownService.clicked(VenteDropdownComponent.actions.CREATE_OFFRE_ACHAT, {vente: this._vente});
    }

    link(): void {
        this._dropdownService.clicked(VenteDropdownComponent.actions.LINK, {vente: this._vente});
    }

    manageDiffusions(): void {
        this._dropdownService.clicked(VenteDropdownComponent.actions.MANAGE_DIFFUSIONS, {vente: this._vente});
    }

    print(): void {
        this._dropdownService.clicked(VenteDropdownComponent.actions.PRINT, {vente: this._vente});
    }

    printAffichettes(): void {
        this._dropdownService.clicked(VenteDropdownComponent.actions.PRINT_AFFICHETTES, {vente: this._vente});
    }

    restore(): void {
        this._dropdownService.clicked(VenteDropdownComponent.actions.RESTORE, {vente: this._vente});
    }

    see(): void {
        this._dropdownService.clicked(VenteDropdownComponent.actions.SEE, {vente: this._vente});
    }

    seeMatchedDemandeurs(): void {
        this._dropdownService.clicked(VenteDropdownComponent.actions.SEE_MATCHED_DEMANDEURS, {vente: this._vente});
    }

    sendFiche(): void {
        this._dropdownService.clicked(VenteDropdownComponent.actions.SEND_FICHE, {vente: this._vente});
    }

    sendToConfrere(): void {
        this._dropdownService.clicked(VenteDropdownComponent.actions.SEND_TO_CONFRERE, {vente: this._vente});
    }

    unlock(): void {
        this._dropdownService.clicked(VenteDropdownComponent.actions.UNLOCK, {vente: this._vente});
    }

    update(): void {
        this._dropdownService.clicked(VenteDropdownComponent.actions.UPDATE, {vente: this._vente});
    }
}
