import {Component, inject} from '@angular/core';
import {ModalService} from '@shared/modal/modal.service';
import {IDynamicComponent} from '@shared/shared.interfaces';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';

@Component({templateUrl: 'dossier-bien.restoration.component.html'})
export class DCDossierBienRestorationComponent implements IDynamicComponent {
    private _modalService = inject(ModalService);
    private _dossierBien!: ADossierBien;
    private _keepProprietaires = false;

    set data(value: {dossierBien: ADossierBien}) {
        this._dossierBien = value.dossierBien;
    }

    get dossierBien(): ADossierBien {
        return this._dossierBien;
    }

    get keepProprietaires(): boolean {
        return this._keepProprietaires;
    }

    set keepProprietaires(value: boolean) {
        this._keepProprietaires = value;
    }

    get WARNING_STATUS(): string {
        return ModalService.status.WARNING;
    }

    response(isAccepted: boolean): void {
        this._modalService.setResponse({keepProprietaires: this.keepProprietaires, isAccepted});
    }
}
