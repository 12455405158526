import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgModel} from '@angular/forms';
import {AppFormSelectInputComponent} from '@shared/form/select/input/form.select-input.component';
import Template from '@models/templates/template/template.model';
import {take, tap} from 'rxjs/operators';
import {ITemplatesSelectOptions} from '@features/templates/templates.interfaces';
import {CTemplatesService} from '@models/templates/collection/templates.collection.service';

@Component({exportAs: 'templateSelect', selector: 'app-template-select', templateUrl: 'template-select.component.html'})
export class AppTemplateSelectComponent implements OnInit {
    @Output() selected = new EventEmitter<Template>();

    static readonly initTemplateSelectOptions: ITemplatesSelectOptions = {
        bindLabel: 'name',
        name: 'templateSelect',
        notFoundText: 'Aucun modèle',
        placeholder: 'Sélectionnez un modèle',
        selectFirst: false,
    };
    private _cTemplatesService: CTemplatesService;
    private _categories: string[] = [];
    private _formInput!: NgModel;
    private _loading = true;
    private _model!: Template;
    private _options: ITemplatesSelectOptions = {...AppTemplateSelectComponent.initTemplateSelectOptions};
    private _templates: Template[] = [];

    constructor(cTemplatesService: CTemplatesService) {
        this._cTemplatesService = cTemplatesService;
    }

    @Input()
    set categories(value: string[]) {
        this._categories = value;
    }

    @Input()
    set category(value: string) {
        this._categories = [value];
    }

    get formInput(): NgModel {
        return this._formInput;
    }

    get loading(): boolean {
        return this._loading;
    }

    get model(): Template {
        return this._model;
    }

    @Input()
    set model(value: Template) {
        this._model = value;
    }

    get options(): ITemplatesSelectOptions {
        return this._options;
    }

    @Input()
    set options(value: ITemplatesSelectOptions) {
        this._options = {...AppTemplateSelectComponent.initTemplateSelectOptions, ...value};
    }

    get templates(): Template[] {
        return this._templates;
    }

    @ViewChild('templateSelectInput')
    set templateSelectInput(value: AppFormSelectInputComponent) {
        setTimeout(_ => this._formInput = value.formInput, 1);
    }

    ngOnInit(): void {
        this._cTemplatesService.getWithDefaultFirst$(this._categories).pipe(
            tap(cTemplates => {
                if (this.options.selectFirst && cTemplates.total > 0) {
                    this.model = cTemplates.results[0];
                    this.onSelect(this.model);
                }
            }),
            take(1),
        ).subscribe({
            next: cTemplates => this._templates = cTemplates.results,
            complete: () => this._loading = false,
        });
    }

    onSelect(template: unknown): void {
        this.selected.emit(template as Template);
    }
}
