import {inject, Injectable} from '@angular/core';
import {BienFactory} from '@models/bien/bien.factory';
import Estimation from '@models/estimations/estimation/estimation.model';
import {Observable} from 'rxjs';
import {IEstimationApi} from '@models/estimations/estimation/estimation.interfaces';
import {NgEstimation} from '@legacy/app/managers/ressources';
import {map, tap} from 'rxjs/operators';
import {EstimationsApiService} from '@models/estimations/estimations.api.service';
import {DictionaryItemFactory} from '@models/dictionaries/dictionary/items/item/dictionary-item.factory';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import DateFormat from '@shared/date/date.format';

@Injectable({providedIn: 'root'})
export class EstimationFactory {
    private _bienFactory = inject(BienFactory);
    private _dictionaryItemFactory = inject(DictionaryItemFactory);
    private _dictionaryItemService = inject(DictionaryItemService);
    private _estimationsApiService = inject(EstimationsApiService);

    create(estimationApi: IEstimationApi): Estimation {
        const reference = estimationApi._embedded?.bien?.reference;
        const estimation = new Estimation(estimationApi.uuid, estimationApi.id, reference);

        estimation.courrierIntroductifRapportId = estimationApi.courrierIntroductifRapportId;
        estimation.dateCreation = estimationApi.dateCreation;
        estimation.dateRemise = estimationApi.dateRemise;
        estimation.motif = estimationApi.motif;
        estimation.rapportId = estimationApi.rapportId;
        estimation.statut = estimationApi.statut;
        estimation.type = this._dictionaryItemService.getByCode(Dictionary.names.ESTIMATION_TYPES, estimationApi.type || Estimation.types.AVIS_VALEUR);
        estimation.typeValeurVenale = this._dictionaryItemService.getByCode(Dictionary.names.ESTIMATION_VALEUR_VENALE_TYPES, estimationApi.typeValeurVenale || Estimation.valeurVenaleTypes.SIMPLE);
        estimation.valeurVenale = estimationApi.valeurVenale;
        estimation.valeurVenaleMax = estimationApi.valeurVenaleMax;
        estimation.valeurVenaleMin = estimationApi.valeurVenaleMin;

        if (estimationApi._embedded?.bien) {
            estimation.bien = this._bienFactory.create(estimationApi._embedded.bien);
        }

        if (estimationApi._links) {
            if (estimationApi._links.contactsGroup) {
                estimation.linkContactsGroup = estimationApi._links.contactsGroup.href;
            }

            if (estimationApi._links.self) {
                estimation.linkSelf = estimationApi._links.self.href;
            }
        }

        return estimation;
    }

    getByLink$(link: string): Observable<Estimation> {
        return this._estimationsApiService.getByLink$(link).pipe(map(estimationApi => this.create(estimationApi)));
    }

    remettreRapport$(estimation: Estimation): Observable<void> {
        return this._estimationsApiService.remettreRapport$(estimation.id.toString()).pipe(
            tap(_ => estimation.dateRemise = DateFormat.toJSON(DateFormat.dateFromNow())),
            tap(_ => estimation.statut = Estimation.abstractStatuts.REMIS),
        );
    }

    ngCreate(ngEstimation: NgEstimation): Estimation {
        const reference = ngEstimation.bien?.reference;
        const estimation = new Estimation(ngEstimation.uuid, ngEstimation.id, reference);
        const sanitizedEstimation = ngEstimation.sanitize() as NgEstimation;

        estimation.courrierIntroductifRapportId = sanitizedEstimation.courrierIntroductifRapportId;
        estimation.motif = ngEstimation.motif;
        estimation.rapportId = sanitizedEstimation.rapportId;
        estimation.statut = ngEstimation.statut;
        estimation.valeurVenale = sanitizedEstimation.valeurVenale;
        estimation.valeurVenaleMax = sanitizedEstimation.valeurVenaleMax;
        estimation.valeurVenaleMin = sanitizedEstimation.valeurVenaleMin;

        if (ngEstimation._links) {
            if (ngEstimation._links.contactsGroup) {
                estimation.linkContactsGroup = ngEstimation._links.contactsGroup.href;
            }

            if (ngEstimation._links.self) {
                estimation.linkSelf = ngEstimation._links.self.href;
            }
        }

        if (ngEstimation.bien) {
            estimation.bien = this._bienFactory.ngCreate(ngEstimation.bien);
        }

        if (ngEstimation.dateRemise) {
            estimation.dateRemise = DateFormat.toJSON(ngEstimation.dateRemise);
        }

        if (ngEstimation.type) {
            estimation.type = this._dictionaryItemFactory.ngCreate(ngEstimation.type);
        } else {
            estimation.type = this._dictionaryItemService.getByCode(Dictionary.names.ESTIMATION_TYPES, Estimation.types.AVIS_VALEUR);
        }

        if (ngEstimation.typeValeurVenale) {
            estimation.typeValeurVenale = this._dictionaryItemFactory.ngCreate(ngEstimation.typeValeurVenale);
        } else {
            estimation.typeValeurVenale = this._dictionaryItemService.getByCode(Dictionary.names.ESTIMATION_VALEUR_VENALE_TYPES, Estimation.valeurVenaleTypes.SIMPLE);
        }

        return estimation;
    }
}
