import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {PhoneNumberService} from '@shared/texts/phones/phone/number/phone-number.service';

@Directive({
    selector: '[appFormPhoneValidator]',
    providers: [{provide: NG_VALIDATORS, useExisting: FormPhoneValidatorDirective, multi: true}],
})
export class FormPhoneValidatorDirective implements Validator {
    private _phoneNumberService: PhoneNumberService;

    constructor(phoneNumberService: PhoneNumberService) {
        this._phoneNumberService = phoneNumberService;
    }

    validate(control: AbstractControl<string>): ValidationErrors | null {
        return !control.value || this._phoneNumberService.isValid(control.value) ? null : {phone: true};
    }
}
