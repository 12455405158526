import angularJS from '@shared/angularJS/global.ng';
import {IModule, IPromise, IQService, IWindowService} from 'angular';
import {NgAuthenticationService} from '@legacy/app/auth/auth';
import {NgClientConfig} from '@legacy/app/client/client';

export default function getClientWindow(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('ClientWindow', Factory);

        /**
         * All methods to use Window
         *
         * @param $q
         * @param $window
         * @param ClientConfig
         * @param AuthenticationService
         * @returns {ClientWindow}
         */
        Factory.$inject = ['$q', '$window', 'ClientConfig', 'AuthenticationService'];
        function Factory($q: IQService,
                         $window: IWindowService,
                         clientConfig: NgClientConfig,
                         authenticationService: NgAuthenticationService) {
            const factory = {openFromAPI: openFromAPI, openFromNoty: openFromNoty, openLink: openLink};

            return factory;

            /**
             * Open from API
             *
             * @param path
             * @param queryParams
             * @param windowName
             * @returns {Promise}
             */
            function openFromAPI(path: string, queryParams: { access_token: string }, windowName: string) {
                queryParams.access_token = authenticationService.getAccessToken();

                return factory.openLink(clientConfig.getFullUrl(path, queryParams), windowName);
            }

            /**
             * Open from Noty
             *
             * @param path
             * @param queryParams
             * @param windowName
             * @returns {Promise}
             */
            function openFromNoty(path: string, queryParams: unknown[], windowName: string) {
                const stringParams = clientConfig.serializeURIParams(queryParams);
                let link = $window.origin + path;

                if (stringParams !== "") {
                    link = link + (link.indexOf("?") <= 0 ? "?" : "&") + stringParams;
                }

                return factory.openLink(link, windowName);
            }

            /**
             * Open new Tab with link
             *
             * @param link
             * @param windowName
             * @returns {Promise}
             */
            function openLink(link: string, windowName: string): IPromise<Window> {
                if (!angular.isString(windowName) || windowName === '') {
                    windowName = '_blank';
                }

                return $q.resolve($window.open(link, windowName)!);
            }
        }
    })(angularJS);
}
