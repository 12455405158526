import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {ErrorHandlerService} from '@core/error/error.handler.service';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import ABOUT from '@env/about';
import {ApiService} from '@core/api/api.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    static readonly HANDLED_403_URL_SLUG_ERRORS = [ApiService.SWITCH_USER_KEY];
    static readonly headerKeyAccept = 'Accept';
    static readonly headerKeyContentType = 'Content-Type';
    static readonly keyHeaders = {handledStatusErrors: 'handledstatuserrors', handledTypeErrors: 'handledtypeerrors'};
    private _errorHandlerService = inject(ErrorHandlerService);

    intercept<T = unknown>(httpRequestBrute: HttpRequest<T>, httpHandler: HttpHandler): Observable<HttpEvent<T>> {
        const acceptContentType = 'application/vnd.noty.api+json;version=' + ABOUT.versionAPI;
        let httpRequest: HttpRequest<T> = httpRequestBrute.clone();

        if (!httpRequestBrute.headers.get(ApiInterceptor.headerKeyAccept)) {
            httpRequest = httpRequest.clone({setHeaders: {[ApiInterceptor.headerKeyAccept]: acceptContentType}});
        }

        if (!(httpRequest.body instanceof FormData)) {
            httpRequest = httpRequest.clone({setHeaders: {[ApiInterceptor.headerKeyContentType]: acceptContentType}});
        }

        return httpHandler.handle(httpRequest).pipe(
            catchError((httpErrorResponse: HttpErrorResponse): Observable<never> => {
                let ignoreCatchError = false;

                if (httpErrorResponse) {
                    const handledStatusErrors = (httpRequest.headers.getAll(ApiInterceptor.keyHeaders.handledStatusErrors) ?? [])
                        .map(handledStatusError => +handledStatusError);
                    const handledTypeErrors = httpRequest.headers.getAll(ApiInterceptor.keyHeaders.handledTypeErrors)!;

                    if (httpRequest.url.endsWith('/upload')) {
                        if (httpErrorResponse.status === 403 && this.isHandledTypeErrors(httpErrorResponse, handledTypeErrors)) {
                            ignoreCatchError = true;
                        } else if (httpErrorResponse.status === 409) {
                            ignoreCatchError = true;
                        }
                    } else if (Array.isArray(handledStatusErrors) && handledStatusErrors.length > 0 && handledStatusErrors.includes(httpErrorResponse.status)) {
                        ignoreCatchError = true;
                    } else if (httpErrorResponse.status === 400 && this.isHandledTypeErrors(httpErrorResponse, handledTypeErrors)) {
                        ignoreCatchError = true;
                    } else if (httpErrorResponse.status === 403 && this.isHandledUrlSlugErrors(httpErrorResponse, ApiInterceptor.HANDLED_403_URL_SLUG_ERRORS)) {
                        ignoreCatchError = true;
                    }
                }

                if (!ignoreCatchError) {
                    this._errorHandlerService.catchError(httpErrorResponse);
                }

                return throwError(() => httpErrorResponse);
            })
        );
    }

    isHandledTypeErrors(httpErrorResponse: HttpErrorResponse, handledTypeErrors: string[] = []): boolean {
        const error = httpErrorResponse.error as { type?: string };

        // Ajouter pour comprendre pourquoi "handledTypeErrors.length" n'est pas tout le temps défini
        try {
            if (handledTypeErrors.length > 0 && httpErrorResponse.error && error.type) {
                return handledTypeErrors.some(handledTypeError => error.type!.includes(handledTypeError));
            }
        } catch (e) {
            this._errorHandlerService.catchError(JSON.stringify({e, handledTypeErrors}));
        }

        /*
        If (handledTypeErrors.length > 0 && httpErrorResponse.error && error.type) {
            return handledTypeErrors.some(handledTypeError => error.type!.includes(handledTypeError));
        }
        */

        return false;
    }

    isHandledUrlSlugErrors(httpErrorResponse: HttpErrorResponse, handledUrlSlugErrors: string[] = []): boolean {
        return handledUrlSlugErrors.some(handledUrlSlugError => httpErrorResponse.url?.includes(handledUrlSlugError));
    }
}
