import {Injectable} from '@angular/core';
import {IDynamicComponentLoading} from '@shared/shared.interfaces';
import {BehaviorSubject, Observable, of, ReplaySubject, Subject, tap, throwError} from 'rxjs';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {catchError, filter, map, switchMap, take} from 'rxjs/operators';
import {ICallToActionClicked, ICallToActionRouteData} from '@shared/call-to-action/call-to-action.interfaces';
import {RoutesService} from '@shared/routes/routes.service';

@Injectable({providedIn: 'root'})
export class CallToActionService {
    static readonly MAIN = 'call-to-action-main';
    static readonly SELECTION = 'call-to-action-selection';
    private _router: Router;
    private _routesService: RoutesService;
    private _clickedSource = new Subject<ICallToActionClicked>();
    private _clicked$ = this._clickedSource.asObservable();
    private _ctaMainSource = new ReplaySubject<IDynamicComponentLoading>();
    private _ctaSelectionSource = new ReplaySubject<IDynamicComponentLoading>();
    private _pendingSource = new BehaviorSubject<boolean>(false);
    private _pending$ = this._pendingSource.asObservable();

    constructor(router: Router, routesService: RoutesService) {
        this._router = router;
        this._routesService = routesService;
    }

    get clicked$(): Observable<ICallToActionClicked> {
        return this._clicked$;
    }

    get pending$(): Observable<boolean> {
        return this._pending$;
    }

    actionExec$<T>(action$: Observable<T>): Observable<T> {
        this._pendingSource.next(true);

        return action$.pipe(
            catchError((error: unknown) => {
                this._pendingSource.next(false);

                return throwError(() => error);
            }),
            tap(_ => this._pendingSource.next(false)),
            take(1),
        );
    }

    clicked(action: string, value?: unknown): void {
        this._clickedSource.next({action, value});
    }

    dynamicComponentLoading$(typeActions: string): Observable<IDynamicComponentLoading> {
        if (typeActions === CallToActionService.MAIN) {
            return this._ctaMainSource.asObservable();
        } else if (typeActions === CallToActionService.SELECTION) {
            return this._ctaSelectionSource.asObservable();
        }

        return of(undefined! as IDynamicComponentLoading);
    }

    init(): void {
        this._router.events.pipe(
            filter(event => event instanceof NavigationEnd || event instanceof NavigationStart),
            filter(event => {
                if (event instanceof NavigationStart) {
                    const url = event.url.split('#')[0];

                    return this._router.url !== url;
                }

                return true;
            }),
            switchMap(event => {
                if (event instanceof NavigationStart) {
                    this._pendingSource.next(false);

                    return of([undefined!, undefined!]);
                }

                return this._routesService.dataFromRoot$<ICallToActionRouteData>().pipe(
                    filter(routeData => !!routeData && !!routeData.actions),
                    map(routeData => {
                        let mainComponentLoading: IDynamicComponentLoading = undefined!;
                        let selectionComponentLoading: IDynamicComponentLoading = undefined!;

                        if (routeData.actions) {
                            if (routeData.actions.main) {
                                mainComponentLoading = {
                                    component: routeData.actions.main.component,
                                    data: routeData.actions.main.data,
                                } as IDynamicComponentLoading;
                            }

                            if (routeData.actions.selection) {
                                selectionComponentLoading = {
                                    component: routeData.actions.selection.component,
                                    data: routeData.actions.selection.data,
                                } as IDynamicComponentLoading;
                            }
                        }

                        return [mainComponentLoading, selectionComponentLoading];
                    })
                );
            }),
        ).subscribe(([mainComponentLoading, selectionComponentLoading]) => this.setDynamicComponentLoading(mainComponentLoading, selectionComponentLoading));
    }

    setDynamicComponentLoading(mainComponentLoading: IDynamicComponentLoading, selectionComponentLoading: IDynamicComponentLoading = undefined!): void {
        this._ctaMainSource.next(mainComponentLoading);
        this._ctaSelectionSource.next(selectionComponentLoading);
    }
}
