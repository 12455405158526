import {ElementRef, Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, ParamMap, Router} from '@angular/router';
import {from, interval, Observable, of, switchMap} from 'rxjs';
import {expand, filter, map, take, takeWhile} from 'rxjs/operators';
import {SessionStorageService} from '@core/storage/session-storage.service';
import {DossierBienType} from '@models/dossiers/biens/dossier-biens.constants';

@Injectable({providedIn: 'root'})
export class RoutesService {
    private _router: Router;
    private _sessionStorageService: SessionStorageService;

    constructor(router: Router, sessionStorageService: SessionStorageService) {
        this._router = router;
        this._sessionStorageService = sessionStorageService;
    }

    activatedRouteSnapshotFromRoot$(): Observable<ActivatedRouteSnapshot> {
        return of(this._router.routerState.snapshot.root).pipe(
            expand(activatedRouteSnapshot => of(activatedRouteSnapshot.firstChild!)),
            takeWhile(activatedRouteSnapshot => !!activatedRouteSnapshot),
            filter(activatedRouteSnapshot => !activatedRouteSnapshot.firstChild!),
        );
    }

    dataFromRoot$<T>(): Observable<T> {
        return this.activatedRouteSnapshotFromRoot$().pipe(map(activatedRouteSnapshot => activatedRouteSnapshot.data as T));
    }

    // @todo Faire retourner un DossierType pour la compatibilité avec Demandeur
    getTypeDossierFromUrl(): DossierBienType {
        return this._router.url.split('/')[2].slice(0, -1) as DossierBienType;
    }

    paramsFromRoot$(): Observable<ParamMap> {
        return this.activatedRouteSnapshotFromRoot$().pipe(map(activatedRouteSnapshot => activatedRouteSnapshot.paramMap));
    }

    pathFromRoot(activatedRouteSnapshotParam: ActivatedRouteSnapshot): string {
        return '/' + activatedRouteSnapshotParam.pathFromRoot
            .filter(activatedRouteSnapshot => activatedRouteSnapshot.url.length > 0)
            .map(route => route.url[0].path).join('/');
    }

    redirect$(navigatedUrl: string, redirectedUrl: string): Observable<boolean> {
        if (!['/auth/logout', '/start'].includes(redirectedUrl)) {
            this._sessionStorageService.set('redirectedUrl', redirectedUrl);
        }

        return from(this._router.navigateByUrl(navigatedUrl));
    }

    // @todo Mettre en place une directive afin de ne pas avoir d'appel dans les layout mais quand le DOM est prêt
    scrollToAnchor$(activatedRoute: ActivatedRoute, elementRef: ElementRef<HTMLElement>): Observable<void> {
        return activatedRoute.fragment.pipe(
            filter(fragment => !!fragment),
            switchMap(anchor => interval(10).pipe(map(_ => elementRef.nativeElement.querySelector('#' + anchor!)!))),
            filter(elementAnchor => !!elementAnchor && !!elementAnchor.scrollIntoView),
            map(elementAnchor => elementAnchor.scrollIntoView({behavior: 'smooth'})),
            take(1),
        );
    }
}
