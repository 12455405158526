import {IModule} from "angular";
import angularJS from '@shared/angularJS/global.ng';
import getClientConfig from "@legacy/app/client/services/config";
import getClientService from "@legacy/app/client/services/client";
import getClientHttp from "@legacy/app/client/services/http";
import getClientWindow from "@legacy/app/client/services/window";
import getClientFileAPI from "@legacy/app/client/services/fileapi";
import getClientPaginate from "@legacy/app/client/services/paginate";

export default function getClient(): IModule {
    return (function (angular) {
        'use strict';

        const module = angular.module('esk.client', ['esk.auth', 'soqrate']);

        // Config / Run
        getClientConfig(module);

        // Services
        getClientFileAPI(module);
        getClientHttp(module);
        getClientPaginate(module);
        getClientService(module);
        getClientWindow(module);

        return module;
    })(angularJS);
}
