import {
    Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewContainerRef
} from '@angular/core';
import {ReplaySubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ClassCSSConst, SortDefinition} from '@shared/constants';
import {CollectionColumnSortComponent} from '@shared/collection/column-sort/collection-column.sort.component';

@Directive({selector: '[appCollectionColumnSort]'})
export class AppCollectionColumnSortDirective implements OnDestroy, OnInit {
    @Output() changed = new EventEmitter<SortDefinition>();

    private _elementRef: ElementRef<HTMLElement>;
    private _renderer2: Renderer2;
    private _viewContainerRef: ViewContainerRef;
    private _initSort!: SortDefinition;
    private readonly _onDestroy$ = new Subject<void>();
    private _sortSource = new ReplaySubject<SortDefinition>(1);

    constructor(elementRef: ElementRef<HTMLElement>, renderer2: Renderer2, viewContainerRef: ViewContainerRef) {
        this._elementRef = elementRef;
        this._renderer2 = renderer2;
        this._viewContainerRef = viewContainerRef;
    }

    @Input()
    set appCollectionColumnSort(sort: SortDefinition) {
        this._sortSource.next(sort);
    }

    ngOnInit(): void {
        const divContent = this._elementRef.nativeElement.firstChild;
        const componentRef = this._viewContainerRef.createComponent(CollectionColumnSortComponent);

        this._sortSource.asObservable().pipe(takeUntil(this._onDestroy$)).subscribe(sort => componentRef.instance.sort = sort);
        componentRef.instance.changed.asObservable().pipe(takeUntil(this._onDestroy$)).subscribe(sort => this.changed.emit(sort));

        this._renderer2.addClass(divContent, ClassCSSConst.FLEX);
        this._renderer2.appendChild(divContent, componentRef.location.nativeElement);
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }
}
