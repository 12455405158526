import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {Observable, of, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {
    EtudeSettingsDocumentsHeaderService
} from '@models/etudes/etude/settings/documents/header/etude-settings-documents-header.service';
import {IDynamicComponent, IDynamicComponentData} from '@shared/shared.interfaces';
import {
    EtudeSettingsDocumentsFooterService
} from '@models/etudes/etude/settings/documents/footer/etude-settings-documents-footer.service';
import {
    ConfigurationActionsMainDropdownComponent
} from '@features/configuration/actions/dropdown/configuration-actions-main.dropdown.component';
import {DropdownService} from '@shared/dropdown/dropdown.service';

@Component({templateUrl: 'configuration-actions-main.component.html'})
export class ConfigurationActionsMainComponent implements IDynamicComponent, OnDestroy, OnInit {
    static readonly actions = ConfigurationActionsMainDropdownComponent.actions;
    private _callToActionService = inject(CallToActionService);
    private _dropdownService = inject(DropdownService);
    private _etudeSettingsDocumentsFooterService = inject(EtudeSettingsDocumentsFooterService);
    private _etudeSettingsDocumentsHeaderService = inject(EtudeSettingsDocumentsHeaderService);
    private _canSave$!: Observable<boolean>;
    private readonly _onDestroy$ = new Subject<void>();
    private _showDropdown = false;

    get canSave$(): Observable<boolean> {
        return this._canSave$;
    }

    set data(value: IDynamicComponentData & { type: string }) {
        this._showDropdown = false;
        if (value && ['dossiers', 'etude'].includes(value.type)) {
            this._canSave$ = of(true);
            this._showDropdown = value.type === 'etude';
        } else if (value?.type === 'footer') {
            this._canSave$ = this._etudeSettingsDocumentsFooterService.current$.pipe(
                map(etudeSettingsDocumentsFooter => etudeSettingsDocumentsFooter?.enabled),
            );
        } else if (value?.type === 'header') {
            this._canSave$ = this._etudeSettingsDocumentsHeaderService.current$.pipe(
                map(etudeSettingsDocumentsHeader => etudeSettingsDocumentsHeader?.enabled),
            );
        } else {
            this._canSave$ = of(false);
        }
    }

    get pending$(): Observable<boolean> {
        return this._callToActionService.pending$;
    }

    get showDropdown(): boolean {
        return this._showDropdown;
    }

    ngOnInit(): void {
        this._dropdownService.clicked$.pipe(takeUntil(this._onDestroy$)).subscribe(dropdownClicked =>
            this._callToActionService.clicked(dropdownClicked.action, dropdownClicked.value)
        );
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    clickDropdown(htmlButtonElement: HTMLButtonElement): void {
        this._dropdownService.open(htmlButtonElement, {
            component: ConfigurationActionsMainDropdownComponent,
        });
    }

    save(): void {
        this._callToActionService.clicked(ConfigurationActionsMainComponent.actions.SAVE);
    }
}
