import {Inject, Injectable} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Editor, EditorOptions} from 'tinymce';
import {ToasterService} from '@shared/toaster/toaster.service';
import {ITinymceEvent, ITinymceOptions, WindowTinymce} from '@shared/tinymce/tinymce.interfaces';
import {TinymceBalisesService} from '@shared/tinymce/tinymce.balises.service';

@Injectable({providedIn: 'root'})
export class TinymceService {
    static readonly DOCUMENT_MARGIN = 5;
    static readonly plugins = {
        AUTO_RESIZE: 'autoresize',
        BALISES: TinymceBalisesService.PLUGIN_NAME,
        FULL_SCREEN: 'fullscreen',
        LISTS: 'lists',
        PAGE_BREAK: 'pagebreak',
        TABLE: 'table',
    };
    static readonly defaultOptions = {
        base_url: '/assets/tinymce',
        browser_spellcheck: true,
        content_css: [
            '/assets/tinymce/content.min.css',
            '/assets/tinymce/tinymce.css',
            '/assets/tinymce/skins/ui/oxide/content.min.css',
        ],
        // https://gitlab.soqrate.com/soqrate/noty/noty/-/issues/716 : A supprimer
        content_style: 'body {margin: ' + TinymceService.DOCUMENT_MARGIN.toString() + 'mm;}',
        extended_valid_elements: 'path[*],svg[*]',
        font_size_formats: '8px 9px 10px 11px 12px 14px 16px 18px 24px 36px 48px',
        height: 800,
        language: 'fr_FR',
        // Voir https://www.tiny.cloud/get-tiny/language-packages pour mettre à jour
        language_url: 'assets/tinymce/fr_FR.js',
        menu: {
            insert: {
                title: 'Insert',
                items: 'image link media addcomment pageembed template codesample | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime',
            },
        },
        menubar: 'edit insert format table',
        newline_behavior: 'invert',
        noneditable_regexp: [],
        object_resizing: 'img',
        plugins: [TinymceService.plugins.TABLE],
        promotion: false,
        relative_urls: false,
        remove_script_host: false,
        removed_menuitems: 'newdocument, visualaid',
        skin: false,
        statusbar: false,
        table_toolbar: '',
    } as unknown as EditorOptions;
    static readonly events = {
        BEFORE_EXEC_COMMAND: 'BeforeExecCommand',
        BEFORE_SET_CONTENT: 'BeforeSetContent',
        CHANGE: 'Change',
        EXEC_COMMAND: 'ExecCommand',
        INIT: 'init',
        POST_PROCESS: 'PostProcess',
    };
    static readonly menuItems = {BALISES: TinymceBalisesService.MENU_ITEMS};
    static readonly pluginOptions = {balisesModels: TinymceBalisesService.pluginOptions};
    static readonly toolbars = {FULL_SCREEN: 'fullscreen'};
    private _deviceDetectorService: DeviceDetectorService;
    private _tinymceBalisesService: TinymceBalisesService;
    private _toasterService: ToasterService;
    /**
     * @deprecated Use getOptions()
     */
    private readonly _completeOptions: EditorOptions;

    static hasStyleFloat(htmlElement: HTMLElement): boolean {
        if (!htmlElement) {
            return false;
        }

        // @todo Ne pas utiliser window mais injecter le service
        if (['left', 'right', 'inline-start', 'inline-end'].includes(window.getComputedStyle(htmlElement).float)) {
            return true;
        }

        return TinymceService.hasStyleFloat(htmlElement.parentElement!);
    }

    constructor(deviceDetectorService: DeviceDetectorService,
                tinymceBalisesService: TinymceBalisesService,
                toasterService: ToasterService,
                @Inject('Window') window: WindowTinymce) {
        this._deviceDetectorService = deviceDetectorService;
        this._tinymceBalisesService = tinymceBalisesService;
        this._toasterService = toasterService;

        // Mise en place du système de balises
        // @todo Ne pas utiliser window mais injecter le service
        this._tinymceBalisesService.addPlugin(window.tinymce.PluginManager);

        /**
         * @deprecated Use getOptions()
         */
        const defaultOptions = JSON.parse(JSON.stringify(TinymceService.defaultOptions)) as EditorOptions;
        defaultOptions.plugins = [];
        if (this._deviceDetectorService.isDesktop()) {
            defaultOptions.plugins.push(TinymceService.plugins.FULL_SCREEN);
        }
        defaultOptions.plugins.push(TinymceService.plugins.LISTS);
        defaultOptions.plugins.push(TinymceService.plugins.TABLE);
        defaultOptions.table_toolbar = '';
        this._completeOptions = JSON.parse(JSON.stringify(defaultOptions)) as EditorOptions;
        this._completeOptions.content_css.push('/assets/tinymce/tinymce.pagebreak.css');
        this._completeOptions.extended_valid_elements! += ',soq-pagebreak';
        this._completeOptions.pagebreak_separator = '<soq-pagebreak></soq-pagebreak>';
        this._completeOptions.pagebreak_split_block = true;
        this._completeOptions.plugins.push(TinymceService.plugins.PAGE_BREAK);
        this._completeOptions.setup = (editor: Editor) => {
            editor.on(TinymceService.events.INIT, _ => this.improvePageBreak(TinymceService.events.INIT, editor));
            editor.on(TinymceService.events.CHANGE, _ => this.improvePageBreak(TinymceService.events.CHANGE, editor));
        };
        this._completeOptions.toolbar = (deviceDetectorService.isDesktop() ? (TinymceService.toolbars.FULL_SCREEN + ' | ') : '') +
            'undo redo | fontfamily | bold italic underline strikethrough | forecolor backcolor | alignleft aligncenter alignright alignjustify ' +
            '| outdent indent | bullist numlist | styles blocks fontsize pagebreak | cut copy paste | removeformat';
    }

    /**
     * @deprecated Use getOptions()
     */
    get completeOptions(): EditorOptions {
        return {...this._completeOptions};
    }

    addPlugins(editorOptions: EditorOptions, addedPlugins: string[] = []): EditorOptions {
        let nonEditableRegexp: RegExp = undefined!;

        addedPlugins.forEach(addedPlugin => {
            switch (addedPlugin) {
                case TinymceService.plugins.BALISES :
                    editorOptions.content_css.push('/assets/tinymce/tinymce.balises.css');
                    nonEditableRegexp = TinymceBalisesService.FIND_ALL;
                    editorOptions.plugins.push(TinymceService.plugins.BALISES);
                    this._tinymceBalisesService.options = editorOptions[TinymceBalisesService.pluginOptions.KEY] as string[];

                    break;

                case TinymceService.plugins.FULL_SCREEN :
                    if (this._deviceDetectorService.isDesktop()) {
                        editorOptions.plugins.push(TinymceService.plugins.FULL_SCREEN);
                    }

                    break;

                case TinymceService.plugins.PAGE_BREAK :
                    editorOptions.content_css.push('/assets/tinymce/tinymce.pagebreak.css');
                    editorOptions.extended_valid_elements! += ',soq-pagebreak';
                    editorOptions.pagebreak_separator = '<soq-pagebreak></soq-pagebreak>';
                    editorOptions.pagebreak_split_block = true;
                    editorOptions.plugins.push(TinymceService.plugins.PAGE_BREAK);

                    break;

                default:
                    editorOptions.plugins.push(addedPlugin);
            }
        });

        if (nonEditableRegexp) {
            editorOptions.noneditable_regexp.push(nonEditableRegexp);
        }

        return editorOptions;
    }

    getDefaultOptions(): EditorOptions {
        const options = JSON.parse(JSON.stringify(TinymceService.defaultOptions)) as EditorOptions;

        options.init_instance_callback = editor => {
            const forecolor = editor.formatter.get('forecolor');
            const hilitecolor = editor.formatter.get('hilitecolor');

            if (forecolor) {
                forecolor[0].exact = true;
            }

            if (hilitecolor) {
                hilitecolor[0].exact = true;
            }
        };
        options.menu.insert.items += ' | ' + TinymceService.menuItems.BALISES;
        options.setup = editor => {
            editor.on(TinymceService.events.BEFORE_EXEC_COMMAND, (event: ITinymceEvent) => this.setNonEditableContent(editor, event, null));
            editor.on(TinymceService.events.CHANGE, _ => this.improvePageBreak(TinymceService.events.CHANGE, editor));
            editor.on(TinymceService.events.EXEC_COMMAND, (event: ITinymceEvent) => this.setNonEditableContent(editor, event, 'false'));
            editor.on(TinymceService.events.INIT, _ => this.improvePageBreak(TinymceService.events.INIT, editor));
        };
        // @todo Réfléchir à l'utilisation de la toolbar en mode tableau et plus en string
        options.toolbar = TinymceService.toolbars.FULL_SCREEN + ' | undo redo | cut copy paste | ' +
            'bold italic underline | forecolor backcolor | alignleft aligncenter alignright alignjustify | ' +
            'bullist numlist | outdent indent | pagebreak | removeformat';

        return options;
    }

    // @todo Voir pour supprimer le type au profit des options
    getOptions(type: 'simple' | 'complete' = 'simple', options = {} as ITinymceOptions): EditorOptions {
        const addedPlugins = options.plugins ?? [];
        const editorOptions = {...this.getDefaultOptions(), ...(options.pluginsOptions ?? {}) as EditorOptions};
        let addedToolbar = '';

        editorOptions.content_css = editorOptions.content_css.concat(options.content_css ?? []);
        if (options.withToolbarStyles) {
            addedToolbar += ' styles';
        }

        if (options.withToolbarBlocks) {
            addedToolbar += ' blocks';
        }

        if (options.withToolbarFontsize) {
            addedToolbar += ' fontsize';
        }

        if (addedToolbar) {
            (editorOptions.toolbar as string) += addedToolbar;
        }

        switch (type) {
            case 'simple':
                editorOptions.max_height = 550;
                editorOptions.menubar = false;
                editorOptions.min_height = 400;
                addedPlugins.push(TinymceService.plugins.AUTO_RESIZE);

                break;
        }

        return this.addPlugins(editorOptions, addedPlugins);
    }

    improvePageBreak(eventName: string, editor: Editor): void {
        if (!editor.plugins[TinymceService.plugins.PAGE_BREAK]) {
            return;
        }

        const pagebreakElements = editor.dom.select<HTMLImageElement>('img.mce-pagebreak');

        pagebreakElements.forEach(pagebreakElement => {
            if (TinymceService.hasStyleFloat(pagebreakElement)) {
                editor.undoManager.undo();
                if (eventName !== TinymceService.events.INIT) {
                    this._toasterService.warning('Impossible d\'insérer un saut de page ici', 'Pour plus d\'informations, contactez le support technique.');
                }
            } else if (pagebreakElement.parentElement) {
                pagebreakElement.parentElement.classList.add('parent-soq-pagebreak');

                if (pagebreakElement.x > 0) {
                    pagebreakElement.parentElement.style.margin = '0 ' + (-(pagebreakElement.x + 1)).toString() + 'px';
                }
            }
        });
    }

    setNonEditableContent(editor: Editor, event: ITinymceEvent, value: string | null): void {
        if (!['mceToggleFormat', 'mceApplyTextcolor', 'mceRemoveTextcolor'].includes(event.command)) {
            return;
        }

        editor.getBody().querySelectorAll('.' + (editor.getParam('noneditable_class', 'mceNonEditable')!))
            .forEach(element => element.setAttribute('contenteditable', value!));
    }
}
