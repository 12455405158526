import {Component, OnDestroy, OnInit} from '@angular/core';
import {IDynamicComponent, IDynamicComponentData} from '@shared/shared.interfaces';
import {combineLatest, Observable, of, ReplaySubject, Subject, switchMap} from 'rxjs';
import CContacts from '@models/contacts/collection/contacts.collection.model';
import {catchError, filter, map, startWith, take, takeUntil, tap} from 'rxjs/operators';
import {ModalService} from '@shared/modal/modal.service';
import Contact from '@models/contacts/contact/contact.model';
import {EtudeService} from '@models/etudes/etude/etude.service';
import Etude from '@models/etudes/etude/etude.model';
import ContactsGroup from '@models/contacts-group/contacts-group.model';
import ContactsGroupMember from '@models/contacts-group/members/member/contacts-group-member.model';
import {ContactsGroupMemberService} from '@models/contacts-group/members/member/contacts-group-member.service';
import {AppNoteoContactComponent} from '@shared/noteo/contact/noteo-contact.component';
import {CEtudeDossiersFactory} from '@models/etude-dossiers/collection/etude-dossiers.collection.factory';
import CEtudeDossiers from '@models/etude-dossiers/collection/etude-dossiers.collection.model';
import EtudeDossier from '@models/etude-dossiers/etude-dossier/etude-dossier.model';
import {HttpErrorResponse} from '@angular/common/http';
import {CContactsFactory} from '@models/contacts/collection/contacts.collection.factory';

@Component({templateUrl: 'dossier-membres-select.component.html'})
export class DCDossierMembresSelectComponent implements IDynamicComponent, OnDestroy, OnInit {
    static readonly actions = {CREATE: 'create', IMPORT_FAILED: 'import-failed'};
    readonly sources = Contact.sources;
    private _cContactsFactory: CContactsFactory;
    private _contactsGroupMemberService: ContactsGroupMemberService;
    private _cEtudeDossiersFactory: CEtudeDossiersFactory;
    private _etudeService: EtudeService;
    private _modalService: ModalService;
    private _cContactsInotSource = new ReplaySubject<CContacts>(1);
    private _cContactsInot$ = this._cContactsInotSource.asObservable();
    private _cContactsNotySource = new ReplaySubject<CContacts>(1);
    private _cContactsNoty$ = this._cContactsNotySource.asObservable();
    private _cEtudeDossiersInotSource = new ReplaySubject<CEtudeDossiers>(1);
    private _cEtudeDossiersInot$ = this._cEtudeDossiersInotSource.asObservable();
    private _enableImportContactInot = true;
    private _errorCContactsInot!: HttpErrorResponse;
    private _errorCEtudeDossiersInot!: HttpErrorResponse;
    private _contacts!: Contact[];
    private _contactsGroup!: ContactsGroup;
    private _displaySearchLoader = false;
    private _launchCContactsInotSource = new Subject<void>();
    private _launchCEtudeDossiersInotSource = new Subject<void>();
    private readonly _onDestroy$ = new Subject<void>();
    private _searchText!: string;
    private _searchSource = new ReplaySubject<string>(1);
    private _search$ = this._searchSource.asObservable();

    constructor(cContactsFactory: CContactsFactory,
                contactsGroupMemberService: ContactsGroupMemberService,
                cEtudeDossiersFactory: CEtudeDossiersFactory,
                etudeService: EtudeService,
                modalService: ModalService) {
        this._cContactsFactory = cContactsFactory;
        this._contactsGroupMemberService = contactsGroupMemberService;
        this._cEtudeDossiersFactory = cEtudeDossiersFactory;
        this._etudeService = etudeService;
        this._modalService = modalService;
    }

    get enableImportContactInot(): boolean {
        return this._enableImportContactInot;
    }

    get cContactsInot$(): Observable<CContacts> {
        return this._cContactsInot$;
    }

    get cContactsNoty$(): Observable<CContacts> {
        return this._cContactsNoty$;
    }

    get contacts(): Contact[] {
        return this._contacts;
    }

    get cEtudeDossiersInot$(): Observable<CEtudeDossiers> {
        return this._cEtudeDossiersInot$;
    }

    set data(dynamicComponentData: IDynamicComponentData) {
        this._contactsGroup = dynamicComponentData.contactsGroup as ContactsGroup;
        this.setContacts(this._contactsGroup);
    }

    get displaySearchLoader(): boolean {
        return this._displaySearchLoader;
    }

    get errorCContactsInot(): HttpErrorResponse {
        return this._errorCContactsInot;
    }

    get errorCEtudeDossiersInot(): HttpErrorResponse {
        return this._errorCEtudeDossiersInot;
    }

    get etude$(): Observable<Etude> {
        return this._etudeService.last$;
    }

    get launchCContactsInotSource(): Subject<void> {
        return this._launchCContactsInotSource;
    }

    get launchCEtudeDossiersInotSource(): Subject<void> {
        return this._launchCEtudeDossiersInotSource;
    }

    get MODULE_INOT(): string {
        return AppNoteoContactComponent.modules.INOT;
    }

    get searchText(): string {
        return this._searchText;
    }

    ngOnInit(): void {
        const cContactsNoty$ = this._search$.pipe(
            tap(_ => this._cContactsNotySource.next(undefined!)),
            filter(search => !!search),
            switchMap(search => this._cContactsFactory.get$({
                keywords: search,
                limit: 3,
                source: Contact.sources.LOCAL,
            }).pipe(
                catchError(_ => of(this._cContactsFactory.createVirgin())),
                tap(cContacts => this._cContactsNotySource.next(cContacts)),
            )),
        );
        const cContactsInot$ = combineLatest([this._search$, this._launchCContactsInotSource.asObservable().pipe(startWith(undefined))]).pipe(
            tap(_ => this._cContactsInotSource.next(undefined!)),
            map(([search,]) => search),
            filter(search => !!search),
            switchMap(search => this.etude$.pipe(switchMap(etude => {
                this._errorCContactsInot = undefined!;
                if (etude.settingsGenapi.inotEnabled) {
                    return this._cContactsFactory.get$({
                        keywords: search,
                        limit: 3,
                        source: Contact.sources.INOT,
                    }).pipe(
                        catchError((error: HttpErrorResponse) => {
                            this._errorCContactsInot = error;

                            return of(this._cContactsFactory.createVirgin());
                        }),
                        tap(cContacts => this._cContactsInotSource.next(cContacts)),
                    );
                }

                return of(undefined);
            }))),
        );
        const cEtudeDossiersInot$ = combineLatest([this._search$, this._launchCEtudeDossiersInotSource.asObservable().pipe(startWith(undefined))]).pipe(
            tap(_ => this._cEtudeDossiersInotSource.next(undefined!)),
            map(([search,]) => search),
            filter(search => !!search),
            switchMap(search => this.etude$.pipe(switchMap(etude => {
                this._errorCEtudeDossiersInot = undefined!;
                if (etude.settingsGenapi.inotEnabled) {
                    return this._cEtudeDossiersFactory.get$({
                        keywords: search,
                        limit: 3,
                        source: EtudeDossier.sources.INOT,
                    }).pipe(
                        catchError((error: HttpErrorResponse) => {
                            this._errorCEtudeDossiersInot = error;

                            return of(this._cEtudeDossiersFactory.createVirgin());
                        }),
                        tap(cEtudeDossiers => this._cEtudeDossiersInotSource.next(cEtudeDossiers)),
                    );
                }

                return of(undefined);
            }))),
        );

        this._search$.pipe(
            filter(search => !!search),
            tap(_ => this._displaySearchLoader = true),
            switchMap(_ => combineLatest([cContactsNoty$, cContactsInot$, cEtudeDossiersInot$])),
            takeUntil(this._onDestroy$),
        ).subscribe(_ => this._displaySearchLoader = false);
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    addFromNoty(contact: Contact): void {
        this._contactsGroupMemberService.addFromContact$(this._contactsGroup, contact).pipe(take(1))
            .subscribe(_ => this.setContacts(this._contactsGroup));
    }

    contactCreation(): void {
        this._modalService.setResponse({
            action: DCDossierMembresSelectComponent.actions.CREATE,
            search: this.searchText,
        });
    }

    importFromInot$(contact: Contact): void {
        this._enableImportContactInot = false;
        this._contactsGroupMemberService.addFromContact$(this._contactsGroup, contact, {headers: {handledStatusErrors: [422]}}).pipe(
            catchError(_ => {
                this._modalService.setResponse({
                    action: DCDossierMembresSelectComponent.actions.IMPORT_FAILED,
                    contact,
                });

                return of(undefined as unknown as ContactsGroupMember);
            }),
            tap(_ => this.setContacts(this._contactsGroup)),
            take(1),
        ).subscribe(_ => this._enableImportContactInot = true);
    }

    searchContacts(search: string): void {
        this._searchSource.next(search);
        this._searchText = search;
    }

    setContacts(contactsGroup: ContactsGroup): void {
        this._contacts = contactsGroup.members.map(member => member.contact);
    }
}
