import {Component, Input} from '@angular/core';
import {IDynamicComponent, IDynamicComponentData} from '@shared/shared.interfaces';
import {DropdownService} from '@shared/dropdown/dropdown.service';

@Component({templateUrl: 'dossier-files.actions-selection-dropdown.component.html'})
export class DossierFilesActionsSelectionDropdownComponent implements IDynamicComponent {
    static readonly actions = {DELETE: 'dossier_files_delete', SHARE: 'dossier_files_share'};
    private _dropdownService: DropdownService;
    private _value!: IDynamicComponentData;

    constructor(dropdownService: DropdownService) {
        this._dropdownService = dropdownService;
    }

    // Inutile pour le moment mais obligatoire pour IDynamicComponent
    @Input()
    set data(value: IDynamicComponentData) {
        this._value = value;
    }

    delete(): void {
        this._dropdownService.clicked(DossierFilesActionsSelectionDropdownComponent.actions.DELETE);
    }
}
