import angularJS from '@shared/angularJS/global.ng';
import {filter as lFilter, find} from 'lodash';
import {firstValueFrom, of, ReplaySubject} from 'rxjs';
import {IModule, IPromise, IQService} from 'angular';
import {
    NgBienManager, NgDictionariesManager, NgLocationManager, NgLocationPasserelleManager, NgManager, NgTemplateManager,
    NgUserManager, NgUtilsManager
} from '@legacy/app/managers/managers';
import {
    NgBienPhotoPasserelle, NgFilter, NgLocation, NgLocationPasserelle
} from '@legacy/app/managers/ressources';
import {EtudeService} from '@models/etudes/etude/etude.service';
import Ng2Location from '@models/locations/location/location.model';
import User from '@models/users/user/user.model';
import {NgLocationPasserellesCollection} from '@legacy/app/managers/collections';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import Passerelle from '@models/passerelle/passerelle.model';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import DateFormat from '@shared/date/date.format';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import Bien from '@models/bien/bien.model';
import {ILinksApi} from '@models/links/links.interfaces';

export default function getManagersLocation(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('LocationManager', manager);

        /**
         * Manager location
         *
         * @param BaseManager
         * @param DictionariesManager
         * @param BienManager
         * @param UtilsManager
         * @param LocationPasserelleManager
         * @param Ng2EtudeService
         * @param $q
         * @param UserManager
         * @param TemplateManager
         * @param Ng2DictionaryItemService
         */
        manager.$inject = ['BaseManager', 'DictionariesManager', 'BienManager', 'UtilsManager', 'LocationPasserelleManager', 'Ng2EtudeService', '$q', 'UserManager', 'TemplateManager', 'Ng2DictionaryItemService'];
        function manager(baseManager: NgManager,
                         dictionariesManager: NgDictionariesManager,
                         bienManager: NgBienManager,
                         utilsManager: NgUtilsManager,
                         locationPasserelleManager: NgLocationPasserelleManager,
                         ng2EtudeService: EtudeService,
                         $q: IQService,
                         userManager: NgUserManager,
                         templateManager: NgTemplateManager,
                         ng2DictionaryItemService: DictionaryItemService) {
            let classResource = baseManager.getClass<NgLocation>();
            let currentSource = new ReplaySubject<NgLocation>(1);

            /**
             * Routing
             */
            baseManager.addRoute('locations.cget', {path: '/locations', method: 'GET'});
            baseManager.addRoute('locations.cget-etude', {path: '/etude/locations', method: 'GET'});
            baseManager.addRoute('locations.get', {path: '/locations/{id}', method: 'GET'});
            baseManager.addRoute('locations.insert', {path: '/user/locations', method: 'POST'});
            baseManager.addRoute('locations.edit', {path: '/locations/{id}', method: 'PUT'});
            baseManager.addRoute('locations.available', {path: '/locations/{id}/disponible', method: 'PATCH'});
            baseManager.addRoute('locations.diffuse', {
                path: '/locations/{id}/internal/networks/diffuser',
                method: 'PATCH'
            });
            baseManager.addRoute('locations.suspended', {path: '/locations/{id}/suspendre', method: 'PATCH'});
            baseManager.addRoute('locations.create-estimation', {path: '/locations/{id}/estimation', method: 'PATCH'});
            baseManager.addRoute('locations.create-vente', {path: '/locations/{id}/vente', method: 'PATCH'});
            baseManager.addRoute('locations.clone', {path: '/locations/{uuid}/clone', method: 'PATCH'});
            baseManager.addRoute('locations.archive', {path: '/locations/{id}/archiver', method: 'PATCH'});

            /**
             * LocationManager object
             *
             * @constructor
             */
            angular.extend(LocationManager.prototype, baseManager.__proto__);
            LocationManager.prototype.eskManager = {prefixRoute: 'locations', collectionName: 'items'};
            function LocationManager(this: NgLocationManager) {
                this.current$ = currentSource.asObservable();
                this.current$.subscribe(current => this.current = current);
                currentSource.next(undefined!);
            }

            /**
             * Create a Location object
             *
             * @param ng2Location
             * @returns manager.Location
             */
            LocationManager.prototype.createFromNg2 = function (ng2Location: Ng2Location) {
                if (!ng2Location) {
                    return $q.resolve(undefined!);
                }

                return bienManager.createFromNg2(DossierBienTypesConst.LOCATION, ng2Location.bien, ng2Location.reference).then(bien => this.create({
                    _links: {self: {href: ng2Location.linkSelf}},
                    bien,
                    chargesIncluses: ng2Location.chargesIncluses,
                    id: ng2Location.id,
                    loyer: ng2Location.loyer,
                    statut: ng2Location.statut,
                    uuid: ng2Location.uuid,
                }));
            };

            LocationManager.prototype.emitCurrent = function(ngLocation: NgLocation) {
                currentSource.next(ngLocation);
            }

            /**
             * Init current and return promise
             *
             * @param locationId
             * @param currentUser
             * @returns {Promise}
             */
            LocationManager.prototype.initCurrent = function (locationId: number, currentUser: User) {
                const that = this;
                let promise: IPromise<NgLocation>;

                if (locationId) {
                    promise = that.getOneById(locationId);
                } else {
                    promise = $q.resolve(that.create({
                        _links: {},
                        bien: {
                            notaire: userManager.createFromNg2(currentUser.defaultNotaire),
                            option: {risquesZone: true},
                            responsableDossier: userManager.createFromNg2(currentUser),
                        },
                        interne: true,
                    }));
                }

                return promise.then(location => {
                    bienManager.initCurrent(location.bien);
                    currentSource.next(location);
                });
            };

            /**
             * Returns the promise to fetch object
             *
             * @param locationId
             * @returns {Promise}
             */
            LocationManager.prototype.getOneById = function (locationId: number) {
                return this.get('get', {id: locationId}).then(function (location: NgLocation) {
                    const promises = [] as IPromise<unknown>[];

                    if (location.bien._links?.modifier?.href) {
                        promises.push(userManager.execLink(location.bien._links.modifier.href).then(modifier => {
                            location.bien.modifier = modifier;
                        }));
                    }

                    if (location.bien.interne && location.bien._links?.responsableDossier?.href) {
                        promises.push(userManager.execLink(location.bien._links.responsableDossier.href).then(responsableDossier => {
                            location.bien.responsableDossier = responsableDossier;
                        }));
                    }

                    return $q.all(promises).then(() => location);
                });
            };

            /**
             * Returns the promise to fetch collection of locations from etude
             *
             * @param params
             * @returns {Promise}
             */
            LocationManager.prototype.getAllEtude = function (params: unknown) {
                return this.get('cget-etude', params);
            };

            /**
             * Returns the promise to fetch collection of filtered locations from etude
             *
             * @param filter
             * @param params
             * @returns {Promise}
             */
            LocationManager.prototype.getAllEtudeFiltered = function (filter: NgFilter, params: unknown) {
                return this.getAllEtude(angular.extend(filter.getParamsRequest(), params));
            };

            /**
             * Update if location is modified
             *
             * @param oldLocation
             * @param location
             */
            LocationManager.prototype.updateIfModified = function (oldLocation: NgLocation, location: NgLocation) {
                const haveToModified = oldLocation.bien.nature.code !== location.bien.nature.code ||
                    (oldLocation.bien.option.nombrePieces !== location.bien.option.nombrePieces && location.bien.nature.code === Bien.natures.APPARTEMENT);

                if (!haveToModified) {
                    return $q.resolve();
                }

                return locationPasserelleManager.getAll(location.id).then(
                    locationPasserelles => locationPasserelles as NgLocationPasserellesCollection
                ).then(locationPasserelles => $q.all(locationPasserelles.collection.map(
                    locationPasserelle => locationPasserelleManager.getOneById(location.id, locationPasserelle.passerelle.id).then(
                        thisLocationPasserelle => thisLocationPasserelle as NgLocationPasserelle
                    ).then(thisLocationPasserelle => {
                        if (!thisLocationPasserelle.natureExternalType ||
                            oldLocation.bien.nature.code !== location.bien.nature.code ||
                            (oldLocation.bien.option.nombrePieces !== location.bien.option.nombrePieces && thisLocationPasserelle.passerelle.normalizer === Passerelle.normalizers.PRECOM && location.bien.nature.code === Bien.natures.APPARTEMENT)) {
                            thisLocationPasserelle.natureExternalType = dictionariesManager.createFromNg2(ng2DictionaryItemService.getNatureNormalizer(thisLocationPasserelle.passerelle.normalizer, location.bien._esk.type!, location.bien.nature.code, location.bien.option.nombrePieces));
                        }

                        return thisLocationPasserelle.save();
                    })))
                );
            }

            /**
             * Create a Location object
             *
             * @param data
             * @returns Location object || Array of manager.Location objects
             */
            LocationManager.prototype.create = function (data: unknown) {
                if (angular.isArray(data)) {
                    var locations = [];

                    for (var i = 0; i < data.length; i++) {
                        // @ts-ignore
                        locations.push(new Location(data[i]));
                    }

                    return locations;
                }

                // @ts-ignore
                return new Location(data);
            };

            /**
             * Location object
             *
             * @param data
             * @constructor
             */
            angular.extend(Location.prototype, classResource.prototype);
            Location.prototype.eskManager = {prefixRoute: 'locations'};
            function Location(this: NgLocation, data: unknown) {
                this._links = {} as ILinksApi;
                this.statut = Ng2Location.statuts.BROUILLON;
                this.extend(data);
                this._esk.defaultRouteParams = {id: this.id};
                this._esk.loyerVierge = getLoyerVierge(this);
            }

            /**
             * Extend the existing Location with new data
             *
             * @param data
             * @returns {Location}
             */
            Location.prototype.extend = function (data: unknown) {
                classResource.prototype.extend.call(this, data);

                this.bien.interne = this.interne;
                this._esk.typeDossier = DossierBienTypesConst.LOCATION;
                this.bien = bienManager.create(this._esk.typeDossier, this.bien);
                if (this.bien._esk) {
                    this.bien._esk.interne = this.bien.interne;
                }

                if (!angular.isObject(this.bien.metadata)) {
                    this.bien.metadata = {};
                }

                if (!angular.isObject(this.bien.metadata.occupation)) {
                    this.bien.metadata.occupation = {};
                }

                if (angular.isString(this.typeLoyer)) {
                    this.typeLoyer = dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.LOCATION_LOYER_TYPES, this.typeLoyer));
                } else {
                    this.typeLoyer = dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.LOCATION_LOYER_TYPES, Ng2Location.loyerTypes.HORS_TAXES));
                }

                if (!angular.isObject(this.periodiciteLoyer)) {
                    this.periodiciteLoyer = dictionariesManager.createFromNg2(ng2DictionaryItemService.getById(Dictionary.names.PERIODICITES, 1));
                }

                if (!angular.isObject(this.periodiciteCharges)) {
                    this.periodiciteCharges = dictionariesManager.createFromNg2(ng2DictionaryItemService.getById(Dictionary.names.PERIODICITES, 1));
                }

                this.charges = this.charges?.toString().replace('.', ',');
                this.depotGarantie = this.depotGarantie?.toString().replace('.', ',');
                this.droitBail = this.droitBail?.toString().replace('.', ',');
                this.etatLieuxChargeLocataire = this.etatLieuxChargeLocataire?.toString().replace('.', ',');
                this.etatLieuxChargeProprietaire = this.etatLieuxChargeProprietaire?.toString().replace('.', ',');
                this.honoNegoChargeProprietaire = this.honoNegoChargeProprietaire?.toString().replace('.', ',');
                this.honoRedacChargeLocataire = this.honoRedacChargeLocataire?.toString().replace('.', ',');
                this.honoRedacChargeProprietaire = this.honoRedacChargeProprietaire?.toString().replace('.', ',');
                this.loyer = this.loyer?.toString().replace('.', ',');
                this.loyerComplement = this.loyerComplement?.toString().replace('.', ',');
                this.loyerReferenceMajore = this.loyerReferenceMajore?.toString().replace('.', ',');
                this.montantTotalDu = this.montantTotalDu?.toString().replace('.', ',');
                this.montantVersementLocataire = this.montantVersementLocataire?.toString().replace('.', ',');

                return this;
            };

            /**
             * Save Resource
             *
             * @returns {Promise}
             */
            Location.prototype.save = function () {
                const self = this;
                var selfBien = angular.copy(self.bien);

                return classResource.prototype.save.call(self).then(function () {
                    return self.bien.save(selfBien).then(function () {
                        if (self._esk.setTitreDescriptif.need) {
                            return templateManager.setBienTitreDescriptif(self).then(() => self);
                        }

                        return self;
                    });
                }).then(_ => firstValueFrom(currentSource.asObservable())).then(currentLocation => {
                    if (self.id === currentLocation.id) {
                        currentSource.next(self);
                    }

                    return self;
                });
            };

            /**
             * Sanitize object Location before send to API
             *
             * @returns {Object}
             */
            Location.prototype.sanitize = function () {
                const sanitizeObject = classResource.prototype.sanitize.call(this);

                delete sanitizeObject.passerelles;
                sanitizeObject.bien = this.bien.sanitize();
                utilsManager.flattenIdForAttribute(sanitizeObject, 'periodiciteLoyer');
                utilsManager.flattenIdForAttribute(sanitizeObject, 'periodiciteCharges');
                utilsManager.flattenAttributeWithCode(sanitizeObject, 'typeLoyer');

                if (!!this.charges) {
                    sanitizeObject.charges = +(this.charges.toString().replace(',', '.'));
                } else {
                    sanitizeObject.charges = null!;
                }

                if (!!this.depotGarantie) {
                    sanitizeObject.depotGarantie = +(this.depotGarantie.toString().replace(',', '.'));
                } else {
                    sanitizeObject.depotGarantie = null!;
                }

                if (!!this.droitBail) {
                    sanitizeObject.droitBail = +(this.droitBail.toString().replace(',', '.'));
                } else {
                    sanitizeObject.droitBail = null!;
                }

                if (!!this.etatLieuxChargeLocataire) {
                    sanitizeObject.etatLieuxChargeLocataire = +(this.etatLieuxChargeLocataire.toString().replace(',', '.'));
                } else {
                    sanitizeObject.etatLieuxChargeLocataire = null!;
                }

                if (!!this.etatLieuxChargeProprietaire) {
                    sanitizeObject.etatLieuxChargeProprietaire = +(this.etatLieuxChargeProprietaire.toString().replace(',', '.'));
                } else {
                    sanitizeObject.etatLieuxChargeProprietaire = null!;
                }

                if (!!this.honoNegoChargeProprietaire) {
                    sanitizeObject.honoNegoChargeProprietaire = +(this.honoNegoChargeProprietaire.toString().replace(',', '.'));
                } else {
                    sanitizeObject.honoNegoChargeProprietaire = null!;
                }

                if (!!this.honoRedacChargeLocataire) {
                    sanitizeObject.honoRedacChargeLocataire = +(this.honoRedacChargeLocataire.toString().replace(',', '.'));
                } else {
                    sanitizeObject.honoRedacChargeLocataire = null!;
                }

                if (!!this.honoRedacChargeProprietaire) {
                    sanitizeObject.honoRedacChargeProprietaire = +(this.honoRedacChargeProprietaire.toString().replace(',', '.'));
                } else {
                    sanitizeObject.honoRedacChargeProprietaire = null!;
                }

                if (!!this.loyer) {
                    sanitizeObject.loyer = +(this.loyer.toString().replace(',', '.'));
                } else {
                    sanitizeObject.loyer = null!;
                }

                if (!!this.loyerBase) {
                    sanitizeObject.loyerBase = +(this.loyerBase.toString().replace(',', '.'));
                } else {
                    sanitizeObject.loyerBase = null!;
                }

                if (!!this.loyerComplement) {
                    sanitizeObject.loyerComplement = +(this.loyerComplement.toString().replace(',', '.'));
                } else {
                    sanitizeObject.loyerComplement = null!;
                }

                if (!!this.loyerReferenceMajore) {
                    sanitizeObject.loyerReferenceMajore = +(this.loyerReferenceMajore.toString().replace(',', '.'));
                } else {
                    sanitizeObject.loyerReferenceMajore = null!;
                }

                if (!!this.montantTotalDu) {
                    sanitizeObject.montantTotalDu = +(this.montantTotalDu.toString().replace(',', '.'));
                } else {
                    sanitizeObject.montantTotalDu = null!;
                }

                if (!!this.montantVersementLocataire) {
                    sanitizeObject.montantVersementLocataire = +(this.montantVersementLocataire.toString().replace(',', '.'));
                } else {
                    sanitizeObject.montantVersementLocataire = null!;
                }

                if (!this.loyerEncadrement) {
                    delete sanitizeObject.loyerBase;
                    delete sanitizeObject.loyerComplement;
                    delete sanitizeObject.loyerReferenceMajore;
                }

                return sanitizeObject;
            };

            /**
             * Operate loyerAffiche
             */
            Location.prototype.operateLoyer = function () {
                const sanitizedLocation = this.sanitize();
                const loyer = angular.isNumber(sanitizedLocation.loyer) && isFinite(sanitizedLocation.loyer) ? sanitizedLocation.loyer : 0;

                this.loyerAffiche = +loyer.toFixed(2);
                this._esk.loyerVierge = getLoyerVierge(this);
                this.operateMontantVersementLocataire();
            };

            /**
             * Operate montantTotalDu
             */
            Location.prototype.operateMontantTotalDu = function () {
                const sanitizedLocation = this.sanitize();
                const depotGarantie = angular.isNumber(sanitizedLocation.depotGarantie) && isFinite(sanitizedLocation.depotGarantie) ? sanitizedLocation.depotGarantie : 0;
                const etatLieuxChargeLocataire = angular.isNumber(sanitizedLocation.etatLieuxChargeLocataire) && isFinite(sanitizedLocation.etatLieuxChargeLocataire) ? sanitizedLocation.etatLieuxChargeLocataire : 0;
                const honoRedacChargeLocataire = angular.isNumber(sanitizedLocation.honoRedacChargeLocataire) && isFinite(sanitizedLocation.honoRedacChargeLocataire) ? sanitizedLocation.honoRedacChargeLocataire : 0;

                this.montantTotalDu = (+((honoRedacChargeLocataire + etatLieuxChargeLocataire + depotGarantie).toFixed(2).replace(',', '.'))).toString();
                this.operateMontantVersementLocataire();
            };

            /**
             * Operate montantVersementLocataire
             *
             * @returns {Object}
             */
            Location.prototype.operateMontantVersementLocataire = function () {
                const sanitizedLocation = this.sanitize();
                const charges = angular.isNumber(sanitizedLocation.charges) && isFinite(sanitizedLocation.charges) ? sanitizedLocation.charges : 0;
                const droitBail = angular.isNumber(sanitizedLocation.droitBail) && isFinite(sanitizedLocation.droitBail) ? sanitizedLocation.droitBail : 0;
                const loyerCharge = this._esk.loyerVierge + charges;
                const montantTotalDu = angular.isNumber(sanitizedLocation.montantTotalDu) && isFinite(sanitizedLocation.montantTotalDu) ? sanitizedLocation.montantTotalDu : 0;

                this.montantVersementLocataire = (+((loyerCharge + montantTotalDu + droitBail).toFixed(2).replace(',', '.'))).toString();
            };

            /**
             * Say if location can be available
             *
             * @return boolean
             */
            Location.prototype.canBeAvailable = function () {
                const sanitizedLocation = this.sanitize();

                return (angular.isNumber(sanitizedLocation.loyer)) && (sanitizedLocation.loyer > 0)
                    && (angular.isNumber(sanitizedLocation.bien.superficie) && sanitizedLocation.bien.superficie > 0)
                    && (angular.isObject(this.bien.option.commune))
                    && (angular.isString(this.bien.titre) && this.bien.titre !== '')
                    && (angular.isString(this.bien.descriptif) && this.bien.descriptif !== '');
            };

            /**
             * Make location available
             *
             * @return boolean
             */
            Location.prototype.available = function () {
                const self = this;

                // @todo https://gitlab.soqrate.com/soqrate/noty/noty/issues/221 : A supprimer
                return self.patch('available').then(function () {
                    self.statut = angular.isString(self.dateDiffusion) && self.dateDiffusion !== '' ? Ng2Location.statuts.DIFFUSE : Ng2Location.statuts.DISPONIBLE;

                    return self.getDiffusions().then(function () {
                        const promises: IPromise<void>[] = [];

                        angular.forEach(lFilter(self.passerelles, {passerelle: {type: 'website'}}), function (locationPasserelle) {
                            if (angular.isString(self.dateRetrait) && self.dateRetrait !== '') {
                                promises.push(locationPasserelle.diffuse());
                            }
                        });

                        angular.forEach(lFilter(self.passerelles, {
                            passerelle: {type: "social_network", instantDiffusion: false}
                        }), function (locationPasserelle) {
                            if (angular.isString(self.dateRetrait) && self.dateRetrait !== '') {
                                promises.push(locationPasserelle.diffuse());
                            }
                        });

                        return $q.all(promises);
                    });
                });
            };

            /**
             * Diffuse Location
             *
             * @returns {Promise}
             */
            Location.prototype.diffuse = function () {
                const self = this;

                return self.patch('diffuse').then(function () {
                    self.dateDiffusion = DateFormat.toDate();
                    self.statut = Ng2Location.statuts.DIFFUSE;
                });
            };

            /**
             * Suspend location
             *
             * @return boolean
             */
            Location.prototype.suspend = function () {
                const self = this;

                // @todo https://gitlab.soqrate.com/soqrate/noty/noty/issues/221 : A supprimer
                return self.getDiffusions().then(function () {
                    const promises: IPromise<void>[] = [];

                    angular.forEach(lFilter(self.passerelles, {passerelle: {type: 'website'}}), function (locationPasserelle) {
                        if (angular.isString(locationPasserelle.dateDiffusion) && locationPasserelle.dateDiffusion !== '') {
                            promises.push(locationPasserelle.suspend());
                        }
                    });

                    angular.forEach(lFilter(self.passerelles, {
                        passerelle: {type: "social_network", instantDiffusion: false}
                    }), function (locationPasserelle) {
                        if (angular.isString(locationPasserelle.dateDiffusion) && locationPasserelle.dateDiffusion !== '') {
                            promises.push(locationPasserelle.suspend());
                        }
                    });

                    return $q.all(promises).then(function () {
                        return self.patch('suspended').then(function () {
                            self.bien.metadata.occupation.dateDisponibilite = null;
                            self.statut = Ng2Location.statuts.SUSPENDU;
                        });
                    });
                });
            };

            /**
             * Get diffusions
             *
             * @returns {*}
             */
            Location.prototype.getDiffusions = function () {
                const self = this;

                return firstValueFrom(ng2EtudeService.last$).then(currentEtude => {
                    return locationPasserelleManager.getAll(self.id).then(function (locationPasserelles) {
                        const etudePasserelles = currentEtude.passerelles;

                        self.passerelles = locationPasserelles.collection;
                        angular.forEach(etudePasserelles, function (etudePasserelle) {
                            var locationPasserelle = find(self.passerelles, {passerelle: {id: etudePasserelle.passerelle.id}});
                            var natureNormalizer;

                            if (!angular.isObject(locationPasserelle)) {
                                natureNormalizer = dictionariesManager.createFromNg2(ng2DictionaryItemService.getNatureNormalizer(etudePasserelle.passerelle.normalizer, self.bien._esk.type, self.bien.nature.code, self.bien.option.nombrePieces));
                                locationPasserelle = locationPasserelleManager.create({
                                    passerelle: etudePasserelle.passerelle,
                                    natureExternalType: etudePasserelle.passerelle.normalizer,
                                    natureExternalTypeId: angular.isObject(natureNormalizer) ? natureNormalizer.id : null,
                                    photos: (function () {
                                        const photos: NgBienPhotoPasserelle[] = [];

                                        angular.forEach(self.bien.photos, function (bienPhoto) {
                                            photos.push({
                                                _links: bienPhoto._links,
                                                bienId: self.id,
                                                photoId: bienPhoto.photo.id,
                                                diffused: true,
                                                photo: bienPhoto.photo,
                                            } as NgBienPhotoPasserelle);
                                        });

                                        return photos;
                                    })(),
                                }, self.id);
                                locationPasserelle.id = false;
                                self.passerelles.push(locationPasserelle);
                            }
                        });

                        return self.passerelles;
                    });
                });
            };

            /**
             * Archive Location
             *
             * @param archive
             * @returns {Promise}
             */
            Location.prototype.archive = function (archive: unknown) {
                const self = this;

                return self.saveArchiveInfos(archive).then(function () {
                    return self.patch('archive');
                });
            };

            /**
             * Create Estimation from Location
             *
             * @returns {Promise}
             */
            Location.prototype.createEstimation = function () {
                return this.patch('create-estimation');
            };

            /**
             * Create Vente from Location
             *
             * @returns {Promise}
             */
            Location.prototype.createVente = function () {
                return this.patch('create-vente');
            };

            /**
             * Get information for archive
             *
             * @returns {Observable}
             */
            Location.prototype.getArchiveInfos = function () {
                var archive = {};

                // @ts-ignore
                archive.comments = this.archiveComments;

                return of(archive);
            };

            /**
             * Save archive information
             *
             * @param archive
             * @returns {Promise}
             */
            Location.prototype.saveArchiveInfos = function (archive: { comments: string }) {
                this.archiveComments = archive.comments;

                return this.save();
            };

            /**
             * Clone location
             *
             * @param keepOwners
             * @returns {Promise}
             */
            Location.prototype.clone = function (keepOwners: boolean): IPromise<NgLocation> {
                return this.patch('clone', {keepProprietaires: Boolean(keepOwners) === true}, {uuid: this.uuid});
            };

            /**
             * Get loyer vierge
             *
             * @param location
             * @returns {number}
             */
            function getLoyerVierge(location: NgLocation) {
                const sanitizedLocation = location.sanitize() as NgLocation;
                const loyer = angular.isNumber(sanitizedLocation.loyer) && isFinite(sanitizedLocation.loyer) ? sanitizedLocation.loyer : 0;
                const charges = angular.isNumber(sanitizedLocation.charges) && isFinite(sanitizedLocation.charges) ? sanitizedLocation.charges : 0;

                return +((loyer - (sanitizedLocation.chargesIncluses ? charges : 0)).toFixed(2));
            }

            // @ts-ignore
            return new LocationManager();
        }
    })(angularJS);
}
