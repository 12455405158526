import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {IEstimationApi} from '@models/estimations/estimation/estimation.interfaces';

@Injectable({providedIn: 'root'})
export class EstimationsApiService {
    private _modelApiService = inject(ModelApiService);

    getByLink$(link: string): Observable<IEstimationApi> {
        return this._modelApiService.get$(link);
    }

    remettreRapport$(uuid: string): Observable<void> {
        return this._modelApiService.patch$('/estimations/' +  uuid + '/remettre');
    }
}
