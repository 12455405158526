import {Component} from '@angular/core';
import {IDynamicComponent} from '@shared/shared.interfaces';
import {ModalService} from '@shared/modal/modal.service';
import {IEstimationRapportErrorCityscanLocalizationData} from '@features/estimations/estimations.interfaces';
import Estimation from '@models/estimations/estimation/estimation.model';

@Component({templateUrl: 'estimation-rapport.error-cityscan-localization.component.html'})
export class DCEstimationRapportErrorCityscanLocalizationComponent implements IDynamicComponent {
    private _modalService: ModalService;
    private _estimation!: Estimation;

    constructor(modalService: ModalService) {
        this._modalService = modalService;
    }

    set data(value: IEstimationRapportErrorCityscanLocalizationData) {
        this._estimation = value.estimation;
    }

    get estimation(): Estimation {
        return this._estimation;
    }

    get STATUS_WARNING(): string {
        return ModalService.status.WARNING;
    }

    redirection(redirect: boolean): void {
        this._modalService.setResponse(redirect);
    }
}
