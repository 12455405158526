import {Component} from '@angular/core';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {Observable} from 'rxjs';
import {IDynamicComponent, IDynamicComponentData} from '@shared/shared.interfaces';

@Component({templateUrl: 'dossier-inot-export.actions-main.component.html'})
export class DossierInotExportActionsMainComponent implements IDynamicComponent {
    static readonly actions = {SEND: 'send'};
    private _callToActionService: CallToActionService;
    private _value!: IDynamicComponentData;

    constructor(callToActionService: CallToActionService) {
        this._callToActionService = callToActionService;
    }

    get pending$(): Observable<boolean> {
        return this._callToActionService.pending$;
    }

    // Inutile pour le moment mais obligatoire pour IDynamicComponent
    set data(value: IDynamicComponentData) {
        this._value = value;
    }

    send(): void {
        this._callToActionService.clicked(DossierInotExportActionsMainComponent.actions.SEND);
    }
}
