import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IImagePickerOptions} from '@shared/image/image.interfaces';
import ImageService from '@shared/image/image.service';
import {take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {IMG_MAX_SIZE} from '@shared/image/image.constants';

@Component({selector: 'app-image-picker', templateUrl: 'image-picker.component.html'})
export class AppImagePickerComponent {
    @Output() selected = new EventEmitter<File>();

    static readonly initImagePickerOptions: IImagePickerOptions = {
        allowedTypes: [
            ImageService.acceptedMimeTypes.JPEG,
            ImageService.acceptedMimeTypes.JPG,
            ImageService.acceptedMimeTypes.PNG,
        ].join(','),
        label: 'Sélectionner une image',
        labelImageType: 'image',
        maxSize: IMG_MAX_SIZE,
        messageWithValidation: false,
        minHeight: 300,
        minWidth: 300,
    };
    private _imageService: ImageService;
    private _options: IImagePickerOptions = {...AppImagePickerComponent.initImagePickerOptions};
    private _openFilePicker$!: Observable<void>;

    constructor(imageService: ImageService) {
        this._imageService = imageService;
    }

    get openFilePicker$(): Observable<void> {
        return this._openFilePicker$;
    }

    @Input()
    set openFilePicker$(value: Observable<void>) {
        this._openFilePicker$ = value;
    }

    get options(): IImagePickerOptions {
        return this._options;
    }

    @Input()
    set options(value: IImagePickerOptions) {
        this._options = {...AppImagePickerComponent.initImagePickerOptions, ...value};
    }

    onSelect(file: File): void {
        this._imageService.isAccepted$(file, this._options).pipe(take(1)).subscribe(isAccepted => {
            if (isAccepted) {
                this.selected.next(file);
            }
        });
    }
}
