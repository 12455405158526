import {Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {BehaviorSubject, delay, Observable, ReplaySubject, Subject, switchMap} from 'rxjs';
import Bonvisite from '@models/bonvisites/bonvisite/bonvisite.model';
import Demandeur from '@models/demandeurs/demandeur/demandeur.model';
import {DemandeurFactory} from '@models/demandeurs/demandeur/demandeur.factory';
import {map, take, takeUntil, tap} from 'rxjs/operators';
import Person from '@models/contacts/person/person.model';
import {NgForm} from '@angular/forms';
import {DemandeurService} from '@models/demandeurs/demandeur/demandeur.service';
import {IBonvisiteEditForms, IBonvisiteEditOptions} from '@features/bonvisites/bonvisites.interfaces';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {IFormButtonInput} from '@shared/form/form.interfaces';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {UserService} from '@models/users/user/user.service';
import User from '@models/users/user/user.model';
import CADossierBiens from '@models/dossiers/biens/collection/dossier-biens.collection.model.abstract';
import {CDossierBiensService} from '@models/dossiers/biens/collection/dossier-biens.collection.service';
import {DossierBienSource, DossierBienType} from '@models/dossiers/biens/dossier-biens.constants';
import Filter from '@models/filters/filter/filter.model';
import Location from '@models/locations/location/location.model';
import Vente from '@models/ventes/vente/vente.model';
import {BonvisiteService} from '@models/bonvisites/bonvisite/bonvisite.service';
import {ConditionConst} from '@shared/constants';
import {DossierBiensSourcesConst, DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import {ICADossierBiensQueryParameters} from '@models/dossiers/biens/collection/dossier-biens.interfaces';

@Component({selector: 'app-bonvisite-edit', templateUrl: 'bonvisite.edit.component.html'})
export class AppBonvisiteEditComponent implements OnDestroy, OnInit {
    @Output() demandeur = new ReplaySubject<Demandeur>(1);
    @Output() forms = new EventEmitter<IBonvisiteEditForms>();
    @Output() locations = new ReplaySubject<Location[]>(1);
    @Output() ventes = new ReplaySubject<Vente[]>(1);

    static readonly initBonvisiteEditOptions: IBonvisiteEditOptions = {
        fixedDossierBiens: false,
        fixedDemandeur: false,
    };
    static readonly initDossierBiensSources: IFormButtonInput[] = [
        {
            code: DossierBiensSourcesConst.BIENS_CORRESPONDANTS,
            disabled: false,
            label: 'Biens correspondants',
        },
        {
            code: DossierBiensSourcesConst.WALLET,
            disabled: false,
            label: 'Mon portefeuille',
        },
        {
            code: DossierBiensSourcesConst.NETWORK,
            disabled: false,
            label: 'Tout le réseau',
        },
    ];
    static readonly initDossierBiensTypes: IFormButtonInput[] = [
        {
            code: DossierBienTypesConst.VENTE,
            disabled: false,
            label: 'Ventes',
        },
        {
            code: DossierBienTypesConst.LOCATION,
            disabled: false,
            label: 'Locations',
        },
    ];
    private _bonvisiteService = inject(BonvisiteService);
    private _cDossierBiensService = inject(CDossierBiensService);
    private _demandeurFactory = inject(DemandeurFactory);
    private _demandeurService = inject(DemandeurService);
    private _etudeService = inject(EtudeService);
    private _userService = inject(UserService);
    private _bonvisite!: Bonvisite;
    private _demandeurCreation = false;
    private _demandeur$ = this.demandeur.asObservable();
    private _dossierBiensKeywords!: string;
    private _dossierBiensSearch = false;
    private _dossierBiensSource = new BehaviorSubject<ADossierBien[]>(undefined!);
    private _dossierBiensSources = [...AppBonvisiteEditComponent.initDossierBiensSources];
    private _dossierBiensSourcesCode = DossierBiensSourcesConst.WALLET;
    private _dossierBiensTypes = [...AppBonvisiteEditComponent.initDossierBiensTypes];
    private _dossierBiensTypesCode!: DossierBienType;
    private _dossierBiens$ = this._dossierBiensSource.asObservable();
    private readonly _endGetDossierBien$ = new Subject<void>();
    private _filteredDossierBiensSource = new BehaviorSubject<CADossierBiens>(undefined!);
    private _filteredDossierBiens$ = this._filteredDossierBiensSource.asObservable();
    private _forms = {} as IBonvisiteEditForms;
    private readonly _onDestroy$ = new Subject<void>();
    private _options: IBonvisiteEditOptions = {...AppBonvisiteEditComponent.initBonvisiteEditOptions};

    get bonvisite(): Bonvisite {
        return this._bonvisite;
    }

    @Input()
    set bonvisite(value: Bonvisite) {
        this._bonvisite = value;
        this._bonvisiteService.getDossierBiens$(this._bonvisite).pipe(take(1)).subscribe(dossierBiens => this._dossierBiensSource.next(dossierBiens));
        if (this._bonvisite.linkDemandeur) {
            this._demandeurFactory.getByLink$(this._bonvisite.linkDemandeur).pipe(
                tap(_ => this._dossierBiensSourcesCode = DossierBiensSourcesConst.BIENS_CORRESPONDANTS),
                take(1),
            ).subscribe(demandeur => this.demandeur.next(demandeur));
        } else {
            this.demandeur.next(undefined!);
        }
    }

    get currentUser$(): Observable<User> {
        return this._userService.last$;
    }

    get demandeur$(): Observable<Demandeur> {
        return this._demandeur$;
    }

    get demandeurCreation(): boolean {
        return this._demandeurCreation;
    }

    set demandeurCreation(value: boolean) {
        this._demandeurCreation = value;
    }

    @ViewChild('demandeurCreationForm')
    set demandeurCreationForm(value: NgForm) {
        this._forms.demandeurCreation = value;
        this.forms.emit(this._forms);
    }

    get dossierBiens$(): Observable<ADossierBien[]> {
        return this._dossierBiens$;
    }

    get dossierBiensKeywords(): string {
        return this._dossierBiensKeywords;
    }

    get dossierBiensSearch(): boolean {
        return this._dossierBiensSearch;
    }

    set dossierBiensSearch(value: boolean) {
        this._dossierBiensSearch = value;
    }

    get dossierBiensSources(): IFormButtonInput[] {
        return this._dossierBiensSources;
    }

    get dossierBiensSourcesCode(): DossierBienSource {
        return this._dossierBiensSourcesCode;
    }

    get dossierBiensTypes(): IFormButtonInput[] {
        return this._dossierBiensTypes;
    }

    get dossierBiensTypesCode(): DossierBienType {
        return this._dossierBiensTypesCode;
    }

    get filteredDossierBiens$(): Observable<CADossierBiens> {
        return this._filteredDossierBiens$;
    }

    get options(): IBonvisiteEditOptions {
        return this._options;
    }

    @Input()
    set options(value: IBonvisiteEditOptions) {
        this._options = {...AppBonvisiteEditComponent.initBonvisiteEditOptions, ...value};
    }

    @ViewChild('visiteForm')
    set visiteForm(value: NgForm) {
        this._forms.visite = value;
        this.forms.emit(this._forms);
    }

    ngOnInit(): void {
        this.demandeur$.pipe(
            map(demandeur => !demandeur || demandeur.isBrouillon()),
            map(disabled => this._dossierBiensSources
                .filter(dossierBiensSource => dossierBiensSource.code === DossierBiensSourcesConst.BIENS_CORRESPONDANTS)
                .map(dossierBiensSource => {
                    dossierBiensSource.disabled = disabled;

                    return dossierBiensSource;
                }),
            ),
            map(dossierBiensSources => dossierBiensSources[0].disabled),
            takeUntil(this._onDestroy$),
        ).subscribe(dossierBiensSourceBiensCorrespondantsDisabled => {
            if (dossierBiensSourceBiensCorrespondantsDisabled && this._dossierBiensSourcesCode === DossierBiensSourcesConst.BIENS_CORRESPONDANTS) {
                this._dossierBiensSourcesCode = DossierBiensSourcesConst.WALLET;
                this.getDossierBiens();
            }
        });
        this.dossierBiens$.pipe(
            map(dossierBiens => ({
                locations: (dossierBiens || []).filter(dossierBien => dossierBien.isLocation()) as Location[],
                ventes: (dossierBiens || []).filter(dossierBien => dossierBien.isVente()) as Vente[],
            })),
            takeUntil(this._onDestroy$),
        ).subscribe(({locations, ventes}) => {
            this.locations.next(locations);
            this.ventes.next(ventes);
        });
        this._etudeService.last$.pipe(map(currentEtude => currentEtude.hasNetwork()), take(1)).subscribe(hasNetwork => {
            if (!hasNetwork) {
                this._dossierBiensSources = this._dossierBiensSources.filter(dossierBiensSource => dossierBiensSource.code !== DossierBiensSourcesConst.NETWORK);
            }
        });
        this._userService.last$.subscribe(user => {
            this._dossierBiensTypes[0].disabled = !user.hasRoleGrpVente();
            this._dossierBiensTypes[1].disabled = !user.hasRoleGrpLocation();
            this._dossierBiensTypesCode = (!this._dossierBiensTypes[0].disabled ? this._dossierBiensTypes[0].code : this._dossierBiensTypes[1].code) as DossierBienType;
        });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    addDossierBien(dossierBien: ADossierBien): void {
        this.dossierBiens$.pipe(
            take(1),
            tap(dossierBiens => dossierBiens.push(dossierBien)),
            tap(dossierBiens => this._dossierBiensSource.next(dossierBiens)),
        ).subscribe(_ => this.getDossierBiens());
    }

    createDemandeur(person: Person): void {
        this._demandeurService.createFromContact$(person)
            .pipe(delay(1), take(1))
            .subscribe(demandeur => this.demandeur.next(demandeur));
    }

    displayDossierBiensSearch(): void {
        this.dossierBiensSearch = true;
        this.getDossierBiens();
    }

    getDossierBiens(): void {
        let cADossierBiens$: Observable<CADossierBiens>;

        this._filteredDossierBiensSource.next(undefined!);
        if (!this.dossierBiensSearch) {
            return;
        }

        this._endGetDossierBien$.next();
        if (this._dossierBiensSourcesCode === DossierBiensSourcesConst.BIENS_CORRESPONDANTS) {
            cADossierBiens$ = this.demandeur$.pipe(
                switchMap(demandeur => this._demandeurService.getCResultsByFilter$(demandeur, Filter.codes.WALLET_CURRENT, this._dossierBiensKeywords)),
                switchMap(cDemandeurRechercheResultats => this._cDossierBiensService.getFromDemandeurRechercheResultats$(cDemandeurRechercheResultats)),
            );
        } else {
            cADossierBiens$ = this._cDossierBiensService.getFiltered$(this._dossierBiensTypesCode, this._dossierBiensSourcesCode, {
                keywords: this._dossierBiensKeywords,
                limit: 20,
                type: {condition: ConditionConst.IN, values: [Vente.types.SIMPLE, Vente.types.VIAGER]},
                statut: {
                    condition: ConditionConst.IN,
                    values: [
                        ADossierBien.abstractBienStatuts.DIFFUSE,
                        ADossierBien.abstractBienStatuts.DISPONIBLE,
                        ADossierBien.abstractBienStatuts.MANDAT,
                    ],
                },
            } as ICADossierBiensQueryParameters);
        }

        cADossierBiens$.pipe(
            switchMap(cADossierBiens => this.dossierBiens$.pipe(
                map(dossierBiens => cADossierBiens.results.filter(aDossierBien => dossierBiens.findIndex(dossierBien => dossierBien.uuid === aDossierBien.uuid) < 0)),
                tap(filteredDossierBiens => cADossierBiens.results = filteredDossierBiens),
                map(_ => cADossierBiens),
            )),
            tap(cDossierBiensFiltered => cDossierBiensFiltered.total = cDossierBiensFiltered.results.length),
            take(1),
            takeUntil(this._endGetDossierBien$),
        ).subscribe(cDossierBiensFiltered => this._filteredDossierBiensSource.next(cDossierBiensFiltered));
    }

    removeDossierBien(dossierBienToRemove: ADossierBien): void {
        this.dossierBiens$.pipe(
            take(1),
            map(dossierBiens => dossierBiens.filter(dossierBien => dossierBien.uuid !== dossierBienToRemove.uuid)),
            tap(dossierBiens => this._dossierBiensSource.next(dossierBiens)),
        ).subscribe(_ => this.getDossierBiens());
    }

    searchDossierBiens(search: string): void {
        this._dossierBiensKeywords = search;
        this.getDossierBiens();
    }

    selectDemandeur(demandeur: Demandeur): void {
        this.demandeur.next(demandeur);
    }

    selectDossierBiensSource(source: string): void {
        this._dossierBiensSourcesCode = source as DossierBienSource;
        this.getDossierBiens();
    }

    selectDossierBiensType(type: string): void {
        this._dossierBiensTypesCode = type as DossierBienType;
        this.getDossierBiens();
    }

    stopDemandeurCreation(): void {
        this.demandeurCreation = false;
        this.demandeur.next(undefined!);
    }
}
