import {Component, EventEmitter, Input, Output} from '@angular/core';
import Etude from '@models/etudes/etude/etude.model';
import {INoteoContactOptions} from '@shared/noteo/noteo.interfaces';
import {UserFactory} from '@models/users/user/user.factory';
import {take, tap} from 'rxjs/operators';
import {ToasterService} from '@shared/toaster/toaster.service';

@Component({selector: 'app-noteo-contact', templateUrl: 'noteo-contact.component.html'})
export class AppNoteoContactComponent {
    @Output() contacted = new EventEmitter<void>();
    static readonly initNoteoContactOptions: INoteoContactOptions = {withModuleInactif: true};
    static readonly modules = {
        CITYSCAN: Etude.enablableModules.CITYSCAN.code,
        INOT: Etude.enablableModules.INOT.code,
        NOTESTIM: Etude.enablableModules.NOTESTIM.code,
    };
    private _toasterService: ToasterService;
    private _userFactory: UserFactory;
    private _module!: string;
    private _labelModule!: string;
    private _options: INoteoContactOptions = {...AppNoteoContactComponent.initNoteoContactOptions};

    constructor(toasterService: ToasterService, userFactory: UserFactory) {
        this._toasterService = toasterService;
        this._userFactory = userFactory;
    }

    get labelModule(): string {
        return this._labelModule;
    }

    @Input({required: true})
    set module(value: string) {
        this._module = value;
        if (this._module === AppNoteoContactComponent.modules.CITYSCAN) {
            this._labelModule = Etude.enablableModules.CITYSCAN.label;
        } else if (this._module === AppNoteoContactComponent.modules.INOT) {
            this._labelModule = Etude.enablableModules.INOT.label;
        } else if (this._module === AppNoteoContactComponent.modules.NOTESTIM) {
            this._labelModule = Etude.enablableModules.NOTESTIM.label;
        } else {
            this._labelModule = undefined!;
        }
    }

    get options(): INoteoContactOptions {
        return this._options;
    }

    @Input()
    set options(value: INoteoContactOptions) {
        this._options = {...AppNoteoContactComponent.initNoteoContactOptions, ...value};
    }

    askToBeContacted(): void {
        this._userFactory.askInfosModule$(this._labelModule).pipe(tap(_ => this.contacted.emit()), take(1))
            .subscribe(() => this._toasterService.success('Demande d\'informations', 'Votre demande d\'informations sur le module "' + this._labelModule + '" est envoyée à Noteo.'));
    }
}
