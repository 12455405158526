import {Component} from '@angular/core';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {Observable} from 'rxjs';
import {IDynamicComponent, IDynamicComponentData} from '@shared/shared.interfaces';
import {Router} from '@angular/router';
import Offreachat from '@models/offreachats/offreachat/offreachat.model';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {
    OffreachatsActionsMainDropdownComponent
} from '@features/offreachats/list/actions/dropdown/offreachats.actions-main-dropdown.component';
import {DossierService} from '@models/dossiers/dossier/dossier.service';

@Component({selector: 'offreachats-actions-main', templateUrl: 'offreachats.actions-main.component.html'})
export class OffreachatsActionsMainComponent implements IDynamicComponent {
    private _callToActionService: CallToActionService;
    private _dossierService: DossierService;
    private _dropdownService: DropdownService;
    private _router: Router;
    private _value!: IDynamicComponent;

    constructor(callToActionService: CallToActionService,
                dossierService: DossierService,
                dropdownService: DropdownService,
                router: Router) {
        this._callToActionService = callToActionService;
        this._dossierService = dossierService;
        this._dropdownService = dropdownService;
        this._router = router;
    }

    get pending$(): Observable<boolean> {
        return this._callToActionService.pending$;
    }

    // Inutile pour le moment mais obligatoire pour IDynamicComponent
    set data(value: IDynamicComponentData) {
        this._value = value;
    }

    clickDropdown(htmlButtonElement: HTMLButtonElement): void {
        this._dropdownService.open(htmlButtonElement, {component: OffreachatsActionsMainDropdownComponent});
    }

    create(): void {
        const dossier = this._dossierService.getCurrentFromNg(this._router.url);

        this._router.navigateByUrl('/app/' + dossier.dossierType + 's/' + dossier.id.toString() + '/offres-achat/' + Offreachat.statuts.NEW + '/edit');
    }
}
