import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SortConst, SortDefinition} from '@shared/constants';

@Component({selector: 'app-collection-column-sort', templateUrl: 'collection-column.sort.component.html'})
export class CollectionColumnSortComponent {
    @Output() changed = new EventEmitter<SortDefinition>();

    private _isAscending = false;
    private _isDescending = false;

    get isAscending(): boolean {
        return this._isAscending;
    }

    get isDescending(): boolean {
        return this._isDescending;
    }

    @Input()
    set sort(value: SortDefinition) {
        this._isAscending = value === SortConst.ASCENDING;
        this._isDescending = value === SortConst.DESCENDING;
    }

    toggle(): void {
        const sort = this._isAscending ? SortConst.DESCENDING : (this.isDescending ? undefined! : SortConst.ASCENDING);

        this.sort = sort;
        this.changed.emit(sort);
    }
}
