import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subject, switchMap} from 'rxjs';
import {filter, map, take, takeUntil} from 'rxjs/operators';
import {IDropdownClicked} from '@shared/dropdown/dropdown.interfaces';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {
    LocationPasserelleDropdownComponent
} from '@features/locations/location/passerelles/passerelle/dropdown/location-passerelle.dropdown.component';
import LocationPasserelle from '@models/locations/location/passerelles/passerelle/location-passerelle.model';
import {
    CLocationPasserellesService
} from '@models/locations/location/passerelles/collection/location-passerelles.collection.service';
import CLocationPasserelles from '@models/locations/location/passerelles/collection/location-passerelles.collection.model';
import {CollectionSelectionService} from '@shared/collection/selection/collection-selection.service';
import {ICollectionSelectionListOptions} from '@shared/collection/selection/collection-selection.interfaces';

@Component({
    selector: 'app-location-passerelles-list',
    templateUrl: 'location-passerelles-list.component.html',
})
export class AppLocationPasserellesListComponent implements OnDestroy, OnInit {
    static readonly initLocationPasserellesListOptions: ICollectionSelectionListOptions = {
        initSelectAll: false,
        nameSelection: 'location-passerelles-list-selection',
    };
    @Output() clicked = new EventEmitter<IDropdownClicked>();
    @Output()locationPasserellesSelected = new EventEmitter<LocationPasserelle[]>();

    private _cLocationPasserellesService: CLocationPasserellesService;
    private _collectionSelectionService: CollectionSelectionService;
    private _dropdownService: DropdownService;
    private _cLocationPasserelles$!: Observable<CLocationPasserelles>;
    private readonly _onDestroy$ = new Subject<void>();
    private _pendingGetMore = false;

    private _options: ICollectionSelectionListOptions = {...AppLocationPasserellesListComponent.initLocationPasserellesListOptions};

    constructor(
                cLocationPasserellesService: CLocationPasserellesService,
                collectionSelectionService: CollectionSelectionService,
                dropdownService: DropdownService
    ) {
        this._cLocationPasserellesService = cLocationPasserellesService;
        this._collectionSelectionService = collectionSelectionService;
        this._dropdownService = dropdownService;
    }

    get cLocationPasserelles$(): Observable<CLocationPasserelles> {
        return this._cLocationPasserelles$;
    }

    @Input({required: true})
    set cLocationPasserelles$(value$: Observable<CLocationPasserelles>) {
        this._cLocationPasserelles$ = value$;
    }

    get options(): ICollectionSelectionListOptions {
        return this._options;
    }

    get pendingGetMore(): boolean {
        return this._pendingGetMore;
    }

    ngOnInit(): void {
        this._dropdownService.clicked$.pipe(takeUntil(this._onDestroy$)).subscribe(dropdownClicked => this.clicked.emit(dropdownClicked));

        this._collectionSelectionService.clear(this._options.nameSelection!);
        this._collectionSelectionService.isInit$(this._options.nameSelection!).pipe(
            filter(isInit => isInit),
            switchMap(_ => this._collectionSelectionService.getListSelected$(this._options.nameSelection!)),
            map(listSelected => listSelected as Set<LocationPasserelle>),
            map(listSelected => Array.from(listSelected)),
            takeUntil(this._onDestroy$),
        ).subscribe(listSelected => this.locationPasserellesSelected.emit(listSelected));
    }

    ngOnDestroy(): void {
        this._collectionSelectionService.delete(this._options.nameSelection!);
        this._onDestroy$.next();
    }

    clickOnLocationPasserelle(htmlButtonElement: HTMLButtonElement, locationPasserelle: LocationPasserelle): void {
        this._dropdownService.open(htmlButtonElement, {
            component: LocationPasserelleDropdownComponent,
            data: {locationPasserelle},
        });
    }

    getMore(cLocationPasserelles: CLocationPasserelles): void {
        this._pendingGetMore = true;
        this._cLocationPasserellesService.addNext$(cLocationPasserelles).pipe(take(1)).subscribe({
            complete: () => this._pendingGetMore = false,
        });
    }
}
