import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {
    NgBienCommoditeManager, NgBienLogementManager, NgBienPieceManager, NgDictionariesManager
} from '@legacy/app/managers/managers';
import {
    NgBienCollectionItem, NgBienCommodite, NgBienDossier, NgBienLogement, NgBienPiece
} from '@legacy/app/managers/ressources';
import {from, Subject} from 'rxjs';
import {filter, map, take, takeUntil, tap} from 'rxjs/operators';
import {BienEditVisibilityPanelService} from '@features/bien-edit/shared/bien-edit.visibility-panel.service';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import {ModalService} from '@shared/modal/modal.service';
import {DCBienEditPieceComponent} from '@features/bien-edit/dynamic-components/piece/bien.edit-piece.component';
import {
    DCBienEditLogementComponent
} from '@features/bien-edit/dynamic-components/logement/bien.edit-logement.component';
import {DictionaryItemFactory} from '@models/dictionaries/dictionary/items/item/dictionary-item.factory';
import {DictionarySelected} from '@features/dictionaries/dictionaries.interfaces';
import {
    DCBienEditSanitairesComponent
} from '@features/bien-edit/dynamic-components/sanitaires/bien.edit-sanitaires.component';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import {DossierBienFactory} from '@models/dossiers/biens/bien/dossier-bien.factory';
import {
    DCBienEditCommoditeComponent
} from '@features/bien-edit/dynamic-components/commodite/bien.edit-commodite.component';
import {DossierBienService} from '@models/dossiers/biens/bien/dossier-bien.service';
import {RoutesService} from '@shared/routes/routes.service';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {CollectionSortableService} from '@shared/collection/sortable/collection.sortable.service';
import {DossierBienFieldsService} from '@models/dossiers/biens/bien/dossier-bien.fields.service';
import {DossierBienFieldsConst} from '@models/dossiers/biens/dossier-biens.constants';

@Component({selector: 'layout-bien-edit-construction', templateUrl: 'layout-bien-edit-construction.component.html'})
export class AppLayoutBienEditConstructionComponent implements OnDestroy, OnInit {
    private _bienEditVisibilityPanelService = inject(BienEditVisibilityPanelService);
    private _collectionSortableService = inject(CollectionSortableService);
    private _dictionaryItemFactory = inject(DictionaryItemFactory);
    private _dictionaryItemService = inject(DictionaryItemService);
    private _dossierBienFactory = inject(DossierBienFactory);
    private _dossierBienFieldsService = inject(DossierBienFieldsService);
    private _dossierBienService = inject(DossierBienService);
    private _modalService = inject(ModalService);
    private _ngInjectorService = inject(NgInjectorService);
    private _routesService = inject(RoutesService);
    private _bienAssainissements!: DictionaryItem[];
    private _bienCharpentes!: DictionaryItem[];
    private _bienChauffages!: DictionaryItem[];
    private _bienClotures!: DictionaryItem[];
    private _bienConnexionsInternet!: DictionaryItem[];
    private _bienEpoqueConstruction!: DictionaryItem;
    private _bienEtat!: DictionaryItem;
    private _bienExposition!: DictionaryItem;
    private _bienHuisseries!: DictionaryItem[];
    private _bienIsolations!: DictionaryItem[];
    private _bienMateriauxConstruction!: DictionaryItem[];
    private _bienMateriauxCouverture!: DictionaryItem[];
    private _bienMitoyennete!: DictionaryItem;
    private _bienPlainPied!: DictionaryItem;
    private _bienVolets!: DictionaryItem[];
    private _bienVues!: DictionaryItem[];
    private _dossierBien!: ADossierBien;
    private _ngBienDossier!: NgBienDossier;
    private readonly _onDestroy$ = new Subject<void>();

    // Supprimer les injections des anciens manager
    private get ngBienCommoditeManager(): NgBienCommoditeManager {
        return this._ngInjectorService.getService('BienCommoditeManager');
    }

    // Supprimer les injections des anciens manager
    private get ngBienLogementManager(): NgBienLogementManager {
        return this._ngInjectorService.getService('BienLogementManager');
    }

    // Supprimer les injections des anciens manager
    private get ngBienPieceManager(): NgBienPieceManager {
        return this._ngInjectorService.getService('BienPieceManager');
    }

    // Supprimer les injections des anciens manager
    private get ngDictionariesManager(): NgDictionariesManager {
        return this._ngInjectorService.getService('DictionariesManager');
    }

    get bienAssainissements(): DictionaryItem[] {
        return this._bienAssainissements;
    }

    get bienCharpentes(): DictionaryItem[] {
        return this._bienCharpentes;
    }

    get bienChauffages(): DictionaryItem[] {
        return this._bienChauffages;
    }

    get bienClotures(): DictionaryItem[] {
        return this._bienClotures;
    }

    get bienConnexionsInternet(): DictionaryItem[] {
        return this._bienConnexionsInternet;
    }

    get bienEpoqueConstruction(): DictionaryItem {
        return this._bienEpoqueConstruction;
    }

    get bienEtat(): DictionaryItem {
        return this._bienEtat;
    }

    get bienExposition(): DictionaryItem {
        return this._bienExposition;
    }

    get bienHuisseries(): DictionaryItem[] {
        return this._bienHuisseries;
    }

    get bienIsolations(): DictionaryItem[] {
        return this._bienIsolations;
    }

    get bienMateriauxConstruction(): DictionaryItem[] {
        return this._bienMateriauxConstruction;
    }

    get bienMateriauxCouverture(): DictionaryItem[] {
        return this._bienMateriauxCouverture;
    }

    get bienMitoyennete(): DictionaryItem {
        return this._bienMitoyennete;
    }

    get bienPlainPied(): DictionaryItem {
        return this._bienPlainPied;
    }

    get bienVolets(): DictionaryItem[] {
        return this._bienVolets;
    }

    get bienVues(): DictionaryItem[] {
        return this._bienVues;
    }

    get DICTIONARY_NAME_ASSAISSEMENTS(): string {
        return Dictionary.names.ASSAISSEMENTS;
    }

    get DICTIONARY_NAME_CHARPENTES(): string {
        return Dictionary.names.CHARPENTES;
    }

    get DICTIONARY_NAME_CHAUFFAGES(): string {
        return Dictionary.names.CHAUFFAGES;
    }

    get DICTIONARY_NAME_CLOTURES(): string {
        return Dictionary.names.CLOTURES;
    }

    get DICTIONARY_NAME_COMMODITES(): string {
        return Dictionary.names.COMMODITES;
    }

    get DICTIONARY_NAME_CONSTRUCTION_EPOQUES(): string {
        return Dictionary.names.CONSTRUCTION_EPOQUES;
    }

    get DICTIONARY_NAME_CONSTRUCTION_MATERIAUX(): string {
        return Dictionary.names.CONSTRUCTION_MATERIAUX;
    }

    get DICTIONARY_NAME_COUVERTURE_MATERIAUX(): string {
        return Dictionary.names.COUVERTURE_MATERIAUX;
    }

    get DICTIONARY_NAME_ETAGES(): string {
        return Dictionary.names.ETAGES;
    }

    get DICTIONARY_NAME_ETATS(): string {
        return Dictionary.names.ETATS;
    }

    get DICTIONARY_NAME_EXPOSITIONS(): string {
        return Dictionary.names.EXPOSITIONS;
    }

    get DICTIONARY_NAME_HUISSERIES(): string {
        return Dictionary.names.HUISSERIES;
    }

    get DICTIONARY_NAME_INTERNET_CONNEXIONS(): string {
        return Dictionary.names.INTERNET_CONNEXIONS;
    }

    get DICTIONARY_NAME_ISOLATIONS(): string {
        return Dictionary.names.ISOLATIONS;
    }

    get DICTIONARY_NAME_LOGEMENTS(): string {
        return Dictionary.names.LOGEMENTS;
    }

    get DICTIONARY_NAME_MITOYENNETES(): string {
        return Dictionary.names.MITOYENNETES;
    }

    get DICTIONARY_NAME_PIECE_TYPES(): string {
        return Dictionary.names.PIECE_TYPES;
    }

    get DICTIONARY_NAME_PLAIN_PIEDS(): string {
        return Dictionary.names.PLAIN_PIEDS;
    }

    get DICTIONARY_NAME_VOLETS(): string {
        return Dictionary.names.VOLETS;
    }

    get DICTIONARY_NAME_VUES(): string {
        return Dictionary.names.VUES;
    }

    get FIELDS(): Record<string, string> {
        return DossierBienFieldsConst;
    }

    get ngBienDossier(): NgBienDossier {
        return this._ngBienDossier;
    }

    ngOnInit(): void {
        this._dossierBienService.getNgCurrent$(this._routesService.getTypeDossierFromUrl()).pipe(
            tap(ngBienDossier => {
                this._bienAssainissements = [];
                ngBienDossier.bien.assainissements?.forEach(assainissement => this._bienAssainissements.push(this._dictionaryItemFactory.ngCreate(assainissement)));
                this._bienCharpentes = [];
                ngBienDossier.bien.charpentes?.forEach(charpente => this._bienCharpentes.push(this._dictionaryItemFactory.ngCreate(charpente)));
                this._bienChauffages = [];
                ngBienDossier.bien.chauffages?.forEach(chauffage => this._bienChauffages.push(this._dictionaryItemFactory.ngCreate(chauffage)));
                this._bienClotures = [];
                ngBienDossier.bien.clotures?.forEach(cloture => this._bienClotures.push(this._dictionaryItemFactory.ngCreate(cloture)));
                this._bienConnexionsInternet = [];
                ngBienDossier.bien.connexionsInternet?.forEach(connexionInternet => this._bienConnexionsInternet.push(this._dictionaryItemFactory.ngCreate(connexionInternet)));
                this._bienHuisseries = [];
                ngBienDossier.bien.huisseries?.forEach(huisserie => this._bienHuisseries.push(this._dictionaryItemFactory.ngCreate(huisserie)));
                this._bienIsolations = [];
                ngBienDossier.bien.isolations?.forEach(isolation => this._bienIsolations.push(this._dictionaryItemFactory.ngCreate(isolation)));
                this._bienMateriauxConstruction = [];
                ngBienDossier.bien.materiauxConstruction?.forEach(materiauConstruction => this._bienMateriauxConstruction.push(this._dictionaryItemFactory.ngCreate(materiauConstruction)));
                this._bienMateriauxCouverture = [];
                ngBienDossier.bien.materiauxCouverture?.forEach(materiauCouverture => this._bienMateriauxCouverture.push(this._dictionaryItemFactory.ngCreate(materiauCouverture)));
                this._bienVolets = [];
                ngBienDossier.bien.volets?.forEach(volet => this._bienVolets.push(this._dictionaryItemFactory.ngCreate(volet)));
                this._bienVues = [];
                ngBienDossier.bien.vues?.forEach(vue => this._bienVues.push(this._dictionaryItemFactory.ngCreate(vue)));

                if (ngBienDossier.bien.option.epoqueConstruction) {
                    this._bienEpoqueConstruction = this._dictionaryItemFactory.ngCreate(ngBienDossier.bien.option.epoqueConstruction);
                } else {
                    this._bienEpoqueConstruction = undefined!;
                }

                if (ngBienDossier.bien.option.etat) {
                    this._bienEtat = this._dictionaryItemFactory.ngCreate(ngBienDossier.bien.option.etat);
                } else {
                    this._bienEtat = undefined!;
                }

                if (ngBienDossier.bien.option.exposition) {
                    this._bienExposition = this._dictionaryItemFactory.ngCreate(ngBienDossier.bien.option.exposition);
                } else {
                    this._bienExposition = undefined!;
                }

                if (ngBienDossier.bien.option.mitoyennete) {
                    this._bienMitoyennete = this._dictionaryItemFactory.ngCreate(ngBienDossier.bien.option.mitoyennete);
                } else {
                    this._bienMitoyennete = undefined!;
                }

                if (ngBienDossier.bien.option.plainPied) {
                    this._bienPlainPied = this._dictionaryItemFactory.ngCreate(ngBienDossier.bien.option.plainPied);
                } else {
                    this._bienPlainPied = undefined!;
                }
            }),
            tap(ngBienDossier => this._dossierBien = this._dossierBienFactory.ngCreate(ngBienDossier)),
            takeUntil(this._onDestroy$),
        ).subscribe(ngBienDossier => this._ngBienDossier = ngBienDossier);
        this._collectionSortableService.movedItem$.pipe(
            map(([ngBienItem, newIdx]) => [ngBienItem, newIdx] as [NgBienCollectionItem, number]),
            filter(([ngBienItem]) => ngBienItem.eskManager?.prefixRoute === 'commodites' || ngBienItem.eskManager?.prefixRoute === 'logements' || ngBienItem.eskManager?.prefixRoute === 'pieces'),
            takeUntil(this._onDestroy$),
        ).subscribe(([ngBienItem, newIdx]) => ngBienItem.updateRank(newIdx + 1));
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    addNewCommodite(): void {
        const virginCommodite = this.ngBienCommoditeManager.create({}, this.ngBienDossier.bien.id);

        this.editCommodite(virginCommodite);
    }

    addNewLogement(): void {
        const virginLogement = this.ngBienLogementManager.create({}, this.ngBienDossier.bien.id);

        this.editLogement(virginLogement);
    }

    addNewPiece(): void {
        const virginPiece = this.ngBienPieceManager.create({}, this.ngBienDossier.bien.id);

        this.editPiece(virginPiece);
    }

    editCommodite(ngBienCommoditeToSave: NgBienCommodite): void {
        this._modalService.open$(DCBienEditCommoditeComponent, {
            ngBienDossier: this.ngBienDossier,
            ngBienCommodite: ngBienCommoditeToSave.copy(),
        }).pipe(
            map(ngBienCommoditeSaved => ngBienCommoditeSaved as NgBienCommodite),
            take(1),
        ).subscribe(ngBienCommoditeSaved => {
            if (!ngBienCommoditeSaved) {
                return;
            }

            const idx = this.ngBienDossier.bien.commodites.findIndex(ngBienCommoditeFinding => ngBienCommoditeFinding.uuid === ngBienCommoditeSaved.uuid);

            if (idx >= 0) {
                this.ngBienDossier.bien.commodites[idx] = ngBienCommoditeSaved;
            } else {
                this.ngBienDossier.bien.commodites.push(ngBienCommoditeSaved);
            }
        });
    }

    editLogement(ngBienLogementToSave: NgBienLogement): void {
        this._modalService.open$(DCBienEditLogementComponent, {
            ngBienDossier: this.ngBienDossier,
            ngBienLogement: ngBienLogementToSave.copy(),
        }).pipe(
            map(ngBienLogementSaved => ngBienLogementSaved as NgBienLogement),
            take(1),
        ).subscribe(ngBienLogementSaved => {
            if (!ngBienLogementSaved) {
                return;
            }

            const idx = this.ngBienDossier.bien.logements.findIndex(ngBienLogementFinding => ngBienLogementFinding.uuid === ngBienLogementSaved.uuid);

            if (idx >= 0) {
                this.ngBienDossier.bien.logements[idx] = ngBienLogementSaved;
            } else {
                this.ngBienDossier.bien.logements.push(ngBienLogementSaved);
            }

            this.ngBienDossier.bien.sumLogementsSurface();
        });
    }

    editPiece(ngBienPieceToSave: NgBienPiece): void {
        this._modalService.open$(DCBienEditPieceComponent, {
            ngBienDossier: this.ngBienDossier,
            ngBienPiece: ngBienPieceToSave.copy(),
        }).pipe(
            map(ngBienPieceSaved => ngBienPieceSaved as NgBienPiece),
            take(1),
        ).subscribe(ngBienPieceSaved => {
            if (!ngBienPieceSaved) {
                return;
            }

            const idx = this.ngBienDossier.bien.pieces.findIndex(ngBienPieceFinding => ngBienPieceFinding.uuid === ngBienPieceSaved.uuid);

            if (idx >= 0) {
                this.ngBienDossier.bien.pieces[idx] = ngBienPieceSaved;
            } else {
                this.ngBienDossier.bien.pieces.push(ngBienPieceSaved);
            }

            this.ngBienDossier.bien.sumPiecesSurface();
        });
    }

    editSanitaires(): void {
        this._dossierBien.bien.nombreBains = this.ngBienDossier.bien.option.nombreBains;
        this._dossierBien.bien.nombreDouches = this.ngBienDossier.bien.option.nombreDouches;
        this._dossierBien.bien.nombreSallesBains = this.ngBienDossier.bien.option.nombreSallesBains;
        this._dossierBien.bien.nombreSallesEau = this.ngBienDossier.bien.option.nombreSallesEau;
        this._dossierBien.bien.nombreWc = this.ngBienDossier.bien.option.nombreWc;
        this._dossierBien.bien.wcBroyeur = this.ngBienDossier.bien.option.wcBroyeur;
        this._modalService.open$(DCBienEditSanitairesComponent, {dossierBien: this._dossierBien}).pipe(
            map(dossierBien => dossierBien as ADossierBien),
            take(1),
        ).subscribe(dossierBien => {
            if (dossierBien) {
                this.ngBienDossier.bien.option.nombreBains = dossierBien.bien.nombreBains;
                this.ngBienDossier.bien.option.nombreDouches = dossierBien.bien.nombreDouches;
                this.ngBienDossier.bien.option.nombreSallesBains = dossierBien.bien.nombreSallesBains;
                this.ngBienDossier.bien.option.nombreSallesEau = dossierBien.bien.nombreSallesEau;
                this.ngBienDossier.bien.option.nombreWc = dossierBien.bien.nombreWc;
                this.ngBienDossier.bien.option.wcBroyeur = dossierBien.bien.wcBroyeur;
                this._dossierBienService.askToBeSaved(this._dossierBien);
            }
        });
    }

    onChangeAnneeConstruction(): void {
        this.onSelectEpoqueConstruction(this._dictionaryItemService.getEpoqueFromAnnee(this._ngBienDossier.bien.option.anneeConstruction));
    }

    onSelectAssainissements(assainissementsSelected: DictionarySelected): void {
        if (Array.isArray(assainissementsSelected)) {
            this._bienAssainissements = assainissementsSelected;
        } else {
            this._bienAssainissements = [];
        }

        this._ngBienDossier.bien.assainissements = [];
        this._bienAssainissements.forEach(assainissement => this._ngBienDossier.bien.assainissements.push(this.ngDictionariesManager.createFromNg2(assainissement)));
    }

    onSelectCharpentes(charpentesSelected: DictionarySelected): void {
        if (Array.isArray(charpentesSelected)) {
            this._bienCharpentes = charpentesSelected;
        } else {
            this._bienCharpentes = [];
        }

        this._ngBienDossier.bien.charpentes = [];
        this._bienCharpentes.forEach(charpente => this._ngBienDossier.bien.charpentes.push(this.ngDictionariesManager.createFromNg2(charpente)));
    }

    onSelectChauffages(chauffagesSelected: DictionarySelected): void {
        if (Array.isArray(chauffagesSelected)) {
            this._bienChauffages = chauffagesSelected;
        } else {
            this._bienChauffages = [];
        }

        this._ngBienDossier.bien.chauffages = [];
        this._bienChauffages.forEach(chauffage => this._ngBienDossier.bien.chauffages.push(this.ngDictionariesManager.createFromNg2(chauffage)));
    }

    onSelectClotures(cloturesSelected: DictionarySelected): void {
        if (Array.isArray(cloturesSelected)) {
            this._bienClotures = cloturesSelected;
        } else {
            this._bienClotures = [];
        }

        this._ngBienDossier.bien.clotures = [];
        this._bienClotures.forEach(cloture => this._ngBienDossier.bien.clotures.push(this.ngDictionariesManager.createFromNg2(cloture)));
    }

    onSelectCommoditeType(ngBienCommodite: NgBienCommodite, commoditeType: DictionarySelected): void {
        if (commoditeType && !Array.isArray(commoditeType)) {
            ngBienCommodite.commodite = this.ngDictionariesManager.createFromNg2(commoditeType);
        } else {
            ngBienCommodite.commodite = undefined!;
        }

        this.saveCommodite(ngBienCommodite);
    }

    onSelectConnexionsInternet(connexionsInternetSelected: DictionarySelected): void {
        if (Array.isArray(connexionsInternetSelected)) {
            this._bienConnexionsInternet = connexionsInternetSelected;
        } else {
            this._bienConnexionsInternet = [];
        }

        this._ngBienDossier.bien.connexionsInternet = [];
        this._bienConnexionsInternet.forEach(connexionInternet => this._ngBienDossier.bien.connexionsInternet.push(this.ngDictionariesManager.createFromNg2(connexionInternet)));
    }

    onSelectEpoqueConstruction(epoqueConstructionSelected: DictionarySelected): void {
        this._bienEpoqueConstruction = epoqueConstructionSelected as DictionaryItem;
        this._ngBienDossier.bien.option.epoqueConstruction = this.ngDictionariesManager.createFromNg2(this._bienEpoqueConstruction);
    }

    onSelectEtat(etatSelected: DictionarySelected): void {
        this._bienEtat = etatSelected as DictionaryItem;
        this._ngBienDossier.bien.option.etat = this.ngDictionariesManager.createFromNg2(this._bienEtat);
    }

    onSelectExposition(expositionSelected: DictionarySelected): void {
        this._bienExposition = expositionSelected as DictionaryItem;
        this._ngBienDossier.bien.option.exposition = this.ngDictionariesManager.createFromNg2(this._bienExposition);
    }

    onSelectHuisseries(huisseriesSelected: DictionarySelected): void {
        if (Array.isArray(huisseriesSelected)) {
            this._bienHuisseries = huisseriesSelected;
        } else {
            this._bienHuisseries = [];
        }

        this._ngBienDossier.bien.huisseries = [];
        this._bienHuisseries.forEach(huisserie => this._ngBienDossier.bien.huisseries.push(this.ngDictionariesManager.createFromNg2(huisserie)));
    }

    onSelectIsolations(isolationsSelected: DictionarySelected): void {
        if (Array.isArray(isolationsSelected)) {
            this._bienIsolations = isolationsSelected;
        } else {
            this._bienIsolations = [];
        }

        this._ngBienDossier.bien.isolations = [];
        this._bienIsolations.forEach(isolation => this._ngBienDossier.bien.isolations.push(this.ngDictionariesManager.createFromNg2(isolation)));
    }

    onSelectLogementEtage(ngBienLogement: NgBienLogement, etage: DictionarySelected): void {
        if (etage && !Array.isArray(etage)) {
            ngBienLogement.etage = this.ngDictionariesManager.createFromNg2(etage);
        } else {
            ngBienLogement.etage = undefined!;
        }

        this.saveLogement(ngBienLogement);
    }

    onSelectLogementType(ngBienLogement: NgBienLogement, logementType: DictionarySelected): void {
        if (logementType && !Array.isArray(logementType)) {
            ngBienLogement.logement = this.ngDictionariesManager.createFromNg2(logementType);
        } else {
            ngBienLogement.logement = undefined!;
        }

        this.saveLogement(ngBienLogement);
    }

    onSelectMateriauxConstruction(materiauxConstructionSelected: DictionarySelected): void {
        if (Array.isArray(materiauxConstructionSelected)) {
            this._bienMateriauxConstruction = materiauxConstructionSelected;
        } else {
            this._bienMateriauxConstruction = [];
        }

        this._ngBienDossier.bien.materiauxConstruction = [];
        this._bienMateriauxConstruction.forEach(materiauConstruction => this._ngBienDossier.bien.materiauxConstruction.push(this.ngDictionariesManager.createFromNg2(materiauConstruction)));
    }

    onSelectMateriauxCouverture(materiauxCouvertureSelected: DictionarySelected): void {
        if (Array.isArray(materiauxCouvertureSelected)) {
            this._bienMateriauxCouverture = materiauxCouvertureSelected;
        } else {
            this._bienMateriauxCouverture = [];
        }

        this._ngBienDossier.bien.materiauxCouverture = [];
        this._bienMateriauxCouverture.forEach(materiauCouverture => this._ngBienDossier.bien.materiauxCouverture.push(this.ngDictionariesManager.createFromNg2(materiauCouverture)));
    }

    onSelectMitoyennete(mitoyenneteSelected: DictionarySelected): void {
        this._bienMitoyennete = mitoyenneteSelected as DictionaryItem;
        this._ngBienDossier.bien.option.mitoyennete = this.ngDictionariesManager.createFromNg2(this._bienMitoyennete);
    }

    onSelectPieceEtage(ngBienPiece: NgBienPiece, etage: DictionarySelected): void {
        if (etage && !Array.isArray(etage)) {
            ngBienPiece.etage = this.ngDictionariesManager.createFromNg2(etage);
        } else {
            ngBienPiece.etage = undefined!;
        }

        this.savePiece(ngBienPiece);
    }

    onSelectPieceType(ngBienPiece: NgBienPiece, pieceType: DictionarySelected): void {
        if (pieceType && !Array.isArray(pieceType)) {
            ngBienPiece.piece = this.ngDictionariesManager.createFromNg2(pieceType);
        } else {
            ngBienPiece.piece = undefined!;
        }

        this.savePiece(ngBienPiece);
    }

    onSelectPlainPied(plainPiedSelected: DictionarySelected): void {
        this._bienPlainPied = plainPiedSelected as DictionaryItem;
        this._ngBienDossier.bien.option.plainPied = this.ngDictionariesManager.createFromNg2(this._bienPlainPied);
    }

    onSelectVolets(voletsSelected: DictionarySelected): void {
        if (Array.isArray(voletsSelected)) {
            this._bienVolets = voletsSelected;
        } else {
            this._bienVolets = [];
        }

        this._ngBienDossier.bien.volets = [];
        this._bienVolets.forEach(volet => this._ngBienDossier.bien.volets.push(this.ngDictionariesManager.createFromNg2(volet)));
    }

    onSelectVues(vuesSelected: DictionarySelected): void {
        if (Array.isArray(vuesSelected)) {
            this._bienVues = vuesSelected;
        } else {
            this._bienVues = [];
        }

        this._ngBienDossier.bien.vues = [];
        this._bienVues.forEach(vue => this._ngBienDossier.bien.vues.push(this.ngDictionariesManager.createFromNg2(vue)));
    }

    removeCommodite(ngBienCommodite: NgBienCommodite): void {
        from(this.ngBienDossier.bien.removeItem('commodites', ngBienCommodite)).pipe(take(1)).subscribe();
    }

    removeLogement(ngBienLogement: NgBienLogement): void {
        from(this.ngBienDossier.bien.removeItem('logements', ngBienLogement))
            .pipe(take(1))
            .subscribe(_ => this.ngBienDossier.bien.sumLogementsSurface());
    }

    removePiece(ngBienPiece: NgBienPiece): void {
        from(this.ngBienDossier.bien.removeItem('pieces', ngBienPiece))
            .pipe(take(1))
            .subscribe(_ => this.ngBienDossier.bien.sumPiecesSurface());
    }

    saveCommodite(ngBienCommodite: NgBienCommodite): void {
        this._ngBienDossier.bien.saveItem('commodites', ngBienCommodite, true);
    }

    saveLogement(ngBienLogement: NgBienLogement): void {
        this._ngBienDossier.bien.saveItem('logements', ngBienLogement, true).finally(() => this._ngBienDossier.bien.sumLogementsSurface());
    }

    savePiece(ngBienPiece: NgBienPiece): void {
        this._ngBienDossier.bien.saveItem('pieces', ngBienPiece, true).finally(() => this._ngBienDossier.bien.sumPiecesSurface());
    }

    showFieldForBien(field: string): boolean {
        return this._dossierBienFieldsService.isEditionVisible(this._dossierBien, field);
    }

    showPanelForBien(panel: string): boolean {
        return this._bienEditVisibilityPanelService.isVisible(this._dossierBien, panel);
    }
}
