import {Component, inject, ViewEncapsulation} from '@angular/core';
import {IDynamicComponent, IDynamicComponentData} from '@shared/shared.interfaces';
import {take} from 'rxjs/operators';
import {ModalService} from '@shared/modal/modal.service';
import {NgForm} from '@angular/forms';
import {from} from 'rxjs';
import {NgDictionariesManager} from '@legacy/app/managers/managers';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {DictionarySelected} from '@features/dictionaries/dictionaries.interfaces';
import {NgBienDiagnostic, NgBienDossier} from '@legacy/app/managers/ressources';
import DateFormat from '@shared/date/date.format';
import {IFormSelectDateOptions} from '@shared/form/form.interfaces';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';

@Component({encapsulation: ViewEncapsulation.None, templateUrl: 'bien.edit-diagnostic.component.html'})
export class DCBienEditDiagnosticComponent implements IDynamicComponent {
    private _modalService = inject(ModalService);
    private _ngInjectorService = inject(NgInjectorService);
    private _ngBienDossier!: NgBienDossier;
    private _ngBienDiagnostic!: NgBienDiagnostic;
    private _optionsDate: IFormSelectDateOptions = {inputClass: 'form-control', max: DateFormat.toISOString()};

    // Supprimer les injections des anciens manager
    private get ngDictionariesManager(): NgDictionariesManager {
        return this._ngInjectorService.getService('DictionariesManager');
    }

    set data(dynamicComponentData: IDynamicComponentData) {
        this._ngBienDossier = dynamicComponentData.ngBienDossier as NgBienDossier;
        this._ngBienDiagnostic = dynamicComponentData.ngBienDiagnostic as NgBienDiagnostic;
    }

    get DICTIONARY_NAME_DIAGNOSTIC_TYPES(): string {
        return Dictionary.names.DIAGNOSTIC_TYPES;
    }

    get ngBienDiagnostic(): NgBienDiagnostic {
        return this._ngBienDiagnostic;
    }

    get optionsDate(): IFormSelectDateOptions {
        return this._optionsDate;
    }

    onSelectDiagnostic(diagnosticType: DictionarySelected): void {
        if (diagnosticType && !Array.isArray(diagnosticType)) {
            this._ngBienDiagnostic.typeDiagnostic = this.ngDictionariesManager.createFromNg2(diagnosticType);
        } else {
            this._ngBienDiagnostic.typeDiagnostic = undefined!;
        }
    }

    onSelectDate(date: string): void {
        this._ngBienDiagnostic.date = DateFormat.toDate(date);
    }

    save(editForm: NgForm): void {
        if (!editForm.submitted || !editForm.valid) {
            return;
        }

        from(this._ngBienDossier.bien.saveItem('diagnostics', this._ngBienDiagnostic, true))
            .pipe(take(1)).subscribe(_ => this._modalService.setResponse(this._ngBienDiagnostic));
    }
}
