import {Component, EventEmitter, inject, Input, Output} from '@angular/core';
import CContacts from '@models/contacts/collection/contacts.collection.model';
import Contact from '@models/contacts/contact/contact.model';
import {take} from 'rxjs/operators';
import {CContactsService} from '@models/contacts/collection/contacts.collection.service';
import {Observable} from 'rxjs';
import Etude from '@models/etudes/etude/etude.model';
import {AppNoteoIconTextNotyComponent} from '@shared/noteo/icon-text/noty/noteo-icon-text.noty.component';

@Component({selector: 'app-contacts-source', templateUrl: 'contacts-source.component.html'})
export class AppContactsSourceComponent {
    @Output() added = new EventEmitter<Contact>();

    readonly infoSources = {
        [Contact.sources.INOT]: {
            LABEL: '  Depuis ' + Etude.enablableModules.INOT.label + ' : Contacts',
            LOGO: Etude.enablableModules.INOT.srcImage,
            DISPLAY_SOURCE: false,
        },
        [Contact.sources.LOCAL]: {
            LABEL: '  Depuis ' + AppNoteoIconTextNotyComponent.label,
            LOGO: AppNoteoIconTextNotyComponent.srcImage,
            DISPLAY_SOURCE: true,
        },
    };
    private _cContactsService = inject(CContactsService);
    private _cContacts$!: Observable<CContacts>;
    private _enableAdding = true;
    private _selectedContacts!: Contact[];
    private _keyword!: string;
    private _pendingGetMore = false;
    private _source!: string;

    get enableAdding(): boolean {
        return this._enableAdding;
    }

    @Input()
    set enableAdding(value: boolean) {
        this._enableAdding = value;
    }

    get cContacts$(): Observable<CContacts> {
        return this._cContacts$;
    }

    @Input()
    set cContacts$(value: Observable<CContacts>) {
        this._cContacts$ = value;
    }

    get keyword(): string {
        return this._keyword;
    }

    @Input()
    set keyword(value: string) {
        this._keyword = value;
    }

    get pendingGetMore(): boolean {
        return this._pendingGetMore;
    }

    @Input()
    set selectedContacts(value: Contact[]) {
        this._selectedContacts = value;
    }

    get source(): string {
        return this._source;
    }

    @Input()
    set source(value: string) {
        this._source = value;
    }

    add(contact: Contact): void {
        this.added.emit(contact);
    }

    getMore(cContacts: CContacts): void {
        this._pendingGetMore = true;
        this._cContactsService.addNext$(cContacts).pipe(take(1)).subscribe({complete: () => this._pendingGetMore = false});
    }

    isAlreadySelected(contact: Contact): boolean {
        if (contact.isNew() && contact.externalId) {
            return !!this._selectedContacts.find(selectedContact => selectedContact.externalSource === contact.externalSource && selectedContact.externalId === contact.externalId);
        }

        return !!this._selectedContacts.find(selectedContact => selectedContact.uuid === contact.uuid);
    }
}
