import {Directive, EventEmitter, HostBinding, HostListener, Input, NgZone, Output} from '@angular/core';

@Directive({selector: '[appFormFileDropper]'})
export class AppFormFileDropperDirective {
    @Output() dragoverActiv = new EventEmitter<boolean>();
    @Output() droppedFile = new EventEmitter<File>();

    @HostBinding('class.dragover') private _isDragover!: boolean;
    private _ngZone: NgZone;
    private _browserSupported: boolean;
    private _isDroppable = true;
    private _isEntered = false;

    constructor(ngZone: NgZone) {
        this._ngZone = ngZone;
        this._browserSupported = typeof DataTransferItem.prototype.webkitGetAsEntry === 'function';
    }

    @Input()
    set isDroppable(value: boolean) {
        this._isDroppable = value;
    }

    get isDroppable(): boolean {
        return this._browserSupported && this._isDroppable;
    }

    /**
     * Use timeout on dragenter to prevent dragleave
     * to reset isDragOver each time mouse move
     * See https://stackoverflow.com/a/14392772/7036738
     */
    @HostListener('dragenter', ['$event'])
    onDragEnter(dragEvent: DragEvent): void {
        if (!this.isDroppable) {
            return;
        }

        dragEvent.preventDefault();
        dragEvent.stopPropagation();
        this._isEntered = true;
        setTimeout(() => this._isEntered = false, 0);
    }

    @HostListener('dragover', ['$event'])
    onDragOver(dragEvent: DragEvent): void {
        if (typeof dragEvent === 'boolean' || !this.isDroppable) {
            return;
        }

        dragEvent.preventDefault();
        dragEvent.stopPropagation();
        this._isDragover = true;
        this.dragoverActiv.emit(true);
    }

    @HostListener('dragleave', ['$event'])
    onDragLeave(dragEvent: DragEvent): void {
        if (!this.isDroppable) {
            return;
        }

        dragEvent.preventDefault();
        dragEvent.stopPropagation();
        if (!this._isEntered) {
            this._isDragover = false;
            this.dragoverActiv.emit(false);
        }

        this._isEntered = false;
    }

    @HostListener('drop', ['$event'])
    onDrop(dragEvent: DragEvent): void {
        if (!this.isDroppable) {
            return;
        }

        dragEvent.preventDefault();
        dragEvent.stopPropagation();
        this._isDragover = false;
        this._isEntered = false;
        this.dragoverActiv.emit(false);
        if (dragEvent.dataTransfer && dragEvent.dataTransfer.items.length > 0) {
            Array.from(dragEvent.dataTransfer.items)
                .map(dataTransferItem => dataTransferItem.webkitGetAsEntry())
                .filter(fileSystemEntry => fileSystemEntry?.isFile)
                .map(fileSystemEntry => fileSystemEntry as FileSystemFileEntry)
                .forEach(fileSystemFileEntry => fileSystemFileEntry?.file(file => this._ngZone.run(() => this.droppedFile.emit(file))));
        }
    }
}
