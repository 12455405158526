import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {IDynamicComponent} from '@shared/shared.interfaces';
import Offreachat from '@models/offreachats/offreachat/offreachat.model';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {takeUntil} from 'rxjs/operators';
import {OffreachatDropdownComponent} from '@features/offreachats/item/dropdown/offreachat.dropdown.component';
import {IOffreachatActions, IOffreachatActionsMainData} from '@features/offreachats/offreachats.interfaces';

@Component({selector: 'offreachat-sign-actions-main', templateUrl: 'offreachat-sign.actions-main.component.html'})
export class OffreachatSignActionsMainComponent implements IDynamicComponent, OnDestroy, OnInit {
    static readonly actions = {...{LAUNCH: 'launch'}, ...OffreachatDropdownComponent.actions};
    static readonly initOffreachatActionsMainDataOptions: IOffreachatActions = {cancelSign: true};
    private _callToActionService: CallToActionService;
    private _dropdownService: DropdownService;
    private _offreachat!: Offreachat;
    private readonly _onDestroy$ = new Subject<void>();
    private _options: IOffreachatActions = {...OffreachatSignActionsMainComponent.initOffreachatActionsMainDataOptions};

    constructor(callToActionService: CallToActionService, dropdownService: DropdownService) {
        this._callToActionService = callToActionService;
        this._dropdownService = dropdownService;
    }

    get actions(): { CANCEL_SIGN: string; SIGN: string } {
        return OffreachatSignActionsMainComponent.actions;
    }

    set data(value: IOffreachatActionsMainData) {
        this._offreachat = value.offreachat;
        this._options = {...OffreachatSignActionsMainComponent.initOffreachatActionsMainDataOptions, ...value.options};
    }

    get options(): IOffreachatActions {
        return this._options;
    }

    get pending$(): Observable<boolean> {
        return this._callToActionService.pending$;
    }

    ngOnInit(): void {
        this._dropdownService.clicked$.pipe(takeUntil(this._onDestroy$))
            .subscribe(dropdownClicked => this._callToActionService.clicked(dropdownClicked.action, dropdownClicked.value));
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    clickDropdown(htmlButtonElement: HTMLButtonElement): void {
        this._dropdownService.open(htmlButtonElement, {
            component: OffreachatDropdownComponent,
            data: {
                offreachat: this._offreachat,
                options: {
                    enabledActions: {
                        archive: this._options.archive,
                        cancelSign: this._options.sign && this._options.cancelSign,
                        print: this._options.print,
                        save: this._options.save,
                    }
                },
            },
        });
    }

    action(action: string): void {
        this._callToActionService.clicked(action, this._offreachat);
    }
}
