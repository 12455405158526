import {Component, OnInit} from '@angular/core';
import {UserService} from '@models/users/user/user.service';
import {combineLatest, Observable, of} from 'rxjs';
import {catchError, map, switchMap, take, tap} from 'rxjs/operators';
import {CProceduresService} from '@models/procedures/collection/procedures.collection.service';
import CProcedures from '@models/procedures/collection/procedures.collection.model';
import Procedure from '@models/procedures/procedure/procedure.model';
import Document from '@models/documents/document/document.model';
import {ModalService} from '@shared/modal/modal.service';
import {
    CProcedureSignatairesFactory
} from '@models/procedures/procedure/signataires/collection/procedure-signataires.collection.factory';
import {ToasterService} from '@shared/toaster/toaster.service';
import {BonvisiteFactory} from '@models/bonvisites/bonvisite/bonvisite.factory';
import {OffreachatFactory} from '@models/offreachats/offreachat/offreachat.factory';
import {DemandeurFactory} from '@models/demandeurs/demandeur/demandeur.factory';
import {Router} from '@angular/router';
import {VenteFactory} from '@models/ventes/vente/vente.factory';
import {SortConst} from '@shared/constants';

@Component({
    selector: 'app-procedures-list',
    styleUrls: ['procedures-list.component.scss'],
    templateUrl: 'procedures-list.component.html',
})
export class AppProceduresListComponent implements OnInit {
    static readonly messages = {
        uncomplete: {MESSAGE: 'Le document relié à cette procédure n\'est pas complet.', TITLE: 'Document incomplet'},
        unknown: {MESSAGE: 'Le document relié à cette procédure n\'existe plus.', TITLE: 'Document inconnu'},
    };
    private _bonvisiteFactory: BonvisiteFactory;
    private _cProcedureSignatairesFactory: CProcedureSignatairesFactory;
    private _cProceduresService: CProceduresService;
    private _demandeurFactory: DemandeurFactory;
    private _modalService: ModalService;
    private _offreachatFactory: OffreachatFactory;
    private _toasterService: ToasterService;
    private _router: Router;
    private _userService: UserService;
    private _venteFactory: VenteFactory;
    private _documents: Record<string, Document> = {};
    private _hasRole$!: Observable<boolean>;
    private _pendingGetMore = false;

    constructor(bonvisiteFactory: BonvisiteFactory,
                cProcedureSignatairesFactory: CProcedureSignatairesFactory,
                cProceduresService: CProceduresService,
                demandeurFactory: DemandeurFactory,
                modalService: ModalService,
                offreachatFactory: OffreachatFactory,
                router: Router,
                toasterService: ToasterService,
                userService: UserService,
                venteFactory: VenteFactory) {
        this._bonvisiteFactory = bonvisiteFactory;
        this._cProcedureSignatairesFactory = cProcedureSignatairesFactory;
        this._cProceduresService = cProceduresService;
        this._demandeurFactory = demandeurFactory;
        this._modalService = modalService;
        this._offreachatFactory = offreachatFactory;
        this._router = router;
        this._toasterService = toasterService;
        this._userService = userService;
        this._venteFactory = venteFactory;
    }

    get cProcedures$(): Observable<CProcedures> {
        return this._cProceduresService.current$;
    }

    get documents(): Record<string, Document> {
        return this._documents;
    }

    get hasRole$(): Observable<boolean> {
        return this._hasRole$;
    }

    get pendingGetMore(): boolean {
        return this._pendingGetMore;
    }

    ngOnInit(): void {
        this._hasRole$ = this._userService.last$.pipe(
            map(currentUser => currentUser.hasRoleSignatureElectronique()),
            tap(hasRole => {
                if (hasRole) {
                    this._cProceduresService.initCurrent({tris: {updatedAt: SortConst.DESCENDING}});
                } else {
                    this._router.navigateByUrl('/app/dashboard');
                }
            }),
        );
    }

    getDocumentProcedure$(document: Document, procedure: Procedure): void {
        // Voir https://angular.io/errors/NG0100 avant de supprimer setTimeout()
        setTimeout(_ => this._documents[procedure.uuid] = document, 0);
    }

    getMore(): void {
        this._pendingGetMore = true;
        this._cProceduresService.addNextToCurrent$().pipe(take(1)).subscribe({complete: () => this._pendingGetMore = false});
    }

    onAction(procedure: Procedure): void {
        if (procedure.isPresentielEditable()) {
            this._router.navigateByUrl('/app/procedures/' + procedure.uuid);
        } else {
            this._cProcedureSignatairesFactory.get$(procedure.uuid).pipe(
                tap(cProcedureSignataires => procedure.cSignataires = cProcedureSignataires),
                switchMap(_ => this._modalService.ngOpen$('EskModalProcedure', {
                    resolve: {document: this._documents[procedure.uuid], procedure},
                })),
                catchError(_ => of(true)),
                take(1),
            ).subscribe();
        }
    }

    onSelectDocument(document: Document, procedure: Procedure): void {
        if (!document.id) {
            this._toasterService.info(AppProceduresListComponent.messages.unknown.TITLE, AppProceduresListComponent.messages.unknown.MESSAGE);
            return;
        }

        if (!document.variables) {
            this._toasterService.info(AppProceduresListComponent.messages.uncomplete.TITLE, AppProceduresListComponent.messages.uncomplete.MESSAGE);
            return;
        }

        if (document.variables.contratUuid && document.variables.venteUuid) {
            this._venteFactory.get$(document.variables.venteUuid).pipe(
                take(1),
            ).subscribe(vente => {
                if (procedure.isClosed()) {
                    this._router.navigateByUrl('/app/ventes/' + vente.id.toString() + '/documents');
                } else {
                    this._router.navigateByUrl('/app/ventes/' + vente.id.toString() + '/mandats', {
                        state: {contratUuid: document.variables.contratUuid},
                    });
                }
            });
        } else if (document.variables.bonVisiteUuid) {
            this._bonvisiteFactory.get$(document.variables.bonVisiteUuid).pipe(
                take(1),
            ).subscribe(bonvisite => {
                if (bonvisite.isArchive()) {
                    this._router.navigateByUrl('/app/demandeurs/' + bonvisite.demandeurId.toString() + '/bons-visite/' + bonvisite.uuid + '/resume');
                } else {
                    this._router.navigateByUrl('/app/demandeurs/' + bonvisite.demandeurId.toString() + '/bons-visite/' + bonvisite.uuid + '/signature');
                }
            });
        } else if (document.variables.offreAchatUuid) {
            this._offreachatFactory.get$(document.variables.offreAchatUuid).pipe(
                switchMap(offreachat => combineLatest([
                    of(offreachat),
                    this._demandeurFactory.getByLink$(offreachat.linkDemandeur),
                ])),
                take(1),
            ).subscribe(([offreachat, demandeur]) => {
                if (offreachat.isArchive()) {
                    this._router.navigateByUrl('/app/demandeurs/' + demandeur.id.toString() + '/offres-achat/' + offreachat.uuid + '/resume');
                } else if (offreachat.isSign()) {
                    this._router.navigateByUrl('/app/demandeurs/' + demandeur.id.toString() + '/offres-achat/' + offreachat.uuid + '/sign');
                } else {
                    this._router.navigateByUrl('/app/demandeurs/' + demandeur.id.toString() + '/offres-achat/' + offreachat.uuid + '/signature');
                }
            });
        }
    }
}
