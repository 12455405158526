import {Pipe, PipeTransform} from '@angular/core';
import TextsSizeFormat from '@shared/texts/size/texts-size.format';

@Pipe({name: 'textSize'})
export class TextSizePipe implements PipeTransform {
    transform(size: number): string {
        if (!size) {
            return '--';
        }

        return TextsSizeFormat.convertToConvivial(size);
    }
}
