import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {combineLatest, Observable, ReplaySubject, Subject} from 'rxjs';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {IMapBox, IMapMarker, IMapData} from '@shared/map/map.interfaces';
import {IEtudesSiteMapData} from '@features/etudes/etudes.interfaces';
import Site from '@models/sites/site/site.model';
import CSites from '@models/sites/collection/sites.collection.model';

@Component({selector: 'app-etudes-sites-map', templateUrl: 'etudes-sites.map.component.html'})
export class AppEtudesSitesMapComponent implements OnDestroy, OnInit {
    @Output() boxChanged = new EventEmitter<IMapBox>();
    @Output() siteClicked = new EventEmitter<Site>();

    private _combineLatest$!: Observable<{mapBox: IMapBox, data: IMapData}>;
    private _cSites!: CSites;
    private _data$!: Observable<IEtudesSiteMapData>;
    private _mapBox$!: Observable<IMapBox>;
    private _mapDataSource = new ReplaySubject<IMapData>();
    private _mapData$ = this._mapDataSource.asObservable();
    private readonly _onDestroy$ = new Subject<void>();

    @Input()
    set data$(value$: Observable<IEtudesSiteMapData>) {
        this._data$ = value$;
    }

    @Input({required: true})
    set mapBox$(value$: Observable<IMapBox>) {
        this._mapBox$ = value$;
    }

    get combineMap$(): Observable<{mapBox: IMapBox, data: IMapData}> {
        return this._combineLatest$;
    }

    ngOnInit(): void {
        this._data$.pipe(
            takeUntil(this._onDestroy$)
        ).subscribe(data => {
            const markers: IMapMarker[] = [];
            const mapData: IMapData = {};

            if (data.cSites !== undefined) {
                this._cSites = data.cSites;
                data.cSites.results.forEach(site => markers.push({
                    id: site.id.toString(),
                    latitude: site.latitude,
                    longitude: site.longitude,
                }));
            }

            if (markers.length > 0) {
                mapData.markers = markers;
            }

            mapData.useAutoZoom = false;

            mapData.markerIdActivated$ = data.hovered$?.pipe(map(site =>  site?.id.toString()));

            this._mapDataSource.next(mapData);
        });

        this._combineLatest$ = combineLatest([this._mapBox$, this._mapData$.pipe(startWith(undefined! as IMapData))]).pipe(
            map(([mapBox, data]) => ({mapBox, data })),
        );
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    markerClicked(siteId: string): void {
        this.siteClicked.emit(this._cSites.results.find(site => site.id.toString() === siteId));
    }
}
