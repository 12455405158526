interface IApi {
    baseUrl: string;
    client: { id: string; secret: string; };
    mediaUrl: string;
    pathUrl: string;
}

interface IFacebook {
    app: { id: string; };
}

interface IMyNoty {
    baseUrl: string;
}

interface IPrevisite {
    prefix: string;
    url: string;
    partnerToken: string;
}

interface ISentry {
    dsn: string;
    enabled: boolean;
    environment: string;
}

export default class Environment {
    private _api: IApi;
    private _facebook: IFacebook;
    private _myNoty: IMyNoty;
    private _previsite: IPrevisite | null;
    private _production: boolean;
    private _sentry: ISentry;

    constructor(api: IApi,
                facebook: IFacebook,
                myNoty: IMyNoty,
                previsite: IPrevisite | null,
                sentry: ISentry) {
        this._api = api;
        this._facebook = facebook;
        this._myNoty = myNoty;
        this._previsite = previsite;
        this._production = true;
        this._sentry = sentry;
    }

    get api(): IApi {
        return this._api;
    }

    get facebook(): IFacebook {
        return this._facebook;
    }

    get myNoty(): IMyNoty {
        return this._myNoty;
    }

    get previsite(): IPrevisite | null {
        return this._previsite;
    }

    get production(): boolean {
        return this._production;
    }

    set production(value: boolean) {
        this._production = value;
    }

    get sentry(): ISentry {
        return this._sentry;
    }

    set sentry(value: ISentry) {
        this._sentry = value;
    }
}
