import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgSoqModalesManager, NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {ToasterService} from '@shared/toaster/toaster.service';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgEmailManager, NgLocationManager} from '@legacy/app/managers/managers';
import {NgEstimation, NgLocation, NgVente} from '@legacy/app/managers/ressources';
import Bonvisite from '@models/bonvisites/bonvisite/bonvisite.model';
import Location from '@models/locations/location/location.model';

export default function getLocationLifetimeCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.component('eskLocationLifetimeCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/locations/controllers/item/lifetime.html',
        });

        /**
         * Controller of Lifetime Location
         *
         * @param LocationManager
         * @param SoqModalesManager
         * @param Ng2RouterStateForNgService
         * @param $translate
         * @param SoqSweetAlert
         * @param Ng2ToasterService
         * @param EmailManager
         * @constructor
         */
        Controller.$inject = ['LocationManager', 'SoqModalesManager', 'Ng2RouterStateForNgService', '$translate', 'SoqSweetAlert', 'Ng2ToasterService', 'EmailManager'];
        function Controller(this: any,
                            locationManager: NgLocationManager,
                            soqModalesManager: NgSoqModalesManager,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            $translate: angular.translate.ITranslateService,
                            soqSweetAlert: NgSoqSweetAlert,
                            ng2ToasterService: ToasterService,
                            emailManager: NgEmailManager) {
            const $ctrl = this;

            $ctrl.archive = archive;
            $ctrl.createEmail = createEmail;
            $ctrl.createEstimation = createEstimation;
            $ctrl.createVente = createVente;
            $ctrl.duplicate = duplicate;
            $ctrl.BonvisiteStatutNEW = Bonvisite.statuts.NEW;
            $ctrl.location = locationManager.current;
            $ctrl.ng2RouterStateForNgService = ng2RouterStateForNgService;
            $ctrl.STATUT_ARCHIVE = Location.statuts.ARCHIVE;

            /**
             * Open modal archive
             */
            function archive() {
                soqModalesManager.open('LocationModalArchive', {
                    resolve: {location: $ctrl.location}
                }).then(function () {
                    ng2ToasterService.success($translate.instant('location.archive.realised.TITLE'), $translate.instant('location.archive.realised.MESSAGE', {reference: $ctrl.location.bien.reference}));
                    ng2RouterStateForNgService.navigateByUrl('/app/locations/portefeuille');
                });
            }

            /**
             * Create email
             */
            function createEmail() {
                soqModalesManager.open('EskModalEmail', {
                    resolve: {
                        email: emailManager.create({
                            recipients: $ctrl.location.bien.contactsGroup.getRecipients(),
                            dossiers: [
                                {typeId: $ctrl.location.id, type: $ctrl.location._esk.typeDossier, dossier: $ctrl.location},
                            ],
                        }),
                        currentDossier: $ctrl.location,
                    }
                });
            }

            /**
             * Create estimation from location
             */
            function createEstimation() {
                soqSweetAlert.warningConfirmation($translate.instant('location.createEstimation.confirm.TITLE'), $translate.instant('location.createEstimation.confirm.MESSAGE', {
                    reference: $ctrl.location.bien.reference
                }), {
                    input: 'checkbox',
                    customClass: {input: 'checkbox check-green text-bold'},
                    inputPlaceholder: $translate.instant('location.createEstimation.confirm.INPUT'),
                    confirmButtonText: $translate.instant('location.createEstimation.confirm.CONFIRMATION')
                }).then(function (showArchive) {
                    $ctrl.location.createEstimation().then((estimation: NgEstimation) => {
                        ng2ToasterService.success($translate.instant('location.createEstimation.realised.TITLE'), $translate.instant('location.createEstimation.realised.MESSAGE', {
                            reference: estimation.bien.reference
                        }));

                        if (showArchive) {
                            $ctrl.archive();
                        }
                    });
                });
            }

            /**
             * Create vente from location
             */
            function createVente() {
                soqSweetAlert.warningConfirmation($translate.instant('location.createVente.confirm.TITLE'), $translate.instant('location.createVente.confirm.MESSAGE', {
                    reference: $ctrl.location.bien.reference
                }), {
                    input: 'checkbox',
                    customClass: {input: 'checkbox check-green text-bold'},
                    inputPlaceholder: $translate.instant('location.createVente.confirm.INPUT'),
                    confirmButtonText: $translate.instant('location.createVente.confirm.CONFIRMATION')
                }).then(function (showArchive) {
                    $ctrl.location.createVente().then((vente: NgVente) => {
                        ng2ToasterService.success($translate.instant('location.createVente.realised.TITLE'),
                            $translate.instant('location.createVente.realised.MESSAGE', {reference: vente.bien.reference}));

                        if (showArchive) {
                            $ctrl.archive();
                        }
                    });
                });
            }

            /**
             * Duplicate location
             */
            function duplicate() {
                soqSweetAlert.warningConfirmation($translate.instant('location.duplicate.confirm.TITLE'), $translate.instant('location.duplicate.confirm.MESSAGE'), {
                    input: 'checkbox',
                    customClass: {input: 'checkbox check-green text-bold'},
                    inputPlaceholder: $translate.instant('location.duplicate.confirm.INPUT'),
                    confirmButtonText: $translate.instant('location.duplicate.confirm.CONFIRMATION')
                }).then(function (keepOwners) {
                    ng2ToasterService.info($translate.instant('location.duplicate.in_progress.TITLE'), $translate.instant('location.duplicate.in_progress.MESSAGE'));
                    $ctrl.location.clone(keepOwners).then((duplicatedLocation: NgLocation) => {
                        ng2ToasterService.success($translate.instant('location.duplicate.realised.TITLE'), $translate.instant('location.duplicate.realised.MESSAGE', {
                            reference: duplicatedLocation.bien.reference
                        }));
                    });
                });
            }
        }
    })(angularJS);
}
