import Reference from '@models/references/reference/reference.model';
import {IValidationError} from '@models/model.interfaces';
import {ValidationErrorsConst} from '@models/model.constants';
import {EstimationReferenceSourceType} from '@models/estimations/estimation/references/estimation-references.constants';

export default class EstimationReference extends Reference {
    private _sourceType!: EstimationReferenceSourceType;
    private _sourceUuid!: string;

    get sourceType(): EstimationReferenceSourceType {
        return this._sourceType;
    }

    set sourceType(value: EstimationReferenceSourceType) {
        this._sourceType = value;
    }

    get sourceUuid(): string {
        return this._sourceUuid;
    }

    set sourceUuid(value: string) {
        this._sourceUuid = value;
    }

    isNew(): boolean {
        return this.uuid === EstimationReference.statuts.NEW;
    }

    getValidationErrors(): IValidationError[] {
        const validationErrors = super.getValidationErrors();

        if (!this.sourceType) {
            validationErrors.push(ValidationErrorsConst.SOURCE_TYPE);
        }

        if (!this.sourceUuid) {
            validationErrors.push(ValidationErrorsConst.SOURCE_UUID);
        }

        return validationErrors;
    }
}
