import {Component, Input} from '@angular/core';
import {CollectionSelectionService} from '@shared/collection/selection/collection-selection.service';

@Component({selector: 'app-collection-selection-all', templateUrl: 'collection-selection-all.component.html'})
export class CollectionSelectionAllComponent {
    private _collectionSelectionService: CollectionSelectionService;
    private _listName!: string;

    constructor(collectionSelectionService: CollectionSelectionService) {
        this._collectionSelectionService = collectionSelectionService;
    }

    get checkedAll(): boolean {
        return this._collectionSelectionService.hasValues(this._listName) && this._collectionSelectionService.isAllSelected(this._listName);
    }

    get indeterminate(): boolean {
        return this._collectionSelectionService.indeterminate(this._listName);
    }

    @Input()
    set listName(value: string) {
        this._listName = value;
    }

    toggleAll(): void {
        this._collectionSelectionService.toggleAll(this._listName);
    }
}
