import {Component, inject, ViewEncapsulation} from '@angular/core';
import {IDynamicComponent, IDynamicComponentData} from '@shared/shared.interfaces';
import {take} from 'rxjs/operators';
import {ModalService} from '@shared/modal/modal.service';
import {NgForm} from '@angular/forms';
import {from} from 'rxjs';
import {NgDictionariesManager} from '@legacy/app/managers/managers';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {DictionarySelected} from '@features/dictionaries/dictionaries.interfaces';
import {NgBienCharge, NgBienDossier} from '@legacy/app/managers/ressources';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';

@Component({encapsulation: ViewEncapsulation.None, templateUrl: 'bien.edit-charge.component.html'})
export class DCBienEditChargeComponent implements IDynamicComponent {
    private _modalService = inject(ModalService);
    private _ngInjectorService = inject(NgInjectorService);
    private _ngBienDossier!: NgBienDossier;
    private _ngBienCharge!: NgBienCharge;

    // Supprimer les injections des anciens manager
    private get ngDictionariesManager(): NgDictionariesManager {
        return this._ngInjectorService.getService('DictionariesManager');
    }

    set data(dynamicComponentData: IDynamicComponentData) {
        this._ngBienDossier = dynamicComponentData.ngBienDossier as NgBienDossier;
        this._ngBienCharge = dynamicComponentData.ngBienCharge as NgBienCharge;
    }

    get DICTIONARY_NAME_CHARGES(): string {
        return Dictionary.names.CHARGES;
    }

    get DICTIONARY_NAME_PERIODICITES(): string {
        return Dictionary.names.PERIODICITES;
    }

    get ngBienCharge(): NgBienCharge {
        return this._ngBienCharge;
    }

    onSelectCharge(chargeType: DictionarySelected): void {
        if (chargeType && !Array.isArray(chargeType)) {
            this._ngBienCharge.charge = this.ngDictionariesManager.createFromNg2(chargeType);
        } else {
            this._ngBienCharge.charge = undefined!;
        }
    }

    onSelectPeriodicite(periodicite: DictionarySelected): void {
        if (periodicite && !Array.isArray(periodicite)) {
            this._ngBienCharge.periodicite = this.ngDictionariesManager.createFromNg2(periodicite);
        } else {
            this._ngBienCharge.periodicite = undefined!;
        }
    }

    save(editForm: NgForm): void {
        if (!editForm.submitted || !editForm.valid) {
            return;
        }

        from(this._ngBienDossier.bien.saveItem('charges', this._ngBienCharge, true))
            .pipe(take(1)).subscribe(_ => this._modalService.setResponse(this._ngBienCharge));
    }
}
