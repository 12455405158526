import Environment from '@env/environment.model';

const environment = new Environment(
    {
        baseUrl: 'https://api.noty.fr.wip',
        client: {
            id: '1_14ovgnt936680sg4cwgsogc4kggs0k4880kg8sgw4o0ow8o0k4',
            secret: '5wdti8votrwgo4wkgs4c48840k4wsss088scsw4w8kow88wc8s',
        },
        mediaUrl: 'https://media.noty.fr.wip',
        pathUrl: '',
    },
    {app: {id: '326134718200387'}},
    {baseUrl: 'http://my.dev.noty.fr'},
    null,
    {dsn: 'https://847d650596f548a4b401989837c21d07@sentry.io/62938', enabled: false, environment: 'local'}
);

environment.production = false;

export default environment;
