import {Component, HostBinding} from '@angular/core';
import {NavigationService} from '@shared/navigation/navigation.service';

@Component({selector: 'app-navigation-toggle', templateUrl: 'navigation-toggle.component.html'})
export class AppNavigationToggleComponent {
    // @todo Passer ces class dans ClassCSSConst
    static readonly INIT_CLASS = 'tw-block md:tw-hidden tw-h-full tw-mr-1';
    private _navigationService: NavigationService;

    constructor(navigationService: NavigationService) {
        this._navigationService = navigationService;
    }

    @HostBinding('class') class = AppNavigationToggleComponent.INIT_CLASS;

    toggle(): void {
        this._navigationService.toggle();
    }
}
