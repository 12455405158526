import {Component, Input} from '@angular/core';
import {CUsersFactory} from '@models/users/collection/users.collection.factory';
import {Observable} from 'rxjs';
import CUsers from '@models/users/collection/users.collection.model';

@Component({selector: 'app-users-stacked-images', templateUrl: 'users.stacked-images.component.html'})
export class AppUsersStackedImagesComponent {
    private _cUsersFactory: CUsersFactory;
    private _cUsers$!: Observable<CUsers>;

    constructor(cUsersFactory: CUsersFactory) {
        this._cUsersFactory = cUsersFactory;
    }

    get cUsers$(): Observable<CUsers> {
        return this._cUsers$;
    }

    @Input()
    set linkUsers(value: string) {
        this._cUsers$ = this._cUsersFactory.getByLink$(value);
    }
}
