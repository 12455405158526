import {NgModule} from '@angular/core';
import {TextCurrencyPipe} from '@shared/texts/currency/text-currency.pipe';
import {TextNl2brPipe} from '@shared/texts/nl2br/text-nl2br.pipe';
import {TextSizePipe} from '@shared/texts/size/text-size.pipe';
import {TextPhonesComponent} from '@shared/texts/phones/text-phones.component';
import {CommonModule} from '@angular/common';
import {TextPhoneComponent} from '@shared/texts/phones/phone/text-phone.component';
import {FaModule} from '@shared/fontawesome/fa.module';

// @todo Uniformiser le nommage des composants et module, "Text*" ou "Texts*"
@NgModule({
    declarations: [TextCurrencyPipe, TextNl2brPipe, TextPhoneComponent, TextPhonesComponent, TextSizePipe],
    exports: [TextCurrencyPipe, TextNl2brPipe, TextPhoneComponent, TextPhonesComponent, TextSizePipe],
    imports: [CommonModule, FaModule],
})
export class TextsModule {
}
