import {inject, Injectable} from '@angular/core';
import {interval, Observable, of, switchMap} from 'rxjs';
import {NgEskTaskRunner} from '@legacy/app/eskimmo/eskimmo';
import {filter, map} from 'rxjs/operators';
import {NgTask} from '@legacy/app/managers/ressources';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';

@Injectable({providedIn: 'root'})
export class TransfersService {
    private _ngInjectorService = inject(NgInjectorService);

    // Supprimer les injections des anciens manager
    private get ngEskTaskRunner(): NgEskTaskRunner {
        return this._ngInjectorService.getService('EskTaskRunner');
    }

    // @todo Passer par une initialisation et un getter
    getNgTasks$(): Observable<NgTask[]> {
        let previousNgTasks: NgTask[];

        return interval(1000).pipe(
            switchMap(_ => {
                if (previousNgTasks?.length === this.ngEskTaskRunner.getTasks().length) {
                    return of(undefined);
                }

                previousNgTasks = [...this.ngEskTaskRunner.getTasks()];

                return of(previousNgTasks);
            }),
            filter(ngTasks => !!ngTasks),
            map(ngTasks    => ngTasks as NgTask[])
        );
    }
}
