import {Injectable, NgZone} from '@angular/core';

@Injectable({providedIn: 'root'})
export default class IntersectionService {
    static readonly initIntersectionObserverOptions = {rootMargin: '0px', threshold: 0};

    private _ngZone: NgZone;

    constructor(ngZone: NgZone) {
        this._ngZone = ngZone;
    }

    observe(htmlElement: HTMLElement, callback: () => void): void {
        const observer = new IntersectionObserver(([intersectionObserverEntry]) => {
            if (intersectionObserverEntry.isIntersecting) {
                observer.disconnect();
                this._ngZone.run(() => callback());
            }
        }, IntersectionService.initIntersectionObserverOptions);

        observer.observe(htmlElement);
    }
}
