import angularJS from '@shared/angularJS/global.ng';
import {IModule, IQService} from 'angular';

export default function getSoqFileAPI(module: IModule): void {
    (angular => {
        'use strict';

        module.factory('SoqFileAPI', Factory);

        /**
         * All methods to use FileAPI
         *
         * @param $q
         * @param FileAPI
         * @returns {SoqFileAPI}
         */
        Factory.$inject = ['$q'];
        function Factory($q: IQService) {
            const FileAPI = (window as unknown as {FileAPI: any})['FileAPI'];

            FileAPI.withCredentials = false;

            return {upload: upload};

            /**
             * Upload with defined options
             *
             * @param options
             * @returns {Promise}
             */
            function upload(options: any) {
                const deferred = $q.defer();
                let xhr: XMLHttpRequest;

                options.fileprogress = fileprogress;
                options.filecomplete = filecomplete;
                xhr = FileAPI.upload(options);

                return deferred.promise;

                /**
                 * Control progression of upload
                 *
                 * @param evt
                 */
                function fileprogress(evt: any) {
                    evt.xhr = xhr;
                    deferred.notify(evt);
                }

                /**
                 * Action to do when upload is finished
                 *
                 * @param err
                 * @param xhr
                 * @returns {promise}
                 */
                function filecomplete(err: any, xhr: XMLHttpRequest) {
                    let message;

                    if (!err) {
                        return deferred.resolve(JSON.parse(xhr.response));
                    }

                    if (err !== 'unknown') {
                        message = err;
                    } else if (xhr.response === '') {
                        message = 'Internet connexion lost';
                    } else {
                        message = JSON.parse(xhr.response);
                    }

                    return deferred.reject(message);
                }
            }
        }
    })(angularJS);
}
