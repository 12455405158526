import {Component, inject, ViewEncapsulation} from '@angular/core';
import {IDynamicComponent, IDynamicComponentData} from '@shared/shared.interfaces';
import {take} from 'rxjs/operators';
import {ModalService} from '@shared/modal/modal.service';
import {NgForm} from '@angular/forms';
import {from} from 'rxjs';
import {NgDictionariesManager} from '@legacy/app/managers/managers';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {DictionarySelected} from '@features/dictionaries/dictionaries.interfaces';
import {NgBienDossier, NgBienCommodite} from '@legacy/app/managers/ressources';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';

@Component({encapsulation: ViewEncapsulation.None, templateUrl: 'bien.edit-commodite.component.html'})
export class DCBienEditCommoditeComponent implements IDynamicComponent {
    private _modalService = inject(ModalService);
    private _ngInjectorService = inject(NgInjectorService);
    private _ngBienDossier!: NgBienDossier;
    private _ngBienCommodite!: NgBienCommodite;

    // Supprimer les injections des anciens manager
    private get ngDictionariesManager(): NgDictionariesManager {
        return this._ngInjectorService.getService('DictionariesManager');
    }

    set data(dynamicComponentData: IDynamicComponentData) {
        this._ngBienDossier = dynamicComponentData.ngBienDossier as NgBienDossier;
        this._ngBienCommodite = dynamicComponentData.ngBienCommodite as NgBienCommodite;
    }

    get DICTIONARY_NAMES_COMMODITES(): string {
        return Dictionary.names.COMMODITES;
    }

    get ngBienCommodite(): NgBienCommodite {
        return this._ngBienCommodite;
    }

    onSelectCommodite(commoditeType: DictionarySelected): void {
        if (commoditeType && !Array.isArray(commoditeType)) {
            this._ngBienCommodite.commodite = this.ngDictionariesManager.createFromNg2(commoditeType);
        } else {
            this._ngBienCommodite.commodite = undefined!;
        }
    }

    save(editForm: NgForm): void {
        if (!editForm.submitted || !editForm.valid) {
            return;
        }

        from(this._ngBienDossier.bien.saveItem('commodites', this._ngBienCommodite, true))
            .pipe(take(1)).subscribe(_ => this._modalService.setResponse(this._ngBienCommodite));
    }
}
