import {Component, ElementRef, EventEmitter, HostListener, Input, NgZone, Output, ViewChild} from '@angular/core';
import {IImageOptions, IImagePercentageCoordinates} from '@shared/image/image.interfaces';
import ImageService from '@shared/image/image.service';

@Component({selector: 'app-image-crop', templateUrl: 'image-crop.component.html'})
export class AppImageCropComponent {
    @Output() crop = new EventEmitter<IImagePercentageCoordinates>();
    @ViewChild('ghostImg') ghostImg!: ElementRef<HTMLImageElement>;

    static readonly initImageOptions: IImageOptions = {aspectRatio: 'tw-aspect-cinema'};
    private _cropCoordinates: IImagePercentageCoordinates = {...ImageService.initImagePercentageCoordinates};
    private _initCoordinates!: {
        crop: IImagePercentageCoordinates;
        drag: IImagePercentageCoordinates;
        isOnImg: boolean;
    };
    private _link!: string;
    private _imageService: ImageService;
    private _ngZone: NgZone;
    private _options: IImageOptions = {...AppImageCropComponent.initImageOptions};

    constructor(imageService: ImageService, ngZone: NgZone) {
        this._imageService = imageService;
        this._ngZone = ngZone;
    }

    get cropCoordinates(): IImagePercentageCoordinates {
        return this._cropCoordinates;
    }

    get link(): string {
        return this._link;
    }

    @Input()
    set link(value: string) {
        if (this._link) {
            return;
        }

        this._link = value;
        this._cropCoordinates = {...ImageService.initImagePercentageCoordinates, ...this._imageService.getCoordinatesFromUrl(this._link)};
    }

    get options(): IImageOptions {
        return this._options;
    }

    @Input()
    set options(value: IImageOptions) {
        this._options = {...AppImageCropComponent.initImageOptions, ...value};
    }

    down(): void {
        this._cropCoordinates.y++;
        this.emitCropCoordinates();
    }

    emitCropCoordinates(): void {
        if (this._cropCoordinates.x < 0) {
            this._cropCoordinates.x = 0;
        } else if (this._cropCoordinates.x > 100) {
            this._cropCoordinates.x = 100;
        }

        if (this._cropCoordinates.y < 0) {
            this._cropCoordinates.y = 0;
        } else if (this._cropCoordinates.y > 100) {
            this._cropCoordinates.y = 100;
        }

        this.crop.emit(this._cropCoordinates);
    }

    @HostListener('drag', ['$event'])
    onDrag(dragEvent: DragEvent): void {
        if (!dragEvent.clientX || !dragEvent.clientY || !dragEvent.target || !this._initCoordinates) {
            return;
        }

        const imgElement = dragEvent.target as HTMLImageElement;

        // 100 => Pour avoir un offset en %
        this._cropCoordinates.y = Math.trunc(this._initCoordinates.crop.y + (100 / imgElement.height) * (this._initCoordinates.drag.y - dragEvent.y));
    }

    @HostListener('dragend', ['$event'])
    onDragEnd(dragEvent: DragEvent): void {
        if (dragEvent.clientX && dragEvent.clientY && this._initCoordinates?.isOnImg) {
            this._ngZone.run(() => this.emitCropCoordinates());
        } else {
            this._cropCoordinates = this._initCoordinates.crop;
        }
    }

    @HostListener('dragleave', ['$event'])
    onDragLeave(dragEvent: DragEvent): void {
        if (!dragEvent.clientX || !dragEvent.clientY || !this._initCoordinates) {
            return;
        }

        this._initCoordinates.isOnImg = false;
    }

    @HostListener('dragover', ['$event'])
    onDragOver(dragEvent: DragEvent): void {
        if (!dragEvent.clientX || !dragEvent.clientY || !this._initCoordinates) {
            return;
        }

        this._initCoordinates.isOnImg = true;
    }

    @HostListener('dragstart', ['$event'])
    onDragStart(dragEvent: DragEvent): void {
        dragEvent.dataTransfer?.setDragImage(this.ghostImg.nativeElement, 0, 0);
        this._initCoordinates = {
            crop: {x: this._cropCoordinates.x, y: this._cropCoordinates.y},
            drag: {x: dragEvent.x, y: dragEvent.y},
            isOnImg: true,
        };
    }

    up(): void {
        this._cropCoordinates.y--;
        this.emitCropCoordinates();
    }
}
