import {Component, Input} from '@angular/core';
import {PhoneNumberService} from '@shared/texts/phones/phone/number/phone-number.service';
import {ITextPhoneOptions} from '@shared/texts/texts.interfaces';

@Component({selector: 'app-text-phone', templateUrl: 'text-phone.component.html'})
export class TextPhoneComponent {
    static readonly initTextPhoneOptions: ITextPhoneOptions = {};
    private _phoneNumberService: PhoneNumberService;
    private _options: ITextPhoneOptions = TextPhoneComponent.initTextPhoneOptions;
    private _phoneRfc3966!: string;
    private _phoneUsable!: string;

    constructor(phoneNumberService: PhoneNumberService) {
        this._phoneNumberService = phoneNumberService;
    }

    get options(): ITextPhoneOptions {
        return this._options;
    }

    @Input()
    set options(value: ITextPhoneOptions) {
        this._options = {...TextPhoneComponent.initTextPhoneOptions, ...value};
    }

    @Input()
    set phone(value: string) {
        this._phoneRfc3966 = this._phoneNumberService.getRfc3966(value);
        this._phoneUsable = this._phoneNumberService.getUsable(value);
    }

    get phoneRfc3966(): string {
        return this._phoneRfc3966;
    }

    get phoneUsable(): string {
        return this._phoneUsable;
    }
}
