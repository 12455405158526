import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({selector: 'app-ng2-textarea', templateUrl: 'ng2-textarea.component.html'})
export class Ng2TextareaComponent {
    @Output() changed = new EventEmitter<string>();

    private _formName = 'ng2-textarea-for-ng';
    private _placeholder = '';
    private _required = false;
    private _text!: string;

    get formName(): string {
        return this._formName;
    }

    @Input()
    set formName(value: string) {
        this._formName = value;
    }

    get placeholder(): string {
        return this._placeholder;
    }

    @Input()
    set placeholder(value: string) {
        this._placeholder = value;
    }

    get required(): boolean {
        return this._required;
    }

    @Input()
    set required(value: boolean) {
        this._required = value;
    }

    get text(): string {
        return this._text;
    }

    @Input()
    set text(value: string) {
        this._text = value;
    }

    onChange(): void {
        this.changed.emit(this.text);
    }
}
